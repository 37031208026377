import { ActivatedRoute, Params } from '@angular/router';
import { PL } from 'src/app/core/models/pl.models';
import { DataPL, DataTrend, ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TopBarService } from 'src/app/core/services/topbar.service';
import Utils from 'src/app/core/utilities/utils';
import { lineBarChart, dataTotals } from '../../data';
import { ChartType, DatepickerElement } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import { Products } from 'src/app/core/models/products.model';
import { PaginationInstance } from 'ngx-pagination';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export interface ProductFilter {
  name: string;
  value: string;
}

export class FinancialTrendsContainer {
  noResults: boolean = true;
  loadingPL: boolean = true;
  loadingTrends: boolean = true;
  noResultsTrends: boolean = true;
  loadingProductsList: boolean = true;
  noResultsProductsList: boolean = true;
  orderTable: boolean = true;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];
  dataTrend: DataTrend | undefined;
  dataTrendMaster: any[] | null | undefined;
  filterTrendDataMaster: any[] | null | undefined;
  typeFilterSelected: string;
  searchProductTrend: string;
  utilities: Utils = new Utils();
  textFilter: string = 'Filters';
  lineBarChart: ChartType;
  textFilterChart: string = 'Filters';
  dataTotals: any = dataTotals;
  initialTotals: any = dataTotals;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  minEndDate: NgbDate;

  productFilters: ProductFilter[] = [
    {"name": "Sales shipped", "value": "sales"},
    {"name": "Units", "value":"units"},
    {"name": "Promotion", "value":"promotion"},
    {"name": "Amazon fee", "value":"amazon_fee"},
    {"name": "Gross profit", "value":"gros_profit"}
  ];

  productFiltersChart: ProductFilter[] = [
    {"name": "Sales", "value":"rowSales"},
    {"name": "Sales shipped", "value": "rowSalesShipped"},
    {"name": "Unit Sold", "value":"rowUnits"},
    {"name": "Promotion", "value":"rowPromotion"},
    {"name": "Amazon fee", "value":"rowAmazonFee"},
    {"name": "Gross profit", "value":"rowGrossProfit"},
    {"name": "Shipping Cost", "value":"rowShippingCost"},
    {"name": "VAT", "value":"rowVat"},
  ];

  keyToChartFilter: string = 'rowSales';

  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 200, // Total de elementos
  };

  constructor(public topBarService: TopBarService, public indexDbCacheService: IndexedDbCacheService, public productsService: ProductService, public loadingSpinnerService: LoadingSpinnerService) {

  }

  manualSyncDataTrendsFromService() {
    this.loadingTrends = !this.loadingTrends;
    this.getGeneralDataTrends(true, true);
  }

  async getGeneralDataTrends(syncData: boolean = false, overwriteCache: boolean = true) {
    this.loadingSpinnerService.show();
    (await this.topBarService.generalDataTrendTwo(syncData, overwriteCache)).subscribe((response: ResponseService) => {
      this.loadingSpinnerService.hide();
      this.loadingTrends = false;
      this.dataTrendMaster = response.data;
      this.getTypeTrend('sales');
    });
  }


  getTypeTrend (typeValue:any) {
    this.initializeDataTrend();
    this.dataTrend['header']=[...this.dataTrend['header'], ...this.dataTrendMaster.map((item:any) => (item.name_month)), ...["Total"]]
    let listAsin = this.extractUniqueAsins();
    let listProductInformation = this.getInformationAsin(listAsin);
    let listProductInformationWithFilter = this.getFilterAsin(listProductInformation, typeValue);
    this.dataTrend.data = listProductInformationWithFilter;
    this.filterTrendDataMaster = listProductInformationWithFilter;
    this.noResultsTrends = !(listProductInformationWithFilter.length > 0);
  }

  getInformationAsin(listAsin: any) {
    const uniqueAsins = new Set(listAsin);
    const listAsinInformation: any[] = [];

    for (const dataValue of this.dataTrendMaster) {
      const financeData = dataValue.finance as any || [];

      financeData
        .filter(financeItem => uniqueAsins.has(financeItem.asin))
        .forEach(financeItem => {
          const foundItem = listAsinInformation.find(item => item.asin === financeItem.asin);
          if (!foundItem) {
            listAsinInformation.push({
              asin: financeItem.asin,
              image: financeItem.image,
              name_product: financeItem.name_product,
              total: financeItem.total,
              seller_sku: financeItem.seller_sku,
              size: financeItem.size,
            });
          }
        });
    }
    return listAsinInformation;
  }

  getFilterAsin(listAsin:any, typeFilter:any){
    listAsin.forEach((asin:any) => {
      let monthProduct:any = [];
      let totalAmount = 0;

      this.dataTrendMaster.forEach((dataValue:any) => {
        let total = 0
        for(let pos=0; pos < dataValue.finance.length; pos++){
          if(dataValue.finance[pos].asin == asin.asin){
            total=total+dataValue.finance[pos][typeFilter];
          }
        }
        monthProduct.push(total);
      });

      monthProduct.forEach((amount:any) => { totalAmount = totalAmount + amount;});
      monthProduct.push(totalAmount);
      asin['amounts'] = monthProduct;
    });
    return listAsin;
  }


  extractUniqueAsins() {
    const asinSet = new Set(
      Array.prototype.concat.apply(
        [],
        (this.dataTrendMaster as any[])
          .map(entry => (entry.finance as any[] || []).map(item => item?.asin))
          .filter(Boolean))
    );

    return Array.from(asinSet);
  }

  private initializeDataTrend(): void {
    this.dataTrend = new DataTrend();
    this.dataTrend.header = ["Product"];
    this.dataTrend.data = [];
  }

  filterProducts(searchTerm: string | undefined): void {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (lowerCaseSearchTerm.trim() === '') {
      this.dataTrend.data = [...this.filterTrendDataMaster];
      this.noResultsTrends = false;
      return;
    }

    this.dataTrend.data = this.filterTrendDataMaster.filter(product =>
      product.asin.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.seller_sku.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.name_product.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.total.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.amounts.some(amount => amount.toString().includes(lowerCaseSearchTerm))
    );

    this.noResultsTrends = this.dataTrend.data.length === 0;
  }
}

import { ActivatedRoute, Params } from '@angular/router';
import { Finances, ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TilesService } from 'src/app/core/services/tiles.service';
import { lineBarChart } from './data';
import Utils from 'src/app/core/utilities/utils';
import { ChartType, DatepickerElement, SelectedDate } from 'src/app/core/models/elements.model';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class TotalSalesContainer {
  minEndDate: NgbDate;
  noResults: boolean = true;
  loadingTiles: boolean = true;
  loadingFinancesByDate: boolean = false;
  dateSelectByRange: SelectedDate;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  selectedFinancesDate: Finances;
  public utilities: Utils;
  public arrayOfDate: any = [];
  defaultFinancesDate: Finances[];
  lineBarChart: ChartType;
  searchedElements: any[] = [];
  chunkedElementsSearch: any[] = [];
  keyToSearch: string = 'saleGlobal';
  textFilter: string = 'Total Sales';
  filters: any[] = [];
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  currentSlide: number = 0;
  backgroundColors = ['#252b3b', '#01559f', '#0693bf', '#03ad46']
  showDatesSection: boolean = false;
  accordionStates: boolean[] = [];

  constructor(public tilesService: TilesService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.utilities = new Utils();
    this.arrayOfDate = this.getDates();
    this.lineBarChart = lineBarChart;
    this.initializeFilters();
    this.getGeneralDataTiles();
  }

  initializeFilters() {
    this.filters = [
      { title: 'Total Sales', key: 'saleGlobal' },
      { title: 'Amazon Fees', key: 'amazon_fee' },
      { title: 'Gross Profit', key: 'gros_profit' },
      { title: 'Units', key: 'units' },
      { title: 'Orders', key: 'orders' },
      { title: 'Promotion', key: 'promotion' },
      { title: 'Sales Shipped', key: 'sales' },
      { title: 'Average Ticket', key: 'average_ticket' }
    ];

    this.filtersDate = [
      { title: 'Ultimo 7 Dias', key: 'seven_days' },
      { title: 'Ultimos 14 Dias', key: 'fourteen_days' },
      { title: 'Ultimo 30 Dias', key: 'thirty_days' },
      { title: 'Trimestre En Curso', key: 'current_quarter' },
      { title: 'Trimestre Anterior', key: 'quarter' },
      { title: '2 Trimestres Anteriores', key: 'previous_quarter' },
      { title: 'Rango de Fechas', key: 'free_dates' }
    ];
  }

  manualSyncDataForTilesCard() {
    this.loadingTiles = !this.loadingTiles;
    this.getGeneralDataTiles(true, true);
  }

  async getFinancesByDates(startDate: string, endDate: string) {
    this.loadingSpinnerService.show();
    this.loadingFinancesByDate = true;

    (await this.tilesService.getFinancesByDates(startDate, endDate)).subscribe((response: any) => {
        this.loadingSpinnerService.hide();
        this.loadingFinancesByDate = false;
        this.selectedFinancesDate = response.data.finances;

        // Encuentra si ya existe un objeto con la misma fecha en searchedElements
        const index = this.searchedElements.findIndex(element => element.date === this.utilities.convertFormatDate(startDate.split('T')[0]) + ' - ' + this.utilities.convertFormatDate(endDate.split('T')[0]));

        if (index !== -1) {
          // Si la fecha ya existe, sobrescribe el objeto existente
          this.searchedElements[index] = {
            date: this.utilities.convertFormatDate(startDate.split('T')[0]) + ' - ' + this.utilities.convertFormatDate(endDate.split('T')[0]),
            data: response.data.finances
          };
        } else {
          // Si la fecha no existe, agrégala al principio del array
          this.searchedElements.unshift({
            date: this.utilities.convertFormatDate(startDate.split('T')[0]) + ' - ' + this.utilities.convertFormatDate(endDate.split('T')[0]),
            data: response.data.finances
          });
        }

        this.chunkedElementsSearch = this.utilities.chunkArray(this.searchedElements, 3);
        // Crear una copia profunda del objeto lineBarChart
        let newLineBarChart = JSON.parse(JSON.stringify(this.lineBarChart));
        newLineBarChart.labels.unshift(this.utilities.convertFormatDate(startDate.split('T')[0]) + ' - ' + this.utilities.convertFormatDate(endDate.split('T')[0]));
        const value = this.selectedFinancesDate[this.keyToSearch];
        const averageTicket = this.selectedFinancesDate['units'] !== 0 ? (this.selectedFinancesDate['saleGlobal'] / this.selectedFinancesDate['units']) : 0;
        const data = this.keyToSearch !== 'average_ticket' ? value : averageTicket;
        newLineBarChart.datasets[0].data.unshift(data);
        this.lineBarChart = newLineBarChart;
    });
  }

  async getGeneralDataTiles(syncData: boolean = false, overwriteCache: boolean = true) {
    (await this.tilesService.getAllTiles(syncData, overwriteCache)).subscribe((response: ResponseService) => {
        this.loadingTiles = false;
        this.defaultFinancesDate = response.data
        this.fetchDataBarChart();
        this.addDataSearchedToTheDataBarChart();
    });
  }

  fetchDataBarChart() {
    const data = this.defaultFinancesDate;

    const getValue = (index) => {
      const value = data[index][this.keyToSearch];
      const units = data[index]['units'];
      const salesGlobal = data[index]['saleGlobal'];
      const averageTicket =  units !== 0 ? (salesGlobal / units) : 0;
      return this.keyToSearch !== 'average_ticket' ? value : averageTicket;
    };

    const today = getValue(0);
    const yesterday = getValue(1);
    const monthToDate = getValue(2);
    const lastMonth = getValue(3);

    const newLineBarChart = {
        ...this.lineBarChart,
        datasets: [{
            data: [monthToDate, lastMonth],
            label: this.textFilter + ' Analytics',
            backgroundColor: 'rgba(6, 147, 191, 0.8)',
            borderColor: 'rgba(6, 147, 191, 0.8)'
        }]
    };

    this.lineBarChart = newLineBarChart;
  }


  addDataSearchedToTheDataBarChart() {
    let newLineBarChart = JSON.parse(JSON.stringify(this.lineBarChart));
    this.searchedElements.forEach((element: any) => {
      const value = element.data[this.keyToSearch];
      const averageTicket = element.data['units'] !== 0 ? (element.data['saleGlobal'] / element.data['units']) : 0;
      const data = this.keyToSearch !== 'average_ticket' ? value : averageTicket;
      newLineBarChart.datasets[0].data.unshift(data);
    });

    this.lineBarChart = newLineBarChart;
  }


  activeFilter(filter: any) {
    this.textFilter = filter.title;
    this.keyToSearch = filter.key;
    this.fetchDataBarChart();
    this.addDataSearchedToTheDataBarChart();
  }

  activeFilteDate(filter: any) {
    this.textFilterDate = filter.title;
    this.loadingFinancesByDate = false;
    const today = new Date();
    const todayFormatted = this.utilities.getDateInStringTimezone(today);
    switch (filter.key) {
      case 'seven_days':
        const sevenDaysAgo = this.utilities.getDateInStringTimezone(today.setDate(today.getDate() - 7));
        this.setRangeInTheUI(sevenDaysAgo, todayFormatted);
        this.showDatesSection = false;
        this.getFinancesByDates(sevenDaysAgo, todayFormatted);
        break;
      case 'fourteen_days':
        this.showDatesSection = false;
        const fourteenDaysAgo = this.utilities.getDateInStringTimezone(today.setDate(today.getDate() - 14));
        this.setRangeInTheUI(fourteenDaysAgo, todayFormatted);
        this.getFinancesByDates(fourteenDaysAgo, todayFormatted);
        break;
      case 'thirty_days':
        this.showDatesSection = false;
        const thirtyDaysAgo = this.utilities.getDateInStringTimezone(today.setDate(today.getDate() - 30));
        this.setRangeInTheUI(thirtyDaysAgo, todayFormatted);
        this.getFinancesByDates(thirtyDaysAgo, todayFormatted);
        break;
      case 'current_quarter':
        const currentQuarterData = this.utilities.getCurrentQuarter();
        this.showDatesSection = false;
        this.getFinancesByDates(currentQuarterData.startDate, currentQuarterData.endDate);
        break;
      case 'quarter':
        const quarterData = this.utilities.getQuarterDate();
        this.showDatesSection = false;
        this.getFinancesByDates(quarterData.startDate, quarterData.endDate);
        break;
      case 'previous_quarter':
        const lastQuarterData = this.utilities.getLastQuarterDate();
        this.showDatesSection = false;
        this.getFinancesByDates(lastQuarterData.startDate, lastQuarterData.endDate );
        break;
      default:
        this.showDatesSection = true;
        this.dateSelectByRange = null;
        break;
    }
  }

  setRangeInTheUI(initDate, endDate) {
    const dateRange = this.utilities.convertFormatDate(initDate.split('T')[0]) + ' - ' + this.utilities.convertFormatDate(endDate.split('T')[0]);
    this.dateSelectByRange = {title:"Selected Date", date: dateRange}
  }

  getDates() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const monthToDate = `${this.utilities.getFormattedDate(today)} - ${this.utilities.getFormattedDate(firstDayOfMonth)}`;
    const lastMonth = `${this.utilities.getFormattedDate(lastDayOfLastMonth)} - ${this.utilities.getFormattedDate(firstDayOfLastMonth)}`;

    return [
      { title: "Today", date: this.utilities.getFormattedDate(today) },
      { title: "Yesterday", date: this.utilities.getFormattedDate(yesterday) },
      { title: "Month to Date", date: monthToDate },
      { title: "Last Month", date: lastMonth }
    ];
  }
}

import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, CalculeElement, CalculeByDay, ChartTypeApex } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { dashedLineChart,  roasDashedLineChart } from '../../data';
import { PPCService } from 'src/app/core/services/ppc.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export class InteractionsChartContainer {
  public utilities: Utils;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  calculate: CalculeElement;
  calculeByDay: CalculeByDay[];
  dashedLineChart: ChartTypeApex;
  roasDashedLineChart: ChartTypeApex;

  constructor(public ppcService: PPCService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.dashedLineChart = dashedLineChart;
    this.roasDashedLineChart = roasDashedLineChart;
    this.utilities = new Utils();
  }

  async getAccountInfo() {
    (await this.ppcService.fetchInfoOfTheAccount()).subscribe((response: any) => {
      if (!response.status) {
        this.loadingSpinnerService.showAlertError();
        this.loadingSpinnerService.hide();
        return;
      }
      this.loading = false;
      this.calculate = response.data.calcule;
      this.calculeByDay = response.data.calcule_by_day;
      this.generateIndicators();
    });
  }

  generateIndicators() {
    let dashedLineChart = JSON.parse(JSON.stringify(this.dashedLineChart));
    dashedLineChart.series[0].data = this.calculeByDay.map((element: any) => Number(element.acos.toFixed(2)))
    dashedLineChart.xaxis.categories = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.dashedLineChart = dashedLineChart;

    let roasDashedLineChart = JSON.parse(JSON.stringify(this.roasDashedLineChart));
    roasDashedLineChart.series[0].data = this.calculeByDay.map((element: any) => element.spend ? Number((element.sales / element.spend).toFixed(2)) : 0 );
    roasDashedLineChart.xaxis.categories = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.roasDashedLineChart = roasDashedLineChart;
  }
}

import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, ChartTypeApex } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { PaginationInstance } from 'ngx-pagination';
import { InventoryService } from 'src/app/core/services/inventory.service';
import * as echarts from 'echarts';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { dashedLineChartByHour } from '../../data';
import ShareFunctionsInventory from 'src/app/core/utilities/share-functions-inventory';

export class SellsByHourContainer {
  minEndDate: NgbDate;
  public utilities: Utils;
  public shareFunctionsInventory: ShareFunctionsInventory;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  orderTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  filters: any[] = []
  orders: any[] = [];
  ordersToSearch: any[] = [];
  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 10000, // Total de elementos
  };

  totalsByStatus: any[];
  view: string = 'map';
  dataForMunicipality: any[] = [];
  isLoadedAMunicipality: boolean = false;
  counterByDay: any;
  weeks: string[] = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  dashedLineChartByHour: ChartTypeApex;
  selectedDay = null;
  showDatesSection: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  endedDate: any;
  initialDate: any;

  constructor(public inventoryService: InventoryService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.dashedLineChartByHour = dashedLineChartByHour;
    this.utilities = new Utils();
    this.shareFunctionsInventory = new ShareFunctionsInventory(loadingSpinnerService, inventoryService, this.utilities);
  }

  async fetchCampaings(initDate?: string, endDate?: string) {
    try {
      const response = await this.shareFunctionsInventory.getCampaingsByDate(initDate, endDate);
      this.loadingTable = response.loadingTable;
      this.orders = response.orders;
      this.noResults = response.noResults;
      this.ordersToSearch = response.ordersToSearch;
      this.generateDataTotalsByHour();
    } catch (error) {
      console.log(error, 'error in inventory container')
    }
  }

  generateDataTotalsByHour() {
    this.selectedDay = null;
    const newBasicDashedLineChartByHour = JSON.parse(JSON.stringify(this.dashedLineChartByHour));
    const dataHour = this.utilities.countSalesByHour(this.ordersToSearch);
    newBasicDashedLineChartByHour.series[0].data = dataHour;
    this.dashedLineChartByHour = newBasicDashedLineChartByHour;
    console.log(this.dashedLineChartByHour)
  }

  filterPurchaseOrdersByDay(day:number) {
    const newBasicDashedLineChartByHour = JSON.parse(JSON.stringify(this.dashedLineChartByHour));
    const dataHour = this.utilities.counterByDayByHour(this.ordersToSearch, day);
    newBasicDashedLineChartByHour.series[0].data = dataHour;
    this.dashedLineChartByHour = newBasicDashedLineChartByHour;
  }
}

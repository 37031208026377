<div class="card" *ngIf="typeOfContentSkeleton === 'card'">
  <div class="row g-0 align-items-center">
    <div class="col-md-12">
      <div class="card-body pd-15">
        <div class="container-title-card">
          <div class="row">
            <div class="col-md-6">
              <div class="progress-container mb-0">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-data mt-2">
          <div class="row">
            <div class="col-md-3">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="typeOfContentSkeleton === 'table'">
  <table class="table table-striped table-responsive background-table mb-0">
    <thead>
      <tr>
        <th class="text-center pd-20" colspan="15">
          <div class="progress-container medium-progress-container">
            <div class="progress-bar" #progress></div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let header of [].constructor(9); index as i">
        <td class="pd-20 text-center" colspan="15">
          <div class="progress-container medium-progress-container">
            <div class="progress-bar" #progress></div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="typeOfContentSkeleton === 'card-tile'">
  <div class="card-group">
    <div class="card mb-4" *ngFor="let item of [].constructor(4)">
      <div class="card-body">
        <div class="card-title">
          <div class="row">
            <div class="col-sm-12">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar medium-progress-bar" #progress></div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="container-intial-info">
          <div class="row">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>

          <div class="row mt-20">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="container-secondary-info">
          <div class="row">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>

          <div class="row mt-20">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="container-third-info">
          <div class="row">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>

          <div class="row mt-20">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="progress-container medium-progress-container">
                <div class="progress-bar" #progress></div>
              </div>

              <div class="progress-container medium-progress-container mt-2">
                <div class="progress-bar" #progress></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeOfContentSkeleton === 'card-vertical'">
  <div class="card mb-4">
    <div class="card-body">
      <div class="card-title">
        <div class="row">
          <div class="col-sm-12">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar medium-progress-bar" #progress></div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="container-intial-info">
        <div class="row">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>

        <div class="row mt-20">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="container-secondary-info">
        <div class="row">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>

        <div class="row mt-20">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="container-third-info">
        <div class="row">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>

        <div class="row mt-20">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>

            <div class="progress-container medium-progress-container mt-2">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeOfContentSkeleton === 'card-chart'">
  <div class="card col-sm-12">
    <div class="card-body card-chart">
      <div class="card-title">
        <div class="row mt-50">
          <div class="col">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
          <div class="col">
            <div class="progress-container medium-progress-container">
              <div class="progress-bar" #progress></div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-content">
        <div class="col-sm-4">
          <div class="progress-container medium-progress-container">
            <div class="progress-bar" #progress></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-dark.png" alt="" height="30">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="50">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="30">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <div class="dropdown dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
        <button ngbDropdownToggle type="button" class="btn header-item waves-effect" data-toggle="dropdown"
          aria-haspopup="false" aria-expanded="false">
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row" *ngIf="!loadingPL; else overview">
            <div class="col-sm-6">
              <h5>Promedio Ultimos 4 Meses</h5>
            </div>
            <div class="col-sm-6">
              <div class="d-flex justify-content-end align-items-center">
                <button type="button" (click)="manualSyncDataPLFromService()"
                  class="btn btn-primary btn-sm btn-rounded btn-sync"
                  ngbTooltip="Manual Sync" placement="right">
                  <i class=" ri-refresh-line"></i>
                </button>
              </div>
            </div>
            <div class="row mt-20">
              <ng-container *ngFor="let prop of utilities.getKeysFromObject(this.dataPL)">
                  <div class="card card-into-carousel col" *ngIf="prop !== 'header'">
                    <div class="card-body-into-carousel card-container-info-cards average-card">
                      <div class="d-flex align-items-center">
                        <div class="flex-1 overflow-hidden">
                          <h5 class="mb-0 font-size-12 text-white">
                            <b>Average {{prop === 'rowSales' ? 'Sales' : prop === 'rowSalesShipped' ? 'Sales Shipped' : prop === 'rowUnits' ? 'Units' : prop === 'rowPromotion' ? 'Promotion' : prop === 'rowShippingCost' ? 'Shipping Cost' : prop === 'rowAmazonFee' ? 'Amazon Fee' : prop === 'rowVat' ? 'VAT' : prop === 'rowGrossProfit' ? 'Gross Profit' : '' }}</b>
                          </h5>
                        </div>
                        <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                      </div>
                      <div class="container-element-data text-white">
                        <ng-container *ngIf="prop === 'unit'; else otherPropers">
                          {{utilities.getSum(dataPL[prop])}}
                        </ng-container>
                        <ng-template #otherPropers>
                          ${{(utilities.getSum(dataPL[prop])/4) | number:'1.2-2'}}
                        </ng-template>
                      </div>
                    </div>
                  </div>
              </ng-container>
            </div>

            <hr>

            <div class="col" *ngFor="let item of topMonths?.header;  let i = index">
              <h5>{{item}}</h5>
              <div class="row">
                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Sales</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowSales[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Sales Shipped</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowSalesShipped[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Unit</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">{{topMonths?.rowUnits[i] }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Promotion</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowPromotion[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Shipping Cost</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowShippingCost[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Amazon Fee</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowAmazonFee[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>VAT</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowVat[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>

                <div class="card card-into-carousel col-md-6">
                  <div class="card-body-into-carousel card-container-info-cards" [style.backgroundColor]="backgroundColors[i]">
                    <div class="d-flex align-items-center">
                      <div class="flex-1 overflow-hidden"><h5 class="mb-0 font-size-12 text-white"><b>Gross Profit</b></h5></div>
                      <div class="ms-auto"><i class="font-size-14 ri-coin-line text-white"></i></div>
                    </div>
                    <div class="container-element-data">
                      <div class="col-md-12 text-white">${{topMonths?.rowGrossProfit[i] | number:'1.2-2' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-template #overview>
            <div class="row">
              <div class="col">
                <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
              </div>
              <div class="col">
                <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
              </div>
              <div class="col">
                <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
              </div>
              <div class="col">
                <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="ri-apps-2-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row g-0">
              <div class="col">
                <h5 class="mb-0 title-partners">Nuestros Partners</h5>
              </div>
            </div>
            <div class="row g-0">
              <div class="col">
                <a class="dropdown-icon-item" href="https://www.creizer.com/" target="_blank">
                  <img src="assets/images/partners/creizer.png" alt="Creizer">
                  <span>Creizer</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="https://gobots.ai/es/latam-b/?utm_source=Drizar" target="_blank">
                  <img src="assets/images/partners/go-bots.png" alt="Go Bots">
                  <span>Go Bots</span>
                </a>
              </div>
            </div>

            <div class="row g-0">
              <div class="col">
                <a class="dropdown-icon-item" href="https://astrosellers.com.mx/" target="_blank">
                  <img src="assets/images/partners/astro-sellers.png" alt="Astro Sellers">
                  <span>Astro Sellers</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="https://mienvio.mx/" target="_blank">
                  <img src="assets/images/partners/mi-envio.png" alt="Mi Ennvio">
                  <span>Mi Envio</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="fullscreen()">
          <i class="ri-fullscreen-line"></i>
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="reSyncAccount()">
          <i class="ri-refresh-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-notification-3-line"></i>
          <span class="noti-dot"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="ri-notification-3-line"></i>
                  </span>
                </div>
                <div class="flex-1">
                  <h6 class="mt-0 mb-1">Welcom to the new VXI</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Hi, welcome to the new VXI Platform, I hope you a enjoy experience</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> 1 min ago</p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon waves-effect" (click)="openModalDashboardSelection()">
          <i class="bx ri-dashboard-line"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-team-line"></i>
          <span class="noti-dot"></span>
          <span class="accountname">{{accountName}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Accounts</h6>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <ng-container *ngIf="!noResults; else noResultsElements">
              <ng-container *ngFor="let account of accounts; let i = index">
                <a href="" class="text-reset notification-item" (click)="selectAccount(account.id, account.name)">
                  <div class="d-flex align-items-center">
                    <div class="avatar-xs me-3">
                      <span class="avatar-title bg-primary rounded-circle font-size-16">
                        <i class=" ri-user-settings-line"></i>
                      </span>
                    </div>
                    <div class="flex-1">
                      <h6 class="mt-0 mb-0">{{account.name}}</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">Currency: {{account.currency_code}}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-container>


            </ng-container>
            <ng-template #noResultsElements>
              <a href="" class="text-reset notification-item">
                <div class="d-flex">
                  <div class="avatar-xs me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class=" ri-user-settings-line"></i>
                    </span>
                  </div>
                  <div class="flex-1">
                    <h6 class="mt-0 mb-1">Sin Cuentas</h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1">Aun no tienes tus cuentas sincronizadas a VEXIN</p>
                    </div>
                  </div>
                </div>
              </a>
            </ng-template>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" (click)="getAccountsForThisProfile(true, true)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> Recuperar Cuentas
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-2.png" alt="Header Avatar">
          <span class="d-xl-inline-block ms-1">{{userProfile?.name}}</span>
          <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="ri-user-line align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge badge-success float-end mt-1">11</span><i
              class="ri-lock-password-line align-middle me-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}} </a>
          <a class="dropdown-item" [routerLink]="'support/lock-screen'"><i class="ri-lock-unlock-line align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="ri-shut-down-line align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"
          (click)="toggleRightSidebar()">
          <i class="ri-settings-2-line"></i>
        </button>
      </div>

    </div>
  </div>
</header>

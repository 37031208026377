<ng-container *ngIf="!loading; else loadingContent">
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="row mt-10">
          <div class="col text-center">
            <p class="text-muted mb-0 font-size-12">Clicks</p>
            <span class="font-bold font-size-18">{{calculate && calculate.clicks ? (calculate.clicks) : 0}}</span>
          </div>
          <div class="col text-center">
            <p class="text-muted mb-0 font-size-12">Impressions</p>
            <span class="font-bold font-size-18">{{calculate && calculate.impressions ? (calculate.impressions | number:'1.0-2' ) : 0}}</span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="basicLineColumnsChart.series[0].data.length > 0 && basicLineColumnsChart.series[1].data.length; else emptyMixedChart">
        <apx-chart
        [series]="basicLineColumnsChart.series"
        [chart]="basicLineColumnsChart.chart"
        [yaxis]="basicLineColumnsChart.yaxis"
        [xaxis]="basicLineColumnsChart.xaxis"
        [labels]="basicLineColumnsChart.labels"
        [stroke]="basicLineColumnsChart.stroke"
        [title]="basicLineColumnsChart.title"
        [dataLabels]="basicLineColumnsChart.dataLabels"
        [tooltip]="basicLineColumnsChart.tooltip"
      ></apx-chart>
      </ng-container>
      <ng-template #emptyMixedChart>
        <app-empty-search></app-empty-search>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #loadingContent>
  <div class="row">
    <app-skeleton-element-list [typeOfContentSkeleton]="'card-chart'"></app-skeleton-element-list>
  </div>
</ng-template>

import { ChartType } from "src/app/core/models/elements.model";

const lineBarChart: ChartType = {
  labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July'
  ],
  datasets: [
      {
          label: 'P&L Data',
          backgroundColor: 'rgba(52, 195, 143, 0.8)',
          borderColor: 'rgba(52, 195, 143, 0.8)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
          hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
          data: [65, 59, 81, 45, 56, 80, 50, 20],
          barPercentage: 0.4

      },
  ],
  options: {
      maintainAspectRatio: false,
      scales: {
          xAxes: [
              {
                  gridLines: {
                      color: 'rgba(166, 176, 207, 0.1)'
                  },
              }
          ],
          yAxes: [
              {
                  gridLines: {
                      color: 'rgba(166, 176, 207, 0.1)'
                  }
              }
          ]
      }
  }
};

const dataTotals = {
  amazon_fee: 0,
  fba: 0,
  fee_comission: 0,
  fee_giftwrap_chargeback: 0,
  fee_shipping_chargeback: 0,
  gros_profit: 0,
  orders: 0,
  promotion: 0,
  sales: 0,
  shipping_cost: 0,
  shipping_price: 0,
  shipping_tax: 0,
  units: 0
};


export { lineBarChart, dataTotals };

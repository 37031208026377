<ng-container *ngIf="orders.length > 0; else emptyMap">
  <div class="row">
    <ng-container  *ngFor="let item of topStates; let i = index">
      <div class="card col card-into-carousel" (click)="createMapByPostalCodes(item)">
        <div class="card-container-info-cards card-body-into-carousel">
          <div class="d-flex align-items-center">
            <div class="flex-1 overflow-hidden">
              <h5 class="mb-0 font-title-card">
                <b>{{item.name}}</b>
              </h5>
            </div>
            <div class="ms-auto d-flex justify-content-end">
              <i class="font-size-24 ri-road-map-line"></i>
            </div>
          </div>
          <div class="container-element-data">
          <div class="row">
            <div class="col-md-6">Orders</div>
            <div class="col-md-6 d-flex justify-content-end align-items-center">{{item.value}}</div>
          </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="card">
    <div class="card-body pd-0">
      <div class="container-buttons-actions-to-map">
        <ng-container *ngIf="view === 'map'; else mapView">
          <button type="button" class="btn btn-primary btn-sm mr-2" (click)="changeBarView()">Change Bar View</button>
        </ng-container>
        <ng-template #mapView>
          <button type="button" class="btn btn-primary btn-sm mr-2" (click)="createMapChart()">Change Map View</button>
        </ng-template>

        <ng-container *ngIf="isLoadedAMunicipality">
          <button type="button" class="btn btn-primary btn-sm" (click)="createMapChart()">View Complete Map</button>
        </ng-container>
      </div>
      <div id="main"></div>
    </div>
  </div>
</ng-container>

<ng-template #emptyMap>
  <div class="card">
    <div class="card-body">
      <app-empty-search></app-empty-search>
    </div>
  </div>
</ng-template>

import { ChartTypeApex, ChartTypeLineColumn, ChartTypeRadialChart } from '../../core/models/elements.model';

const totalsByStatus = [
  { name: 'Shipped', sumQuantity: 0, sumSales: 0, totalOrders: 0 },
  { name: 'Unshipped', sumQuantity: 0, sumSales: 0, totalOrders: 0 },
  { name: 'Pending', sumQuantity: 0, sumSales: 0, totalOrders: 0 },
];

const basicRadialBarChart: ChartTypeRadialChart = {
  chart: {
      height: 350,
      type: 'donut',
  },
  series: [44, 55, 41, 17, 15],
  plotOptions: {
    pie: {
        donut: {
            size: '25%'  // Ajusta el tamaño del donut
        }
    }
  },
  dataLabels: {
    style: {
      colors: ["#fff"],
      fontSize: "10px",
    },
  },
  legend: {
      show: true,
      position: 'right',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '8px',
      offsetX: 0,
      offsetY: -10
  },
  labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5'],
  colors: ['#1cbb8c', '#5664d2', '#fcb92c', '#4aa3ff', '#ff3d60'],
  responsive: [{
      breakpoint: 600,
      options: {
          chart: {
              height: 240
          },
          legend: {
              show: false
          },
      }
  }],
};

const basicLineColumnsChart: ChartTypeLineColumn = {
  series: [
    {
      name: "Sales",
      type: "column",
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    },
    {
      name: "Units",
      type: "line",
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ],
  chart: {
    height: 350,
    type: "line",
    toolbar: {
      show: true,
      tools: {
        download: true,  // Mostrar solo la opción de descarga
        zoom: false,     // Ocultar opciones de zoom
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
    }
  },
  stroke: {
    width: [0, 4]
  },
  title: {
    text: "Sales/Units"
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1]
  },
  labels: [
    "01 Jan 2001",
    "02 Jan 2001",
    "03 Jan 2001",
    "04 Jan 2001",
    "05 Jan 2001",
    "06 Jan 2001",
    "07 Jan 2001",
    "08 Jan 2001",
    "09 Jan 2001",
    "10 Jan 2001",
    "11 Jan 2001",
    "12 Jan 2001"
  ],
  xaxis: {
    type: "category",
    labels: {
      style: {
        fontSize: '10px', // Ajusta el tamaño de la letra según tus necesidades
      },
    },
  },
  yaxis: [
    {
      title: {
        text: "Sales"
      }
    },
    {
      opposite: true,
      title: {
        text: "Units"
      }
    }
  ]
};

const dashedLineChart: ChartTypeApex = {
  chart: {
      height: 380,
      type: 'line',
      zoom: {
          enabled: false
      },
      toolbar: {
          show: false,
      }
  },
  colors: ['#5664d2', '#fcb92c', '#1cbb8c', '#4aa3ff', '#ff3d60', '#F021E0'],
  dataLabels: {
      enabled: false
  },
  stroke: {
      width: [3, 4, 3],
      curve: 'straight',
      dashArray: [0, 8, 5]
  },
  series: [
    {
      name: 'Sales',
      data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
    },
    {
      name: 'Spend',
      data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
    },
    {
      name: 'ACOS',
      data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    },
    {
      name: 'Clicks',
      data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35]
    },
    {
      name: 'Impressions',
      data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    },
    {
      name: 'Profit',
      data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    }
  ],
  title: {
      text: 'Page Statistics',
      align: 'left'
  },
  markers: {
      size: 0,

      hover: {
          sizeOffset: 6
      }
  },
  xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
          '10 Jan', '11 Jan', '12 Jan'
      ],
  },
  tooltip: {
      y: [{
          title: {
              formatter: (val) => {
                  return val + ' (mins)';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val + ' per session';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val;
              }
          }
      }]
  },
  grid: {
      borderColor: '#f1f1f1',
  }
};

const basicColumChart: ChartTypeApex = {
  chart: {
      height: 350,
      type: 'bar',
      toolbar: {
          show: false
      }
  },
  plotOptions: {
      bar: {
          horizontal: false,
          endingShape: 'rounded',
          columnWidth: '45%',
      },
  },
  dataLabels: {
      enabled: false
  },
  stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
  },
  colors: ['#5664d2', '#1cbb8c', '#fcb92c'],
  series: [{
      name: 'Count',
      data: [46, 57, 59]
  }, {
      name: 'Units',
      data: [37, 42, 38]
  }],
  xaxis: {
      categories: ['Shipped', 'Unshipped', 'Pending'],
  },
  yaxis: {
      title: {
          text: 'Metrics'
      }
  },
  fill: {
      opacity: 1
  },
  grid: {
      borderColor: '#f1f1f1'
  },
  tooltip: {
      y: {
          formatter: (val) => {
              return  val;
          }
      }
  }
};

const statesMx = [
  { name: 'Aguascalientes', value: 0, key: 'aguascalientes', jsonPath: '01_Ags_CP.json'},
  { name: 'Baja California', value: 1000, key: 'baja california', jsonPath: '02_BC_CP.json'},
  { name: 'Baja California Sur', value: 2000, key: 'baja california sur', jsonPath: '03_BCS_CP.json'},
  { name: 'Campeche', value: 3000, key: 'campeche', jsonPath: '04_Camp_CP.json'},
  { name: 'Coahuila', value: 6000, key: 'coahuila', jsonPath: '05_Coah_CP.json'},
  { name: 'Colima', value: 7000, key: 'colima', jsonPath: '06_Col_CP.json'},
  { name: 'Chiapas', value: 4000, key: 'chiapas', jsonPath: '07_Chis_CP.json'},
  { name: 'Chihuahua', value: 5000, key: 'chihuahua', jsonPath: '08_Chih_CP.json'},
  { name: 'Ciudad de México', value: 31000, key: 'cdmx', jsonPath: '09_Cdmx_CP.json'},
  { name: 'Durango', value: 8000, key: 'durango', jsonPath: '10_Dgo_CP.json'},
  { name: 'Guanajuato', value: 9000, key: 'guanajuato', jsonPath: '11_Gto_CP.json'},
  { name: 'Guerrero', value: 10000, key: 'guerrero', jsonPath: '12_Gro_CP.json'},
  { name: 'Hidalgo', value: 11000, key: 'hidalgo', jsonPath: '13_Hgo_CP.json'},
  { name: 'Jalisco', value: 12000, key: 'jalisco', jsonPath: '14_Jal_CP.json'},
  { name: 'México', value: 13000, key: 'edomex', jsonPath: '15_Mex_CP.json'},
  { name: 'Michoacán', value: 14000, key: 'michoacan', jsonPath: '16_Mich_CP.json'},
  { name: 'Morelos', value: 15000, key: 'morelos', jsonPath: '17_Mor_CP.json'},
  { name: 'Nayarit', value: 16000, key: 'nayarit', jsonPath: '18_Nay_CP.json'},
  { name: 'Nuevo León', value: 17000, key: 'nuevo leon', jsonPath: '19_NL_CP.json'},
  { name: 'Oaxaca', value: 18000, key: 'oaxaca', jsonPath: '20_Oax_CP.json'},
  { name: 'Puebla', value: 19000, key: 'puebla', jsonPath: '21_Pue_CP.json'},
  { name: 'Querétaro', value: 20000, key: 'queretaro', jsonPath: '22_Qro_CP.json'},
  { name: 'Quintana Roo', value: 21000, key: 'quintana roo', jsonPath: '23_QRoo_CP.json'},
  { name: 'San Luis Potosí', value: 22000, key: 'san luis potosi', jsonPath: '24_SLP_CP.json'},
  { name: 'Sinaloa', value: 23000, key: 'sinaloa', jsonPath: '25_Sin_CP.json'},
  { name: 'Sonora', value: 24000, key: 'sonora', jsonPath: '26_Son_CP.json'},
  { name: 'Tabasco', value: 25000, key: 'tabasco', jsonPath: '27_Tab_CP.json'},
  { name: 'Tamaulipas', value: 26000, key: 'tamaulipas', jsonPath: '28_Tamps_CP.json'},
  { name: 'Tlaxcala', value: 27000, key: 'tlaxcala', jsonPath: '29_Tlax_CP.json'},
  { name: 'Veracruz', value: 28000, key: 'veracruz', jsonPath: '30_Ver_CP.json'},
  { name: 'Yucatán', value: 29000, key: 'yucatan', jsonPath: '31_Yuc_CP.json'},
  { name: 'Zacatecas', value: 30000, key: 'zacatecas', jsonPath: '32_Zac_CP.json'}
];

const equivalentStates: Record<string, string> = {
  "ciudad de mexico": "cdmx",
  "distrito federal": "cdmx",
  "Distrito Federal": "cdmx",
  "CdMx": "cdmx",
  "D.F": "cdmx",
  "DF": "cdmx",
  "Df": "cdmx",
  "CDMX": "cdmx",
  "cdmx": "cdmx",
  "d.f": "cdmx",
  "df": "cdmx",
  "mexico city": "cdmx",
  "DISTRITO FEDERAL": "cdmx",
  "d.f.": "cdmx",
  "mexico df": "cdmx",
  "CIUDAD DE": "cdmx",
  "CIUDAD DE MEXICO": "cdmx",
  "Ciudad de Mexico": "cdmx",
  "CIUDAD DE MÉXICO": "cdmx",
  "Ciudad de México": "cdmx",
  "Mexico D.F.": "cdmx",
  "": "cdmx",
  "am": "cdmx",
  "pue": "puebla",
  "huixquilucan": "edomex",
  "estado de mexico": "edomex",
  "mexico": "edomex",
  "mex": "edomex",
  "MEX": "edomex",
  "quer�taro": "queretaro",
  "veracruz de ignacio de la llave": "veracruz",
  "NUEVO LEON": "nuevo leon",
  "NUEVO LEÓN": "nuevo leon",
  "Nuevo leon": "nuevo leon",
  "Nuevo Leon": "nuevo leon",
  "Nuevo León": "nuevo leon",
  "coahuila de zaragoza": "coahuila",
  "michoacan de ocampo": "michoacan"
};

export { basicRadialBarChart, basicLineColumnsChart, dashedLineChart, totalsByStatus, basicColumChart, statesMx, equivalentStates};

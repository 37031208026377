<ng-container *ngIf="orders.length > 0; else noOrders">
  <ng-container *ngIf="!loadingTable; else loadingContentTable">
      <ng-container *ngIf="!noResults; else noResultsContent">
        <div class="pd-0 table-container">
          <table class="table table-responsive">
            <thead>
              <tr class="table-data-campaings">
                <th (click)="sortColumn('purchase_date')">Purchase Date</th>
                <th (click)="sortColumn('amazon_order_id')">Amazon OrderId</th>
                <th (click)="sortColumn('merchant_order_id')">Merchant OrderId</th>
                <th style="width: 15%;" (click)="sortColumn('product_name')">Product Name</th>
                <th style="width: 5%;" class="text-center" (click)="sortColumn('quantity')">Units</th>
                <th class="text-center" (click)="sortColumn('Sales')">Sales</th>
                <th class="text-center" (click)="sortColumn('order_status')">Status</th>
                <th (click)="sortColumn('ship_city')">Ship City</th>
                <th class="text-center" (click)="sortColumn('ship_postal_code')">Postal Code</th>
                <th (click)="sortColumn('ship_state')">Ship State</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of ordersToSearch | paginate: config">
                <tr>
                  <td class="vertical-align">{{utilities.convertFormatDate(item.purchase_date)}}</td>
                  <td class="vertical-align">{{item.amazon_order_id}}</td>
                  <td class="vertical-align">{{item.merchant_order_id}}</td>
                  <td style="width: 15%;" class="vertical-align">
                    <span class="pointer" [ngbTooltip]="item.product_name" placement="top">
                      {{item.product_name | slice:0:40}}{{ item.product_name.length > 40 ? '...' : '' }}
                    </span>
                  </td>
                  <td style="width: 5%;" class="text-center vertical-align">{{item.quantity}}</td>
                  <td class="text-center vertical-align">${{item.Sales  | number:'1.2-2'}}</td>
                  <td class="text-center vertical-align">{{item.order_status}}</td>
                  <td class="vertical-align">{{item.ship_city}}</td>
                  <td class="text-center vertical-align">{{item.ship_postal_code}}</td>
                  <td class="vertical-align">{{item.ship_state}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <pagination-controls (pageChange)="config.currentPage = $event"></pagination-controls>
      </ng-container>

      <ng-template #noResultsContent>
        <app-empty-search></app-empty-search>
      </ng-template>
  </ng-container>
  <ng-template #loadingContentTable>
    <app-skeleton-element-list [typeOfContentSkeleton]="'table'"></app-skeleton-element-list>
  </ng-template>
</ng-container>
<ng-template #noOrders>
  <div class="card">
    <div class="card-body">
      <app-empty-search></app-empty-search>
    </div>
  </div>
</ng-template>

<div class="card">
  <div class="card-body">
    <ng-container *ngIf="radialBarChart.series.length > 0; else emptyDonutChart">
      <apx-chart class="apex-charts" dir="ltr" [series]="radialBarChart.series" [chart]="radialBarChart.chart"
        [labels]="radialBarChart.labels" [legend]="radialBarChart.legend" [colors]="radialBarChart.colors"
        [plotOptions]="radialBarChart.plotOptions" [dataLabels]="radialBarChart.dataLabels"
        [responsive]="radialBarChart.responsive" [tooltip]="radialBarChart.tooltip">
      </apx-chart>
    </ng-container>

    <ng-template #emptyDonutChart>
      <app-empty-search></app-empty-search>
    </ng-template>
  </div>
</div>

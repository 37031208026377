import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Observable, filter } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimeout = 30 * 60 * 1000
  private inactivityTimer: any;
  private inactivitySubject = new Subject<boolean>();
  private isLocked = false;
  private isAlertShown = false;
  currentUrl: string;

  constructor(private router: Router, private localStorage: LocalStorageService) {
    this.setupListeners();
    // Suscribirse a eventos de cambio de ruta
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url.split('?')[0];
      const invalidUrl = ['/support/lock-screen', '/account/login', '/account/sign-up', '/account/reset-password'];
      const startInactivityTimerElem = !invalidUrl.includes(this.currentUrl);
      if (startInactivityTimerElem) {
        this.startInactivityTimer();
      }
    });
  }

  private startInactivityTimer(): void {
    this.inactivityTimer = setTimeout(() => {
      if (!this.isLocked) {
        this.inactivitySubject.next(true);
      }
    }, this.inactivityTimeout);
  }

  private resetInactivityTimer(): void {
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  }

  private setupListeners(): void {
    ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll', 'click'].forEach(
      (event) => {
        window.addEventListener(event, () => this.resetInactivityTimer());
      }
    );
  }

  goToInactiveScreen(): void {
    this.isLocked = true;
    this.localStorage.saveData('lastUrl', this.router.url);
    this.router.navigate(['/support/lock-screen']);
  }

  unlockScreen(flagToResetInactiviy = true): void {
    this.isLocked = false;
    this.isAlertShown = false;
    this.resetInactivityTimer();

    if (flagToResetInactiviy) {
      this.router.navigate([this.localStorage.getData('lastUrl')]);
    }
  }

  getInactivityObservable(): Observable<boolean> {
    return this.inactivitySubject.asObservable();
  }

  alertInactivity(): void {
    if (!this.isAlertShown) {
      Swal.fire({
        icon: "info",
        showCloseButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        focusConfirm: false,
        title: '¡Sesión inactiva!',
        text: 'Tu sesión ha sido cerrada por inactividad. Por favor, inicia sesión nuevamente.',
        confirmButtonColor: '#5438dc',
      }).then((result) => {
        if (result.isConfirmed) {
          this.goToInactiveScreen();
        }
      });

      this.isAlertShown = true;
    }
  }
}

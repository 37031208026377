<div class="modal-header flex-column">
  <h5 class="modal-title mt-0 mb-10">¡Bienvenido!</h5>
  <p class="modal-subtitle mb-0">Hemos tu proceso de sincronización ha culminado con exito</p>
  <p class="modal-subtitle">Te invitamos a configurar tu dashboard.</p>
</div>
<div class="modal-body">
  <div class="row w-100">
    <div class="bg-light m-20 d-flex align-items-center justify-content-center">
      <div class="row d-flex align-items-start justify-content-center">
        <div class="col-5">
          <p class="modal-text">
            Para configurar tu Dashboard podras hacer uso de los mejores graficos y
            tablas que contamos en Drizar DataHub con la finalidad de que obtengas
            un vistazo rápido de toda la información de tu negocio.
          </p>
        </div>
        <div class="col-5">
          <p class="modal-text">
            De igual manera podras hacer uso de las <br> herramientas de Overview Vista General), filtros y <br>
            ordenamiento para que puedas personalizar tu experiencia.
          </p>
        </div>
        <div class="col-2">
          <button class="btn-save-selection" (click)="saveSelection()">Guardar elementos</button>
        </div>
        <div class="col-4">
          <h4 class="modal-text-subtitle">Productos</h4>
          <div class="row">
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('products_1')}"
                (click)="addToDashboard('products_1')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/products_1.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('products_2')}"
                (click)="addToDashboard('products_2')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/products_2.png" alt="">
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('products_3')}"
                (click)="addToDashboard('products_3')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/products_3.png" alt="">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-4">
          <h4 class="modal-text-subtitle">Financiero</h4>
          <div class="row">
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('financial_1')}"
                (click)="addToDashboard('financial_1')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/financial_1.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('financial_2')}"
                (click)="addToDashboard('financial_2')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/financial_2.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <h4 class="modal-text-subtitle">PPC</h4>
          <div class="row">
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('ppc_1')}"
                (click)="addToDashboard('ppc_1')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/ppc_1.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('ppc_2')}"
                (click)="addToDashboard('ppc_2')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/ppc_2.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('ppc_3')}"
                (click)="addToDashboard('ppc_3')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/ppc_3.png" alt="">
                </div>
              </div>
            </div>
            <!-- <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('ppc_4')}"
                (click)="addToDashboard('ppc_4')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/ppc_4.png" alt="">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-6">
          <h4 class="modal-text-subtitle">Inventario</h4>
          <div class="row">
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('inventory')}"
                (click)="addToDashboard('inventory')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/inventory.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h4 class="modal-text-subtitle">Insights</h4>
          <div class="row">
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('insights_1')}"
                (click)="addToDashboard('insights_1')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/insights_1.png" alt="">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card"
                [ngClass]="{'chart-selected': isChartSelected('insights_2')}"
                (click)="addToDashboard('insights_2')">
                <div class="card-body">
                  <img src="../../../../../assets/images/dashboard/insights_2.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

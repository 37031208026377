<ng-container *ngIf="!loading; else loadingContent">
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="row mt-10">
          <div class="col text-center">
            <p class="text-muted mb-0 font-size-12">ACOS</p>
            <span class="font-bold font-size-18">{{calculate && calculate.acos ? (calculate.acos.toFixed(2) | number:'1.2-2' ) + '%' : 0}}</span>
          </div>
          <div class="col text-center">
            <p class="text-muted mb-0 font-size-12">ROAS</p>
            <span class="font-bold font-size-18">{{calculate && calculate.spend ? ((calculate.sales / calculate.spend).toFixed(2) | number:'1.2-2')  : 0}}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <apx-chart class="apex-charts" dir="ltr" [series]="dashedLineChart.series" [chart]="dashedLineChart.chart"
          [dataLabels]="dashedLineChart.dataLabels" [stroke]="dashedLineChart.stroke"
          [colors]="dashedLineChart.colors" [xaxis]="dashedLineChart.xaxis" [grid]="dashedLineChart.grid"
          [tooltip]="dashedLineChart.tooltip" [plotOptions]="dashedLineChart.plotOptions"
          [fill]="dashedLineChart.fill" [markers]="dashedLineChart.markers" [legend]="dashedLineChart.legend"
          [yaxis]="dashedLineChart.yaxis" [tooltip]="dashedLineChart.tooltip"></apx-chart>
        </div>
        <div class="col-6">
          <apx-chart class="apex-charts" dir="ltr" [series]="roasDashedLineChart.series" [chart]="roasDashedLineChart.chart"
          [dataLabels]="roasDashedLineChart.dataLabels" [stroke]="roasDashedLineChart.stroke"
          [colors]="roasDashedLineChart.colors" [xaxis]="roasDashedLineChart.xaxis" [grid]="roasDashedLineChart.grid"
          [tooltip]="roasDashedLineChart.tooltip" [plotOptions]="roasDashedLineChart.plotOptions"
          [fill]="roasDashedLineChart.fill" [markers]="roasDashedLineChart.markers" [legend]="roasDashedLineChart.legend"
          [yaxis]="roasDashedLineChart.yaxis" [tooltip]="roasDashedLineChart.tooltip"></apx-chart>
        </div>
      </div>

    </div>
  </div>
</ng-container>
<ng-template #loadingContent>
  <div class="row">
    <app-skeleton-element-list [typeOfContentSkeleton]="'card-chart'"></app-skeleton-element-list>
  </div>
</ng-template>

import { Component, OnInit, ViewChild } from '@angular/core';
import { TotalSalesContainer } from './total-sales.container';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TilesService } from 'src/app/core/services/tiles.service';
import { NgbCarousel, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-total-sales',
  templateUrl: './total-sales.component.html',
  styleUrls: ['./total-sales.component.scss']
})

/**
 * Dashboard Component
 */
export class TotalSalesComponent extends TotalSalesContainer implements OnInit {
  @ViewChild('carouselCards') carouselCards: NgbCarousel;

  constructor(public tilesService: TilesService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    super(tilesService, indexDbCacheService, loadingSpinnerService);
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'VXI' }, { label: 'Dashboard'}, { label: 'Total Sales', active: true }];
    this.accordionStates = Array(this.arrayOfDate.length).fill(false);
  }

  searchByPeriod() {
    const initDate = this.utilities.getDateInStringTimezone(new Date(this.initDate.year, this.initDate.month - 1, this.initDate.day));
    const endDate = this.utilities.getDateInStringTimezone(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day));
    this.getFinancesByDates(initDate, endDate);
    const dateRange = this.utilities.convertFormatDate(initDate.split('T')[0]) + ' - ' + this.utilities.convertFormatDate(endDate.split('T')[0]);
    this.dateSelectByRange = {title:"Selected Date", date: dateRange}
  }

  setMinDate() {
    if (this.initDate) {
      this.onInitDateNavigate(this.initDate as NgbDate);
    }
  }

  onInitDateNavigate(date: NgbDate) {
    let nextDay = new Date(date.year, date.month - 1, date.day + 1);

    // Verificar si el próximo día está fuera de los límites del mes
    if (nextDay.getMonth() !== date.month - 1) {
      // Si es así, establecer el primer día del próximo mes
      nextDay = new Date(date.year, date.month, 1);
    }

    // Convertir la fecha al formato NgbDate
    this.minEndDate = { year: nextDay.getFullYear(), month: nextDay.getMonth() + 1, day: nextDay.getDate() } as NgbDate;
  }

  prevSlide() {
    if (this.carouselCards) {
      this.carouselCards.prev();
    }
  }

  nextSlide() {
    if (this.carouselCards) {
      this.carouselCards.next();
    }
  }
}

<div class="container-empty-search">
  <div class="row">
    <div class="col-lg-12">
      <div class="empty-search">
        <div class="empty-search__icon">
          <img src="../../../assets/images/empty.png" class="image-icon" alt="empty search">
        </div>
        <div class="empty-search__title">
          <h3>{{'EMPTY_SEARCH.TITLE' | translate}}</h3>
        </div>
        <div class="empty-search__description">
          <p>{{'EMPTY_SEARCH.DESCRIPTION' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

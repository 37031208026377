import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SkeletonElementListComponent } from './skeleton-element-list.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [ CommonModule, FormsModule, TranslateModule],
  declarations: [SkeletonElementListComponent],
  exports: [SkeletonElementListComponent]
})
export class SkeletonElementListComponentModule {}

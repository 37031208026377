import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-skeleton-element-list',
  templateUrl: './skeleton-element-list.component.html',
  styleUrls: ['./skeleton-element-list.component.scss']
})

/**
 * Pages-500 component
 */
export class SkeletonElementListComponent implements OnInit {
  @ViewChild('progress') progressbar!: ElementRef;
  @Input() typeOfContentSkeleton?: string;

  constructor() { }

  ngOnInit(): void {

  }


}

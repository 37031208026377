import { Products } from 'src/app/core/models/products.model';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ChartTypeRadialChart } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import Utils from 'src/app/core/utilities/utils';
import ShareFunction from 'src/app/core/utilities/share-function';
import { basicRadialBarChart } from '../../data';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export class TableProductsContainer {
  public utilities: Utils;
  public shareFunctions: ShareFunction;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];
  public productsToList: Products[] = [];
  noResults: boolean = false;
  searchProductTile: string | undefined;
  loading: boolean = true;
  selectedProduct: Products | undefined;
  radialBarChart: ChartTypeRadialChart;
  maxSalesProduct: Products;
  keyToSearch: string = 'sales';


  constructor(public productsService: ProductService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.utilities = new Utils();
    this.radialBarChart = basicRadialBarChart;
    this.shareFunctions = new ShareFunction(loadingSpinnerService, productsService, this.utilities);
  }

  async fetchProducts(initDate?: string, endDate?: string, syncData: boolean = false, overwriteCache: boolean = true, keyToSearch?: string) {
    try {
      const response = await this.shareFunctions.getProducts(initDate, endDate, syncData, overwriteCache, keyToSearch);
      this.loading = response.loading;
      this.products = response.products;
      this.noResults = response.noResults;
      this.productsToSearch = response.productsToSearch;
      this.productsToList = response.productsToList;
      this.maxSalesProduct = response.maxSalesProduct;
    } catch (error) {
      this.loadingSpinnerService.showAlertError();
    }
  }
}

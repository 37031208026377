import { LoadingSpinnerService } from './../../../../../core/services/loading-spinner.service';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Products } from 'src/app/core/models/products.model';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ProductService } from 'src/app/core/services/products.service';
import { TableProductsContainer } from './table-products.container';
import { NgbCarousel, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-table-products',
  templateUrl: './table-products.component.html',
  styleUrls: ['./table-products.component.scss']
})

/**
 * Dashboard Component
 */
export class TableProductsComponent extends TableProductsContainer implements OnInit, OnChanges {
  @Input() searchTerm: string;
  @Input() keyToSearch: string = 'sales';
  keySearch: string = 'sales';

  constructor(
    public productsService: ProductService,
    public indexDbCacheService: IndexedDbCacheService,
    public loadingSpinnerService: LoadingSpinnerService,
    public modalService: NgbModal
  ) {
    super(productsService, indexDbCacheService, loadingSpinnerService);
  }

  ngOnInit(): void {
    this.fetchProducts();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerm'] && !changes['searchTerm'].isFirstChange()) {
      const results = this.utilities.filterProductsToList(this.searchTerm, this.keyToSearch, this.products);
      this.productsToList = results.productsToList;
      this.maxSalesProduct = results.maxSalesProduct;
    }

    if (changes['keyToSearch'] && !changes['keyToSearch'].isFirstChange()) {
      this.keySearch = this.keyToSearch;
    }
  }
}

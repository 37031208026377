import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, ChartTypeApex } from '../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { dashedLineChart, dashedLineChartByHour } from './data';
import { PaginationInstance } from 'ngx-pagination';
import { InventoryService } from 'src/app/core/services/inventory.service';
import * as echarts from 'echarts';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import ShareFunctionsInventory from 'src/app/core/utilities/share-functions-inventory';

export class InsightsContainer {
  minEndDate: NgbDate;
  public utilities: Utils;
  public shareFunctionsInventory: ShareFunctionsInventory;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  orderTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  filters: any[] = []
  orders: any[] = [];
  ordersToSearch: any[] = [];
  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 10000, // Total de elementos
  };

  totalsByStatus: any[];
  statesMx: any[];
  topStates: any[];
  view: string = 'map';
  dataForMunicipality: any[] = [];
  isLoadedAMunicipality: boolean = false;
  counterByDay: any;
  weeks: string[] = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  dashedLineChartByHour: ChartTypeApex;
  selectedDay = null;
  showDatesSection: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  endedDate: any;
  initialDate: any;

  constructor(public inventoryService: InventoryService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.dashedLineChart = dashedLineChart;
    this.dashedLineChartByHour = dashedLineChartByHour;
    this.utilities = new Utils();
    this.initializeFilters();
    this.shareFunctionsInventory = new ShareFunctionsInventory(loadingSpinnerService, inventoryService, this.utilities);
  }

  initializeFilters() {
    this.filtersDate = [
      { title: 'Ultimo 7 Dias', key: 'seven_days' },
      { title: 'Ultimos 14 Dias', key: 'fourteen_days' },
      { title: 'Ultimo 30 Dias', key: 'thirty_days' },
      { title: 'Trimestre En Curso', key: 'current_quarter' },
      { title: 'Trimestre Anterior', key: 'quarter' },
      { title: '2 Trimestres Anteriores', key: 'previous_quarter' },
      { title: 'Rango de Fechas', key: 'free_dates' }
    ];
  }

  activeFilteDate(filter: any) {
    const results = this.utilities.generateRangeOfDates(filter);

    this.endedDate = null;
    this.initialDate = null;
    this.textFilterDate = results.titleFilter;
    this.showDatesSection = results.showDatesSection;
    if (!results.showDatesSection) {
      this.endedDate = results.endedDate;
      this.initialDate = results.initialDate;
      this.fetchCampaings(results.initialDate, results.endedDate)
    }
  }

  async fetchCampaings(initDate?: string, endDate?: string) {
    try {
      const response = await this.shareFunctionsInventory.getCampaingsByDate(initDate, endDate);
      this.loadingTable = response.loadingTable;
      this.orders = response.orders;
      this.noResults = response.noResults;
      this.ordersToSearch = response.ordersToSearch;
    } catch (error) {
      this.loadingSpinnerService.showAlertError();
      console.log(error, 'error in inventory container')
    }
  }
}

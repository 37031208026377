import { ResponseService } from "../models/response.models";
import { InventoryService } from "../services/inventory.service";
import { LoadingSpinnerService } from "../services/loading-spinner.service";
import { LocalStorageService } from "../services/local-storage.service";
import { ProductService } from "../services/products.service";
import Utils from "./utils";

class ShareFunction {
  public localStorageService: LocalStorageService;
  public utilities: Utils;
  private loadingSpinnerService: LoadingSpinnerService;
  private productsService: ProductService;

  constructor(
    loadingSpinnerService: LoadingSpinnerService,
    productsService: ProductService,
    utilities: Utils
  ) {
    this.loadingSpinnerService = loadingSpinnerService;
    this.productsService = productsService;
    this.utilities = utilities;
  }

  async getProducts(
    initDate?: string,
    endDate?: string,
    syncData: boolean = false,
    overwriteCache: boolean = true,
    keyToSearch?: string
  ): Promise<{
    loading: boolean,
    products: any[],
    noResults: boolean,
    productsToSearch: any[],
    productsToList: any[],
    maxSalesProduct: any
  }> {
    this.loadingSpinnerService.show();
    const queryParameters: any = {};
    if (initDate) {
      queryParameters['initDate'] = initDate;
    }
    if (endDate) {
      queryParameters['endDate'] = endDate;
    }

    return new Promise(async (resolve, reject) => {
      (await this.productsService.getAllProductsByOrders(syncData, overwriteCache, queryParameters)).subscribe(
        (response: ResponseService) => {
          this.loadingSpinnerService.hide();
          resolve({
            loading: false,
            products: response.data,
            noResults: response.data.length === 0,
            productsToSearch: this.utilities.chunkArray(response.data, 4),
            productsToList: response.data,
            maxSalesProduct: response.data.reduce((max, current) => {
              return (max[keyToSearch] > current[keyToSearch]) ? max : current;
            }, response.data[0])
          });
        },
        (error) => {
          this.loadingSpinnerService.hide();
          reject(error);
        }
      );
    });
  }
}

export default ShareFunction;

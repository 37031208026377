// loading-spinner.component.ts

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-loading-spinner',
  template: `
    <div *ngIf="isLoading" class="loading-spinner-overlay">
      <div class="loading-spinner"></div>
    </div>
  `,
  styles: [`
    .loading-spinner-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
    }

    .loading-spinner {
      border: 4px solid #3498db;
      border-top: 4px solid transparent;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  private subscription: Subscription;

  constructor(private loadingSpinnerService: LoadingSpinnerService) {}

  ngOnInit() {
    this.subscription = this.loadingSpinnerService.isLoading$.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

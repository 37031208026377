import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbNavModule, NgbCarouselModule, NgbDropdownModule, NgbTooltipModule, NgbPaginationModule, NgbCollapseModule, NgbModalModule, NgbAccordionModule, NgbPopoverModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { modalProfileContentComponent } from './modals/modals.component';
import { EmptySearchComponentModule } from 'src/app/support/empty-search/empty-search.module';
import * as echarts from 'echarts';
import { modalAmazonProcessComponent } from './modals/modal-amazon-process/modal-amazon-process.component';
import {modalDashboardSelectionComponent} from './modals/modal-dashboard-selection/modal-dashboard-selection.component';


@NgModule({
  declarations: [PagetitleComponent, modalProfileContentComponent, modalAmazonProcessComponent, modalDashboardSelectionComponent],
  imports: [
    CommonModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbAccordionModule,
    NgApexchartsModule,
    NgChartsModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    NgbPopoverModule,
    NgbDatepickerModule,
    EmptySearchComponentModule
  ],
  exports: [PagetitleComponent]
})
export class UiModule { }

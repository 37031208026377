import { ChartType } from "src/app/core/models/elements.model";

const lineBarChart: ChartType = {
    labels: [
        'Month to Date',
        'Last Month'
    ],
    datasets: [
        {
            label: 'Analytics',
            backgroundColor: 'rgba(6, 147, 191, 0.8)',
            borderColor: 'rgba(6, 147, 191, 0.8)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(52, 195, 143, 0.9)',
            hoverBorderColor: 'rgba(52, 195, 143, 0.9)',
            data: [45, 56],
            barPercentage: 0.4

        },
    ],
    options: {
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        color: 'rgba(166, 176, 207, 0.1)'
                    },
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        color: 'rgba(166, 176, 207, 0.1)'
                    }
                }
            ]
        }
    }
};

export { lineBarChart };

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, from, mergeMap, of, map, throwError, takeUntil, BehaviorSubject } from 'rxjs';
import { IndexedDbCacheService } from './index-db-cache.service';
import { OnlineOfflineService } from './online-offline.service';
import { Profiles } from '../models/profiles.models';
import { ResponseService, ResponseServiceObject } from '../models/response.models';
import Utils from '../utilities/utils';
import { LocalStorageService } from './local-storage.service';
import { HttpRequestService } from './http-request.service';

@Injectable({ providedIn: 'root' })
export class InventoryService {

  public globalUrl: string;
  public utilities: Utils;

  private searchTermSubject = new BehaviorSubject<string>('');
  searchTerm$ = this.searchTermSubject.asObservable();

  private dayWeekSubject = new BehaviorSubject<number>(null);
  dayWeek$ = this.dayWeekSubject.asObservable();

  constructor(
    public _http: HttpClient,
    private indexDBCacheService: IndexedDbCacheService,
    private localStorageService: LocalStorageService,
    private httpRequestService: HttpRequestService,
    private onlineOfflineService: OnlineOfflineService) {
    this.utilities = new Utils();
    this.globalUrl = environment.baseUrlProd;


    this.onlineOfflineService.connectionChanged.subscribe(online => {
      if (online) {
        console.log('went online');
      }
    });
  }

  setDayWeek(day: number) {
    this.dayWeekSubject.next(day);
  }

  setSearchTerm(term: string) {
    this.searchTermSubject.next(term);
  }

  public async fetchInfoOrdersBetweenDates(initDate?: string, endDate?: string): Promise<Observable<any>> {
    try {
      const dateLessThirtyDays = this.utilities.getFormattedDateYear(new Date(new Date().setDate(new Date().getDate() - 5)));
      const initDateSearch = initDate ? initDate : dateLessThirtyDays;
      const endDateSearch = endDate ? endDate : this.utilities.getFormattedDateYear(new Date());

      const response = this._http.get(this.globalUrl + 'orders-by-user/' + initDateSearch + '/' + endDateSearch)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );
      return response ? response : of(null);
    } catch (error) {
      throw error;
    }
  }

  public fetchStates(): Observable<any> {
    const filePath = 'assets/geojson/mx.json';
    return this._http.get(filePath).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error fetching states:', error);
        return of(null); // Retorna un observable con valor nulo en caso de error
      })
    );
  }

  public fetchPostalCodes(fileName: string): Observable<any> {
    const filePath = 'assets/geojson/CP/' + fileName;
    return this._http.get(filePath).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error fetching states:', error);
        return of(null); // Retorna un observable con valor nulo en caso de error
      })
    );
  }

  public fetchZipCodes(): Observable<any> {
    const filePath = 'assets/json/postalCodes.json';
    return this._http.get(filePath).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error fetching states:', error);
        return of(null); // Retorna un observable con valor nulo en caso de error
      })
    );
  }

  private combineData(cachedData: Profiles[], newData: Profiles[]): Profiles[] {
    const uniqueData = newData.filter(item => !cachedData.some(existingItem => existingItem.id === item.id));
    return [...cachedData, ...uniqueData];
  }
}

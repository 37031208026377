import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../shared/ui/ui.module';
import { PagesRoutingModule } from './pages-routing.module';
import { NgbNavModule, NgbCarouselModule, NgbDropdownModule, NgbTooltipModule, NgbPaginationModule, NgbCollapseModule, NgbModalModule, NgbAccordionModule, NgbPopoverModule, NgbDatepickerModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SkuListComponent } from './products/sku-list/sku-list.component';
import { PPCComponent } from './ppc/ppc.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InsightsComponent } from './insights/insights.component';
import { TotalSalesComponent } from './dashboard/total-sales/total-sales.component';
import { FinancialComponent } from './financial/financial.component';
import { SkeletonElementListComponentModule } from '../support/skeleton-element-list/skeleton-element-list.module';
import { LineBreakPipe } from '../core/pipes/linebreak.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { EmptySearchComponentModule } from '../support/empty-search/empty-search.module';
import { NgxPaginationModule } from 'ngx-pagination';
import * as echarts from 'echarts';

//Products Components
import { DonutProductsModule } from './products/sku-list/components/donut-products/donut-products.module';
import { TableProductsModule } from './products/sku-list/components/table-products/table-products.module';
import { GaussProductsModule } from './products/sku-list/components/gauss-products/gauss-products.module';
import { MapChartInventoryModule } from './inventory/components/map-chart-inventory/map-chart-inventory.module';
import { TableCampaingsInventoryModule } from './inventory/components/table-campaings-inventory/table-campaings-inventory.module';
import { SellsByWeekDayModule } from './insights/components/sells-by-weekday/sells-by-weekday.module';
import { SelssByHourModule } from './insights/components/sells-by-hour/sells-by-hour.module';
import { FinancialPLModule } from './financial/components/finnancial-pl/financial-pl.module';
import { FinancialTrendsModule } from './financial/components/finnancial-trends/financial-trends.module';
import {SalesChartModule} from './ppc/components/sales-chart/sales-chart.module';
import {InteractionsChartModule} from './ppc/components/interactions-chart/interactions-chart.module';
import {ImpressionsChartModule} from './ppc/components/impressions-chart/impressions-chart.module';
import {ProfitChartModule} from './ppc/components/profit-chart/profit-chart.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent, SkuListComponent, TotalSalesComponent, PPCComponent, InventoryComponent, FinancialComponent, InsightsComponent,  LineBreakPipe],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbAccordionModule,
    NgApexchartsModule,
    NgChartsModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgxPaginationModule,
    PerfectScrollbarModule,
    DndModule,
    FullCalendarModule,
    LeafletModule,
    EmptySearchComponentModule,
    SkeletonElementListComponentModule,
    DonutProductsModule,
    TableProductsModule,
    GaussProductsModule,
    MapChartInventoryModule,
    TableCampaingsInventoryModule,
    SellsByWeekDayModule,
    SelssByHourModule,
    FinancialPLModule,
    FinancialTrendsModule,
    SalesChartModule,
    InteractionsChartModule,
    ImpressionsChartModule,
    ProfitChartModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class PagesModule { }

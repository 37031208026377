import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FinancialPLContainer } from './financial-pl.container';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TopBarService } from 'src/app/core/services/topbar.service';
import { NgbDate, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/core/services/products.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-financial-pl',
  templateUrl: './financial-pl.component.html',
  styleUrls: ['./financial-pl.component.scss'],
  providers: [NgbDropdownConfig]
})
export class FinancialPLComponent extends FinancialPLContainer implements OnInit {

  element: any;
  configData: any;
  selectedPrimaryTab: string;

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) private document: any,
                public router: Router,
                public cookiesService: CookieService,
                public topBarService: TopBarService,
                public indexDbCacheService: IndexedDbCacheService,
                public productsService: ProductService,
                public loadingSpinnerService: LoadingSpinnerService,
                config: NgbDropdownConfig) {
    super(topBarService, indexDbCacheService, productsService, loadingSpinnerService);
    this.selectedPrimaryTab = 'pl';
    config.autoClose = 'outside';
  }

  ngOnInit(): void {
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    this.getGeneralDataPL();
  }

  activeFilter(typeFilterSelected:any){
    this.typeFilterSelected = typeFilterSelected.value;
    this.textFilter = typeFilterSelected.name;
  }

  checkValueType(item: any): boolean {
    return isNaN(parseFloat(item));
  }

  convertToFloat(value: any): number {
    return parseFloat(value);
  }

  onInitDateNavigate(date: NgbDate) {
    let nextDay = new Date(date.year, date.month - 1, date.day + 1);

    // Verificar si el próximo día está fuera de los límites del mes
    if (nextDay.getMonth() !== date.month - 1) {
      // Si es así, establecer el primer día del próximo mes
      nextDay = new Date(date.year, date.month, 1);
    }

    // Convertir la fecha al formato NgbDate
    this.minEndDate = { year: nextDay.getFullYear(), month: nextDay.getMonth() + 1, day: nextDay.getDate() } as NgbDate;
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, takeUntil } from 'rxjs';
import { HttpRequestService } from '../services/http-request.service';

@Injectable()
export class CancelPendingRequestsInterceptor implements HttpInterceptor {
  constructor(private httpRequestService: HttpRequestService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Intercepta la solicitud y suscríbete al observable de cancelación
    return next.handle(req).pipe(takeUntil(this.httpRequestService.cancelPendingRequestsObservable));
  }
}

import { A } from "@fullcalendar/core/internal-common";

export class ResponseService {
  data?: any[] | null | undefined;
  ok?: boolean | null | undefined;
  cache?: boolean | null | undefined;
}

export class Finances {
  amazon_fee: number;
  fba: number;
  fee_comission: number;
  fee_giftwrap_chargeback: number;
  fee_shipping_chargeback: number;
  gros_profit: number;
  marketplace_facilitator_vat: number;
  orderCount: number;
  orderItemCount: number;
  orderUnitCount: number;
  orders: number;
  promotion: number;
  saleGlobal: number;
  sales: number;
  shipping_cost: number;
  shipping_price: number;
  shipping_tax: number;
  units: number;
}

export class FinancesData {
  [key: string]: Finances;
}

export class ResponseServiceObject {
  data?: FinancesData | null | undefined;
  ok?: boolean | null | undefined;
  cache?: boolean | null | undefined;
}

export class DataPL {
  header: Array<any | null | undefined>;
  rowSales: Array<any | null | undefined>;
  rowSalesShipped: Array<any | null | undefined>;
  rowUnits: Array<any | null | undefined>;
  rowPromotion: Array<any | null | undefined>;
  rowShippingCost: Array<any | null | undefined>;
  rowAmazonFee: Array<any | null | undefined>;
  rowVat: Array<any | null | undefined>;
  rowGrossProfit: Array<any | null | undefined>;
}

export class DataTrend {
  header: Array<any | null | undefined>;
  data: Array<any | null | undefined>;
}

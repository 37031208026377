import {Component, Input, ViewChild} from '@angular/core';

import {NgbModal, NgbActiveModal, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import { Profiles } from 'src/app/core/models/profiles.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-modal-users-profile',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class modalProfileContentComponent {
  @ViewChild('carouselCards') carouselCards: NgbCarousel;
  accounts: any[] = [];
  noResults: boolean = true;
  utilities: Utils;

  constructor(public activeModal: NgbActiveModal, public indexDbCacheService: IndexedDbCacheService, public localStorageService: LocalStorageService) {
    this.utilities = new Utils();
    this.getUserProfileData();
  }

  getUserProfileData() {
    this.indexDbCacheService.getRegister('accounts').then((response: Profiles[]) => {
      this.accounts = this.utilities.chunkArray(response, 4);
      this.noResults = response.length === 0;
    });
  }

  prevSlide() {
    if (this.carouselCards) {
      this.carouselCards.prev();
    }
  }

  nextSlide() {
    if (this.carouselCards) {
      this.carouselCards.next();
    }
  }

  selectAccount(account_id: number) {
    this.localStorageService.saveData('account_id', account_id.toString());
    this.activeModal.close();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}

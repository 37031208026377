import { LoadingSpinnerService } from './../../core/services/loading-spinner.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AlertSyncService } from './../../core/services/alert-sync.service';
import { modalAmazonProcessComponent } from 'src/app/shared/ui/modals/modal-amazon-process/modal-amazon-process.component';
import { modalDashboardSelectionComponent } from 'src/app/shared/ui/modals/modal-dashboard-selection/modal-dashboard-selection.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDate, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DatepickerElement } from 'src/app/core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  userType: string;
  amazonSPAPIStatus: string;
  authorizationRouteAds: string;
  authorizationRouteSeller: string;
  authorizationRouteVendor: string;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  initialDate:any;
  endedDate:any;
  minEndDate: NgbDate;
  showDatesSection: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];

  public utilities: Utils;

  constructor(
    private localStorageService: LocalStorageService,
    public modalService: NgbModal,
    private alertSyncService: AlertSyncService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
    this.utilities = new Utils();
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  account_id: number = null;
  addedDashboardElements: any = [];

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'VXI' }, { label: 'Dashboard', active: true }];
    this.account_id = Number(this.localStorageService.getData('account_id'));
    this.initializeFilters();
    this.subscription = this.localStorageService.addedCharts$.subscribe(addedElements => {
      this.addedDashboardElements = addedElements;
      console.log(this.addedDashboardElements)
    });
    this.getChannelData();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isChartSelected(chart: string): boolean {
    return this.addedDashboardElements.includes(chart);
  }

  initializeFilters() {
    this.filtersDate = [
      { title: 'Ultimo 7 Dias', key: 'seven_days' },
      { title: 'Ultimos 14 Dias', key: 'fourteen_days' },
      { title: 'Ultimo 30 Dias', key: 'thirty_days' },
      { title: 'Trimestre En Curso', key: 'current_quarter' },
      { title: 'Trimestre Anterior', key: 'quarter' },
      { title: '2 Trimestres Anteriores', key: 'previous_quarter' },
      { title: 'Rango de Fechas', key: 'free_dates' }
    ];
  }

  openExlargeModal() {
    const modalOptions: NgbModalOptions = {
      size: 'xl',
      keyboard: false,
      backdrop: 'static', // Configura el backdrop como 'static'
    };
    const modalRef = this.modalService.open(modalAmazonProcessComponent, modalOptions);
  }

  openModalDashboardSelection() {
    const modalOptions: NgbModalOptions = {
      size: 'xl',
      keyboard: false,
      backdrop: 'static', // Configura el backdrop como 'static'
    };
    const modalRef = this.modalService.open(modalDashboardSelectionComponent, modalOptions);
  }

  getChannelData() {
    this.userType = this.localStorageService.getData('userType');
    this.amazonSPAPIStatus = this.localStorageService.getData('amazonSPAPIStatus');
    if (this.amazonSPAPIStatus === 'downloading_information' || this.amazonSPAPIStatus === 'queued') {
      this.checkStatusOfDowloadings(this.amazonSPAPIStatus);
    } else if (this.amazonSPAPIStatus === 'url_authorization') {
      this.openExlargeModal();
    } else if (this.amazonSPAPIStatus === 'activated' && this.account_id) {
      if (this.addedDashboardElements.length === 0) {
        this.openModalDashboardSelection();
      }
    }
  }

  checkStatusOfDowloadings(status: string) {
    this.alertSyncService.checkDowloadStatus().subscribe((res: any) => {
      if (this.amazonSPAPIStatus === 'downloading_information' &&
          (res.data.status_sp_api === 'downloading_information' ||
          res.data.status_sp_api === 'downloading_ads' ||
          res.data.status_sp_api === 'downloading_information')) {
        this.loadingSpinnerService.showAlertDownload();
      } else if (this.amazonSPAPIStatus === 'queued' &&
          (res.data.status_sp_api === 'downloading_information' ||
          res.data.status_sp_api === 'downloading_ads' ||
          res.data.status_sp_api === 'downloading_information.')) {
        this.loadingSpinnerService.showAlertQueued();
      }
    } , (error) => {
      this.loadingSpinnerService.showSubscriptionExpireAlert();
    } );
  }

  searchByDates(): void {
    if (this.initDate) {
      this.onInitDateNavigate(this.initDate as NgbDate);
    }

    if (this.initDate && this.endDate) {
      this.initialDate = this.utilities.getDateInStringTimezone(new Date(this.initDate.year, this.initDate.month - 1, this.initDate.day));
      this.endedDate = this.utilities.getDateInStringTimezone(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day));
    } else if (!this.initDate && !this.endDate) {
      this.initialDate = null;
      this.endedDate = null;
    }
  }

  onInitDateNavigate(date: NgbDate) {
    let nextDay = new Date(date.year, date.month - 1, date.day + 1);

    // Verificar si el próximo día está fuera de los límites del mes
    if (nextDay.getMonth() !== date.month - 1) {
      // Si es así, establecer el primer día del próximo mes
      nextDay = new Date(date.year, date.month, 1);
    }

    // Convertir la fecha al formato NgbDate
    this.minEndDate = { year: nextDay.getFullYear(), month: nextDay.getMonth() + 1, day: nextDay.getDate() } as NgbDate;
  }

  activeFilteDate(filter: any) {
    const results = this.utilities.generateRangeOfDates(filter);

    this.endedDate = null;
    this.initialDate = null;
    this.textFilterDate = results.titleFilter;
    this.showDatesSection = results.showDatesSection;
    if (!results.showDatesSection) {
      this.endedDate = results.endedDate;
      this.initialDate = results.initialDate;
    }
  }
}

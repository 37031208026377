<div class="modal-header">
  <h5 class="modal-title mt-0">Selecciona una cuenta para visualizar su información</h5>
</div>
<div class="modal-body">
  <div class="row w-100" *ngIf="!noResults ; else emptyData">
      <div class="col-sm-12 d-flex justify-content-end mb-2">
        <button class="btn btn-primary mr-2 btn-sm center-elements-into-content" [disabled]="accounts.length <= 1" (click)="prevSlide()">
          <i class=" ri-arrow-left-line"></i>
        </button>
        <button class="btn btn-primary btn-sm center-elements-into-content" [disabled]="accounts.length <= 1" (click)="nextSlide()">
          <i class=" ri-arrow-right-line"></i>
        </button>
      </div>
      <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="false" [interval]="false" #carouselCards>
        <ng-template ngbSlide *ngFor="let chunkElem of accounts">
          <div class="row">
            <div class="card card-into-carousel col-sm-3 mb-1" *ngFor="let account of chunkElem; let i = index">
              <div class="card-body-into-carousel">
                <div class="center-image">
                  <ng-container *ngIf="account.country_code === 'MX'; else otherFlag">
                    <img class="card-img img-fluid image-flag-card" [src]="'../../../../assets/images/flags/mxn.png'" [alt]="account.country_code">
                  </ng-container>
                  <ng-template #otherFlag>
                    <ng-container *ngIf="account.country_code === 'USD'; else noFlag">
                      <img class="card-img img-fluid image-product-card" [src]="'../../../../assets/images/flags/us.png'" [alt]="account.country_code">
                    </ng-container>
                    <ng-template #noFlag>
                      <img class="card-img img-fluid image-product-card" [src]="'../../../../assets/images/us.png'" [alt]="account.country_code">
                    </ng-template>
                  </ng-template>
                </div>
                <div class="card-body text-center">
                  <div class="text-muted">
                    <span>Currency: {{account.currency_code}}</span>
                  </div>
                  <h4>{{account.name}}</h4>
                  <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-secondary btn-sm d-flex align-items-center justify-content-center" (click)="selectAccount(account.id)">
                      <i class=" ri-checkbox-circle-line mr-2"></i>
                      Seleccionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
  </div>

  <ng-template #emptyData>
    <app-empty-search></app-empty-search>
  </ng-template>
</div>

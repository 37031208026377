import { LoadingSpinnerService } from './../../../../core/services/loading-spinner.service';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { SellsByHourContainer } from './sells-by-hour.container';
import { NgbCarousel, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/core/utilities/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sells-by-hour',
  templateUrl: './sells-by-hour.component.html',
  styleUrls: ['./sells-by-hour.component.scss']
})

/**
 * Dashboard Component
 */
export class SellsByHourComponent extends SellsByHourContainer implements OnInit, OnChanges, OnDestroy  {
  private subscription: Subscription;
  @Input() searchTerm: string;
  @Input() initialDate: any;
  @Input() endedDate: any;

  constructor(
    public inventoryService: InventoryService,
    public indexDbCacheService: IndexedDbCacheService,
    public loadingSpinnerService: LoadingSpinnerService,
    public modalService: NgbModal
  ) {
    super(inventoryService, indexDbCacheService, loadingSpinnerService);
  }

  ngOnInit(): void {
    this.fetchCampaings();
    this.subscription = this.inventoryService.dayWeek$.subscribe(day => {
      this.filterPurchaseOrdersByDay(day);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['initialDate'] && !changes['initialDate'].isFirstChange()) || (changes['endedDate'] && !changes['endedDate'].isFirstChange())) {
      this.fetchCampaings(this.initialDate, this.endedDate);
    }
  }
}

import { LoadingSpinnerService } from './../../../../core/services/loading-spinner.service';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { TableCampaingsInventoryContainer } from './table-campaings-inventory.container';
import { NgbCarousel, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/core/utilities/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-campaings-inventory',
  templateUrl: './table-campaings-inventory.component.html',
  styleUrls: ['./table-campaings-inventory.component.scss']
})

/**
 * Dashboard Component
 */
export class TableCampaingsInventoryComponent extends TableCampaingsInventoryContainer implements OnInit, OnChanges, OnDestroy  {
  private subscription: Subscription;
  @Input() searchTerm: string;
  @Input() initialDate: any;
  @Input() endedDate: any;

  constructor(
    public inventoryService: InventoryService,
    public indexDbCacheService: IndexedDbCacheService,
    public loadingSpinnerService: LoadingSpinnerService,
    public modalService: NgbModal
  ) {
    super(inventoryService, indexDbCacheService, loadingSpinnerService);
  }

  ngOnInit(): void {
    this.fetchCampaings();
    this.subscription = this.inventoryService.searchTerm$.subscribe(term => {
      this.filterPurchaseOrdersByState(term);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerm'] && !changes['searchTerm'].isFirstChange()) {
      this.filterCampaings(this.searchTerm);
    }

    if ((changes['initialDate'] && !changes['initialDate'].isFirstChange()) || (changes['endedDate'] && !changes['endedDate'].isFirstChange())) {
      this.fetchCampaings(this.initialDate, this.endedDate);
    }
  }
}

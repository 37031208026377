import { Products } from 'src/app/core/models/products.model';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ChartTypeRadialChart } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import Utils from 'src/app/core/utilities/utils';
import ShareFunction from 'src/app/core/utilities/share-function';
import { basicRadialBarChart } from '../../data';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export abstract class DonutProductsContainer {
  abstract keyToSearch: string;
  public utilities: Utils;
  public shareFunctions: ShareFunction;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];
  public productsToList: Products[] = [];
  noResults: boolean = false;
  searchProductTile: string | undefined;
  loading: boolean = true;
  selectedProduct: Products | undefined;
  radialBarChart: ChartTypeRadialChart;
  maxSalesProduct: Products;

  constructor(public productsService: ProductService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.utilities = new Utils();
    this.radialBarChart = basicRadialBarChart;
    this.shareFunctions = new ShareFunction(loadingSpinnerService, productsService, this.utilities);
  }

  async fetchProducts(initDate?: string, endDate?: string, syncData: boolean = false, overwriteCache: boolean = true, keyToSearch?: string) {
    try {
      const response = await this.shareFunctions.getProducts(initDate, endDate, syncData, overwriteCache, keyToSearch);
      this.loading = response.loading;
      this.products = response.products;
      this.noResults = response.noResults;
      this.productsToSearch = response.productsToSearch;
      this.productsToList = response.productsToList;
      this.maxSalesProduct = response.maxSalesProduct;
      this.generateChart();
    } catch (error) {
      this.loadingSpinnerService.showAlertError();
    }
  }

  generateChart(searchFlag: boolean = false, searchTeam: string = '') {
      let newRadialBarChart = { ...this.radialBarChart };
      const formatDollars = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
      const keyWord = this.keyToSearch === 'sales' ? 'Sales Amount' : 'Units Sold';

      const sortedProducts = [...this.products].sort((a, b) => b[this.keyToSearch] - a[this.keyToSearch]);
      const topProducts = sortedProducts.slice(0, 10);
      const othersTotal = sortedProducts.slice(10).reduce((total, item) => total + item[this.keyToSearch], 0);

      let productsToDisplay = topProducts;
      if (searchFlag) {
          const searchTerm = searchTeam.toLowerCase();
          productsToDisplay = topProducts.filter(product =>
              (product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(searchTerm)
          );
      }

      newRadialBarChart.labels = productsToDisplay.map(item => `${item.seller_sku} | ${item.asin}`);
      newRadialBarChart.series = productsToDisplay.map(item => parseFloat(item[this.keyToSearch].toFixed(2)));

      if (!searchFlag) {
          newRadialBarChart.labels.push('Others');
          newRadialBarChart.series.push(parseFloat(othersTotal.toFixed(2)));
      }

      newRadialBarChart.tooltip = {
          custom: ({ series, seriesIndex, w }) => {
              const value = this.keyToSearch === 'sales' ? formatDollars.format(series[seriesIndex]) : series[seriesIndex];
              return `
                  <div class="box-tooltip">
                      <span class="product-name">${w.globals.labels[seriesIndex]}</span><br/>
                      <span class="product-value">${keyWord}: ${value}</span>
                  </div>
              `;
          }
      };

      this.radialBarChart = newRadialBarChart;
  }

}

import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, CalculeElement, CalculeByDay, ChartTypeApex, ChartTypeLineColumn } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { profitLineChart } from '../../data';
import { PPCService } from 'src/app/core/services/ppc.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export class ProfitChartContainer {
  public utilities: Utils;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  calculate: CalculeElement;
  calculeByDay: CalculeByDay[];
  profitLineChart: ChartTypeApex;

  constructor(public ppcService: PPCService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.profitLineChart = profitLineChart;
    this.utilities = new Utils();
  }

  async getAccountInfo() {
    (await this.ppcService.fetchInfoOfTheAccount()).subscribe((response: any) => {
      if (!response.status) {
        this.loadingSpinnerService.showAlertError();
        this.loadingSpinnerService.hide();
        return;
      }
      this.loading = false;
      this.calculate = response.data.calcule;
      this.calculeByDay = response.data.calcule_by_day;
      this.generateProfitChart();
    });
  }

  generateProfitChart() {
    let profitLineChart = JSON.parse(JSON.stringify(this.profitLineChart));
    profitLineChart.series[0].data =  this.calculeByDay.map((element: any) => Number(element.profit.toFixed(2)))
    profitLineChart.xaxis.categories = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.profitLineChart = profitLineChart;
  }
}

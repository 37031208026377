import { ActivatedRoute, Params } from '@angular/router';
import { Products } from 'src/app/core/models/products.model';
import { ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ChartTypeLineColumn, ChartTypeRadialChart, DatepickerElement } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import Utils from 'src/app/core/utilities/utils';
import { basicRadialBarChart, basicLineColumnsChart } from './data';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import ShareFunction from 'src/app/core/utilities/share-function';

export class SkuListContainer {
  public utilities: Utils;
  public shareFunctions: ShareFunction;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];
  public productsToList: Products[] = [];
  keyToSearch: string = 'sales';
  noResults: boolean = false;
  searchProductTile: string | undefined;
  loading: boolean = true;
  selectedProduct: Products | undefined;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  initDateSearch: any;
  endDateSearch: any;
  textFilter: string = 'Total Sales';
  filters: any[] = [];
  radialBarChart: ChartTypeRadialChart;
  chartOptions: ChartTypeLineColumn;
  maxSalesProduct: Products;
  showDatesSection: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  searchTerm: string = '';

  constructor(public productsService: ProductService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.initializeFilters();
    this.utilities = new Utils();
    this.shareFunctions = new ShareFunction(loadingSpinnerService, productsService, this.utilities);
    this.fetchProducts();
  }

  initializeFilters() {
    this.filters = [
      { title: 'Total Sales', key: 'sales' },
      { title: 'Units', key: 'units' }
    ];

    this.filtersDate = [
      { title: 'Ultimo 7 Dias', key: 'seven_days' },
      { title: 'Ultimos 14 Dias', key: 'fourteen_days' },
      { title: 'Ultimo 30 Dias', key: 'thirty_days' },
      { title: 'Trimestre En Curso', key: 'current_quarter' },
      { title: 'Trimestre Anterior', key: 'quarter' },
      { title: '2 Trimestres Anteriores', key: 'previous_quarter' },
      { title: 'Rango de Fechas', key: 'free_dates' }
    ];
  }

  activeFilter(filter: any) {
    this.textFilter = filter.title;
    this.keyToSearch = filter.key;
  }

  activeFilteDate(filter: any) {
    const results = this.utilities.generateRangeOfDates(filter);
    this.textFilterDate = results.titleFilter;
    this.showDatesSection = results.showDatesSection;
    if (!results.showDatesSection) {
      this.fetchProducts(results.initialDate, results.endedDate)
    }
  }

  manualSyncDataFromService() {
    this.loading = !this.loading;
    this.fetchProducts('', '', true, true);
  }

  filterProducts(searchTerm: string | undefined, products: Products[]): void {
    this.searchTerm = searchTerm.toLowerCase();
    const results = this.utilities.filterProductsToList(searchTerm, this.keyToSearch, products)
    this.productsToSearch = results.productsToSearch;
    this.productsToList = results.productsToList;
    this.maxSalesProduct = results.maxSalesProduct;
    this.noResults = results.noResults;
  }

  async fetchProducts(initDate?: string, endDate?: string, syncData: boolean = false, overwriteCache: boolean = true, keyToSearch?: string) {
    try {
      const response = await this.shareFunctions.getProducts(initDate, endDate, syncData, overwriteCache, keyToSearch);
      this.loading = response.loading;
      this.products = response.products;
      this.noResults = response.noResults;
      this.productsToSearch = response.productsToSearch;
      this.productsToList = response.productsToList;
      this.maxSalesProduct = response.maxSalesProduct;
    } catch (error) {
      this.loadingSpinnerService.showAlertError();
    }
  }
}

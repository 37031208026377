<div class="container-fluid">
  <app-pagetitle title="Insights" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="col-sm-12 mb-30">
    <div class="card">
      <div class="card-container-filters">
        <div class="d-flex align-items-center justify-content-end">
          <div class="row mr-2" *ngIf="showDatesSection">
            <div class="col-sm-6 date-search">
              <span class="ri-calendar-line"></span>
              <input
                [ngModelOptions]="{standalone: true}" name="initDate" id="initDate"
                [(ngModel)]="initDate" (ngModelChange)="searchByDates()" ngbDatepicker
                class="form-control" autoClose="false" placeholder="YYYY-MM-DD"
                (click)="date.toggle()" #date="ngbDatepicker"/>
            </div>
            <div class="col-sm-6 date-search">
              <span class="ri-calendar-line"></span>
              <input
                [ngModelOptions]="{standalone: true}" name="endDate" id="endDate"
                [minDate]="minEndDate" [(ngModel)]="endDate" (ngModelChange)="searchByDates()"
                ngbDatepicker class="form-control" autoClose="false" placeholder="YYYY-MM-DD" (click)="dateEnd.toggle()"
                #dateEnd="ngbDatepicker"/>
            </div>
          </div>

          <div class="dropdown" ngbDropdown>
            <button class="btn btn-sm btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
              ngbDropdownToggle>
              {{textFilterDate}} <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <p class="dropdown-item dropdown-chart-filters-item"
                *ngFor="let filter of filtersDate"
                (click)="activeFilteDate(filter)">
                {{filter.title}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-sells-by-weekday
      [initialDate]="initialDate"
      [endedDate]="endedDate"
    ></app-sells-by-weekday>

    <app-sells-by-hour
      [initialDate]="initialDate"
      [endedDate]="endedDate"
    ></app-sells-by-hour>
</div>




import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OnlineOfflineService {

  get isOnline(): boolean {
    return navigator.onLine;
  }

  // Eventos online/offline
  get connectionChanged(): Observable<boolean> {
    return fromEvent(window, 'online').pipe(
      map(() => this.isOnline)
    );
  }
}

// indexed-db.service.ts
import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from 'idb';
import { Products } from '../models/products.model';
import { PL } from '../models/pl.models';

@Injectable({
  providedIn: 'root',
})
export class IndexedDbCacheService {
  private dbName = 'vxi-front-db';
  private dbVersion = 1;
  private db: IDBPDatabase;

  constructor() {}

  async openDatabase() {
    this.db = await openDB(this.dbName, this.dbVersion, {
      upgrade(db) {
        // Define las tablas aquí durante la creación o actualización de la base de datos
        if (!db.objectStoreNames.contains('products')) {
          db.createObjectStore('products', { keyPath: 'id', autoIncrement: true });
        }

        if (!db.objectStoreNames.contains('pl')) {
          db.createObjectStore('pl', { keyPath: 'id', autoIncrement: true });
        }

        if (!db.objectStoreNames.contains('trends')) {
          db.createObjectStore('trends', { keyPath: 'id', autoIncrement: true });
        }

        if (!db.objectStoreNames.contains('tiles')) {
          db.createObjectStore('tiles', { keyPath: 'id', autoIncrement: true });
        }

        if (!db.objectStoreNames.contains('accounts')) {
          db.createObjectStore('accounts', { keyPath: 'id', autoIncrement: true });
        }
      },
    });
  }

  async cleanAllTables() {
    const tableToClean = ['products', 'pl', 'trends', 'tiles', 'accounts'];
    tableToClean.forEach(async (table) => {
      await this.cleanTable(table);
    });
  }

  async cleanTable(table: string) {
    if (this.db) {
      const transaction = this.db.transaction(table, 'readwrite');
      const objectStore = transaction.objectStore(table);
      await objectStore.clear();
    }
  }

  async saveRegister(table: string, data: any[]) {
    try {
      if (!this.db) {
        await this.openDatabase();
      }

      const tx = this.db.transaction(table, 'readwrite');
      const store = tx.objectStore(table);

      // Clear existing data
      this.cleanTable(table);

      if (data) {
        for (const product of data) {
          this.db.add(table, product);
        }
      }

    } catch (error) {
      console.error(`Error saving data to table ${table}:`, error);
    }
  }

  async getRegister(table: string) {
    if (!this.db) {
      await this.openDatabase();
    }

    try {
      const transaction = this.db.transaction(table, 'readonly');
      const objectStore = transaction.objectStore(table);
      return objectStore.getAll();
    } catch (error) {
      console.error(`Error fetching data from table ${table}:`, error);
      return undefined;
    }
  }
}

<div class="card">
  <div class="card-body">
    <div class="card-title d-flex align-items-center justify-content-between">
      <h4 class="card-title mb-0">
        Ventas x hora del día <span *ngIf="selectedDay || selectedDay === 0">Día: {{weeks[selectedDay]}}</span>
      </h4>

      <ng-container *ngIf="selectedDay || selectedDay === 0">
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="generateDataTotalsByHour()">Restart</button>
      </ng-container>
    </div>


    <apx-chart class="apex-charts" dir="ltr" [series]="dashedLineChartByHour.series" [chart]="dashedLineChartByHour.chart"
          [dataLabels]="dashedLineChartByHour.dataLabels" [stroke]="dashedLineChartByHour.stroke"
          [colors]="dashedLineChartByHour.colors" [xaxis]="dashedLineChartByHour.xaxis" [grid]="dashedLineChartByHour.grid"
          [tooltip]="dashedLineChartByHour.tooltip" [plotOptions]="dashedLineChartByHour.plotOptions"
          [fill]="dashedLineChartByHour.fill" [markers]="dashedLineChartByHour.markers" [legend]="dashedLineChartByHour.legend"
          [yaxis]="dashedLineChartByHour.yaxis" [tooltip]="dashedLineChartByHour.tooltip"></apx-chart>
  </div>
</div>

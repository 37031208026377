import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, from, mergeMap, of, map, throwError, takeUntil } from 'rxjs';
import { IndexedDbCacheService } from './index-db-cache.service';
import { OnlineOfflineService } from './online-offline.service';
import { Profiles } from '../models/profiles.models';
import { ResponseService, ResponseServiceObject } from '../models/response.models';
import Utils from '../utilities/utils';
import { LocalStorageService } from './local-storage.service';
import { HttpRequestService } from './http-request.service';

@Injectable({ providedIn: 'root' })
export class PPCService {

  public globalUrl: string;
  public utilities: Utils;

  constructor(
    public _http: HttpClient,
    private indexDBCacheService: IndexedDbCacheService,
    private localStorageService: LocalStorageService,
    private httpRequestService: HttpRequestService,
    private onlineOfflineService: OnlineOfflineService) {
    this.utilities = new Utils();
    this.globalUrl = environment.baseUrlProd;

    this.onlineOfflineService.connectionChanged.subscribe(online => {
      if (online) {
        this.getAllAccounts(true, true);
      }
    });
  }

  async getAllAccounts(syncData: boolean = false, overwriteCache: boolean = true): Promise<Observable<any>> {
    try {
      const cachedData: Profiles[] = await this.indexDBCacheService.getRegister('accounts');

      if (cachedData && cachedData.length > 0 && !syncData) {
        return of({ data: cachedData, cache: true});
      }

      const newData = await this.fetchDataFromService();

      if (overwriteCache) {
        await this.indexDBCacheService.saveRegister('accounts', newData);
      } else {
        const combinedData = this.combineData(cachedData, newData);
        await this.indexDBCacheService.saveRegister('accounts', combinedData);
      }

      return of({ data: newData, cache: false});
    } catch (error) {
      throw error;
    }
  }

  private async fetchDataFromService(): Promise<Profiles[]> {
    try {
      const observable = this._http.get<ResponseService>(this.globalUrl + 'users-profiles')
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );

      const response = await observable.toPromise();
      return response ? response.data : null;
    } catch (error) {
      throw error;
    }
  }

  public async fetchInfoOfTheAccount(): Promise<Observable<any>> {
    try {
      const account_id = this.localStorageService.getData('account_id');
      const response = this._http.get(this.globalUrl + 'get-general-monthly-report-ads/' + account_id)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async fetchInfoAccountCampaingsByDate(date?: string): Promise<Observable<any>> {
    try {
      const dateSearch = date ? date : this.utilities.getFormattedDateYear(new Date());
      const account_id = this.localStorageService.getData('account_id');
      const response = this._http.get(this.globalUrl + 'get-information-campaigns-by-day/' + account_id + '/' + dateSearch)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  private combineData(cachedData: Profiles[], newData: Profiles[]): Profiles[] {
    const uniqueData = newData.filter(item => !cachedData.some(existingItem => existingItem.id === item.id));
    return [...cachedData, ...uniqueData];
  }
}

import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, ChartTypeApex } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { PaginationInstance } from 'ngx-pagination';
import { InventoryService } from 'src/app/core/services/inventory.service';
import * as echarts from 'echarts';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { dashedLineChart } from '../../data';
import ShareFunctionsInventory from 'src/app/core/utilities/share-functions-inventory';

export class SellsByWeekDayContainer {
  minEndDate: NgbDate;
  public utilities: Utils;
  public shareFunctionsInventory: ShareFunctionsInventory;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  orderTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  filters: any[] = []
  orders: any[] = [];
  ordersToSearch: any[] = [];
  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 10000, // Total de elementos
  };

  totalsByStatus: any[];
  view: string = 'map';
  dataForMunicipality: any[] = [];
  isLoadedAMunicipality: boolean = false;
  counterByDay: any;
  weeks: string[] = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  dashedLineChartByHour: ChartTypeApex;
  selectedDay = null;
  showDatesSection: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  endedDate: any;
  initialDate: any;

  constructor(public inventoryService: InventoryService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.dashedLineChart = dashedLineChart;
    this.utilities = new Utils();
    this.shareFunctionsInventory = new ShareFunctionsInventory(loadingSpinnerService, inventoryService, this.utilities);
  }

  async fetchCampaings(initDate?: string, endDate?: string) {
    try {
      const response = await this.shareFunctionsInventory.getCampaingsByDate(initDate, endDate);
      this.loadingTable = response.loadingTable;
      this.orders = response.orders;
      this.noResults = response.noResults;
      this.ordersToSearch = response.ordersToSearch;
      this.generateDataTotals();
    } catch (error) {
      console.log(error, 'error in inventory container')
    }
  }

  generateDataTotals() {
    const newBasicDashedLineChart = JSON.parse(JSON.stringify(this.dashedLineChart));
    const data = this.utilities.getCounterByDayForDates(this.ordersToSearch);
    this.counterByDay = data.counterByDay;
    newBasicDashedLineChart.series[0].data = data.counters;
    this.dashedLineChart = newBasicDashedLineChart;
    this.loading = false;
  }

  showSalesByDayOfTheWeek(day: number) {
    this.selectedDay = day;
    this.inventoryService.setDayWeek(day);
  }
}

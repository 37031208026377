import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Utils from '../utilities/utils';
import { IndexedDbCacheService } from './index-db-cache.service';
import { LoadingSpinnerService } from './loading-spinner.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AlertSyncService {
  public globalUrl: string;
  public utilities: Utils;

  constructor(
    private http: HttpClient,
    private indexDBCacheService: IndexedDbCacheService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
    this.globalUrl = environment.baseUrlProd;
  }

  autorizationAmazonSPAPI(data:any){
    return this.http.post(this.globalUrl + 'generate-token-sp-api', data);
  }

  confirmAutorizationSPAPI(data:any){
    return this.http.post(this.globalUrl + 'confirm-autorization-sp-api', data);
  }

  autorizationAmazonAds(data:any){
    return this.http.post(this.globalUrl + 'autorization', data);
  }

  autorizationAmazonSPAPIVendor(data:any){
    return this.http.post(this.globalUrl + 'generate-token-sp-api-vendor', data);
  }

  checkDowloadStatus() {
    return this.http.get(this.globalUrl + 'status-downloading-ads');
  }

  cerrarCargandoDatos() {
    Swal.close()
  }

  cargandoDatos(title: string) {
    // Mostrar el primer mensaje
    Swal.fire({
      title: title,
      text: 'Este proceso puede tardar unos minutos.',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading(null);
      },
      customClass: {
        container: 'swal2-container-position',
        popup: 'swal2-popup-custom-style'
      }
    });

    const messages = [
      "No te desesperes, sigue sincronizando los datos.",
      "Ya está por finalizar, ¡gracias por tu paciencia!",
    ];

    let currentMessageIndex = 0;

    const messageInterval = setInterval(() => {
      if (currentMessageIndex < messages.length) {
        Swal.update({
          title: 'Cargando datos',
          text: messages[currentMessageIndex],
        });
        currentMessageIndex++;
      } else {
        clearInterval(messageInterval);
      }
    }, 5 * 60 * 1000);
  }
}

import {Component, Input, ViewChild} from '@angular/core';

import {NgbModal, NgbActiveModal, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import { Profiles } from 'src/app/core/models/profiles.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-modal-amazon-process',
  templateUrl: './modal-amazon-process.component.html',
  styleUrls: ['./modal-amazon-process.component.scss']
})
export class modalAmazonProcessComponent {
  accounts: any[] = [];
  noResults: boolean = true;
  utilities: Utils;
  userType: string;
  error: string;
  amazonSPAPIStatus: string;
  authorizationRouteAds: string;
  authorizationRouteSeller: string;
  authorizationRouteVendor: string;

  constructor(public activeModal: NgbActiveModal, public indexDbCacheService: IndexedDbCacheService, public localStorageService: LocalStorageService) {
    this.utilities = new Utils();
    this.getChannelData();
  }

  getChannelData() {
    this.error = '';
    this.userType = this.localStorageService.getData('userType');
    this.amazonSPAPIStatus = this.localStorageService.getData('amazonSPAPIStatus');
    this.authorizationRouteAds = this.localStorageService.getData('authorizationRouteAds');
    this.authorizationRouteSeller = this.localStorageService.getData('authorizationRouteSeller');
    this.authorizationRouteVendor = this.localStorageService.getData('authorizationRouteVendor');
  }

  actionInModal(action: boolean) {
    if (!action) {
      if (this.userType === 'seller') {
        window.location.href = String(JSON.parse(this.localStorageService.getData("authorizationRouteSeller")));
      } else {
        window.location.href = String(JSON.parse(this.localStorageService.getData("authorizationRouteVendor")));
      }
    }
  }
}

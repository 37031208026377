import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ProfitChartContainer } from './profit-chart.container';
import { PPCService } from 'src/app/core/services/ppc.service';
import { modalProfileContentComponent } from 'src/app/shared/ui/modals/modals.component';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-profit-chart',
  templateUrl: './profit-chart.component.html',
  styleUrls: ['./profit-chart.component.scss']
})

/**
 * Dashboard Component
 */
export class ProfitChartComponent extends ProfitChartContainer implements OnInit  {
  account_id: number = 0;

  constructor(
    public ppcService: PPCService,
    public indexDbCacheService: IndexedDbCacheService,
    public localStorageService: LocalStorageService,
    public loadingSpinnerService: LoadingSpinnerService,
    private el: ElementRef
  ) {
    super(ppcService, indexDbCacheService, loadingSpinnerService);
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.account_id = Number(this.localStorageService.getData('account_id'));
    if (this.account_id) {
      this.getAccountInfo();
    }
  }
}

import { Products } from 'src/app/core/models/products.model';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ChartTypeLineColumn, ChartTypeRadialChart } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import Utils from 'src/app/core/utilities/utils';
import ShareFunction from 'src/app/core/utilities/share-function';
import { basicLineColumnsChart } from '../../data';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export class GaussProductsContainer {
  public utilities: Utils;
  public shareFunctions: ShareFunction;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];
  public productsToList: Products[] = [];
  noResults: boolean = false;
  searchProductTile: string | undefined;
  loading: boolean = true;
  selectedProduct: Products | undefined;
  chartOptions: ChartTypeLineColumn;
  maxSalesProduct: Products;
  keyToSearch: string = 'sales';


  constructor(public productsService: ProductService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.utilities = new Utils();
    this.chartOptions = basicLineColumnsChart;
    this.shareFunctions = new ShareFunction(loadingSpinnerService, productsService, this.utilities);
  }

  async fetchProducts(initDate?: string, endDate?: string, syncData: boolean = false, overwriteCache: boolean = true, keyToSearch?: string) {
    try {
      const response = await this.shareFunctions.getProducts(initDate, endDate, syncData, overwriteCache, keyToSearch);
      this.loading = response.loading;
      this.products = response.products;
      this.noResults = response.noResults;
      this.productsToSearch = response.productsToSearch;
      this.productsToList = response.productsToList;
      this.maxSalesProduct = response.maxSalesProduct;
      this.generateChart();
    } catch (error) {
      this.loadingSpinnerService.showAlertError();
    }
  }

  generateChart(searchFlag: boolean = false, searchTeam: string = '') {
    let newLineColumnChart = JSON.parse(JSON.stringify(this.chartOptions));
    if (!searchFlag) {
      newLineColumnChart.labels = this.products.map(item => `${item.seller_sku}`);
      newLineColumnChart.series[0].data = this.products.map(item => parseFloat(item['sales'].toFixed(2)));
      newLineColumnChart.series[1].data = this.products.map(item => parseFloat(item['units'].toFixed(2)));

    } else {
      newLineColumnChart.labels =  this.products.filter(product =>(product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(searchTeam.toLowerCase())).map(item => `${item.seller_sku}`);
      newLineColumnChart.series[0].data  = this.products.filter(product =>(product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(searchTeam.toLowerCase())).map(item => parseFloat(item['sales'].toFixed(2)));
      newLineColumnChart.series[1].data  = this.products.filter(product =>(product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(searchTeam.toLowerCase())).map(item => parseFloat(item['units'].toFixed(2)));
    }

    this.chartOptions = newLineColumnChart;
  }
}

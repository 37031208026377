import { ActivatedRoute, Params } from '@angular/router';
import { PL } from 'src/app/core/models/pl.models';
import { DataPL, DataTrend, ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TopBarService } from 'src/app/core/services/topbar.service';
import Utils from 'src/app/core/utilities/utils';
import { lineBarChart, dataTotals } from './data';
import { ChartType, DatepickerElement } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import { Products } from 'src/app/core/models/products.model';
import { PaginationInstance } from 'ngx-pagination';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export interface ProductFilter {
  name: string;
  value: string;
}

export class FinancialContainer {
  noResults: boolean = true;
  loadingPL: boolean = true;
  loadingTrends: boolean = true;
  noResultsTrends: boolean = true;
  loadingProductsList: boolean = true;
  noResultsProductsList: boolean = true;
  orderTable: boolean = true;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];

  dataPL: DataPL | undefined;
  dataTrend: DataTrend | undefined;
  dataTrendMaster: any[] | null | undefined;
  filterTrendDataMaster: any[] | null | undefined;
  typeFilterSelected: string;
  searchProductTrend: string;
  searchProductList: string;
  utilities: Utils = new Utils();
  textFilter: string = 'Filters';
  lineBarChart: ChartType;
  textFilterChart: string = 'Filters';
  dataTotals: any = dataTotals;
  initialTotals: any = dataTotals;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  minEndDate: NgbDate;

  productFilters: ProductFilter[] = [
    {"name": "Sales shipped", "value": "sales"},
    {"name": "Units", "value":"units"},
    {"name": "Promotion", "value":"promotion"},
    {"name": "Amazon fee", "value":"amazon_fee"},
    {"name": "Gross profit", "value":"gros_profit"}
  ];

  productFiltersChart: ProductFilter[] = [
    {"name": "Sales", "value":"rowSales"},
    {"name": "Sales shipped", "value": "rowSalesShipped"},
    {"name": "Unit Sold", "value":"rowUnits"},
    {"name": "Promotion", "value":"rowPromotion"},
    {"name": "Amazon fee", "value":"rowAmazonFee"},
    {"name": "Gross profit", "value":"rowGrossProfit"},
    {"name": "Shipping Cost", "value":"rowShippingCost"},
    {"name": "VAT", "value":"rowVat"},
  ];

  keyToChartFilter: string = 'rowSales';

  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 200, // Total de elementos
  };

  constructor(public topBarService: TopBarService, public indexDbCacheService: IndexedDbCacheService, public productsService: ProductService, public loadingSpinnerService: LoadingSpinnerService) {

  }

  sortColumn(column: string) {
    this.orderTable = !this.orderTable;
    this.productsToSearch = this.products.sort((a, b) => {
      // Verificar si a[column] y b[column] son cadenas de texto
      if (typeof a[column] === 'string' && typeof b[column] === 'string') {
          return a[column].localeCompare(b[column]);
      } else {
          // Si no son cadenas de texto, comparar directamente los valores
          return a[column] - b[column];
      }
    });

    if (!this.orderTable) {
      this.productsToSearch = this.productsToSearch.reverse();
    }
  }

  filterProducts(searchTerm: string | undefined): void {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (lowerCaseSearchTerm.trim() === '') {
      this.dataTrend.data = [...this.filterTrendDataMaster];
      this.noResultsTrends = false;
      return;
    }

    this.dataTrend.data = this.filterTrendDataMaster.filter(product =>
      product.asin.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.seller_sku.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.name_product.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.total.toLowerCase().includes(lowerCaseSearchTerm) ||
      product.amounts.some(amount => amount.toString().includes(lowerCaseSearchTerm))
    );

    this.noResultsTrends = this.dataTrend.data.length === 0;
  }

  manualSyncDataProductsFromService() {
    this.loadingProductsList = !this.loadingProductsList;
    this.getProducts('', '', true, true);
  }

  async getProducts(initDate?: string, endDate?: string, syncData: boolean = false, overwriteCache: boolean = true) {
    const queryParameters = {};
    if (initDate) {
      queryParameters['initDate'] = initDate;
    }
    if (endDate) {
      queryParameters['endDate'] = endDate;
    }

    (await this.productsService.getAllProductsByOrders(syncData, overwriteCache, queryParameters)).subscribe((response: ResponseService) => {
        this.loadingProductsList = false;
        this.products = response.data;
        this.noResultsProductsList = response.data.length === 0;
        this.config.totalItems = this.products.length;
        this.productsToSearch = this.products;
        this.cleanTotals();
        this.calculateTotals(this.products);
    });
  }

  filterProductsToList(searchTerm: string | undefined): void {

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    if (lowerCaseSearchTerm.trim() === '') {
      this.productsToSearch = [...this.products];
      this.calculateTotals(this.productsToSearch);
      this.noResultsProductsList = false;
      return;
    }

    this.productsToSearch = this.products.filter(product =>
      product.amazon_fee.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.seller_sku.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.asin.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.condition.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.fba.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.fee_comission.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.fee_giftwrap_chargeback.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.fee_shipping_chargeback.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.fn_sku.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.gros_profit.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.marketplace_facilitator_vat.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.name_product.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.orders.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.promotion.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.sales.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.seller_sku.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.shipping_cost.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.shipping_price.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.shipping_tax.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.size.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.total.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
      product.units.toString().toLowerCase().includes(lowerCaseSearchTerm)
    );
    this.calculateTotals(this.productsToSearch);
    this.noResultsProductsList = this.productsToSearch.length === 0;
  }

  calculateTotals(data) {
    data.forEach(item => {
        for (const prop in item) {
            if (typeof item[prop] === 'number' && prop !== 'id' && prop !== 'marketplace_facilitator_vat') {
              this.dataTotals[prop] += item[prop];
            }
        }
    });

    return this.dataTotals;
  }

  cleanTotals() {
    this.dataTotals  = {
      amazon_fee: 0,
      fba: 0,
      fee_comission: 0,
      fee_giftwrap_chargeback: 0,
      fee_shipping_chargeback: 0,
      gros_profit: 0,
      orders: 0,
      promotion: 0,
      sales: 0,
      shipping_cost: 0,
      shipping_price: 0,
      shipping_tax: 0,
      units: 0
    };
  }
}

<div class="card">
  <div class="card-body">
    <ng-container *ngIf="chartOptions.series[0].data.length > 0 && chartOptions.series[1].data.length; else emptyMixedChart">
      <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [labels]="chartOptions.labels"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [dataLabels]="chartOptions.dataLabels"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
    </ng-container>
    <ng-template #emptyMixedChart>
      <app-empty-search></app-empty-search>
    </ng-template>
  </div>
</div>

import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { NgbCarousel, NgbDate, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { InventoryContainer } from './inventory.container';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-products-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})

/**
 * Dashboard Component
 */
export class InventoryComponent extends InventoryContainer implements OnInit, AfterViewInit  {
  @ViewChild('carouselCharts') carouselCharts: NgbCarousel;
  constructor(
    public inventoryService: InventoryService,
    public indexDbCacheService: IndexedDbCacheService,
    public modalService: NgbModal,
    public localStorageService: LocalStorageService,
    public loadingSpinnerService: LoadingSpinnerService,
    private el: ElementRef
  ) {
    super(inventoryService, indexDbCacheService, loadingSpinnerService);
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'VXI' }, { label: 'Inventory', active: true }];
  }

  ngAfterViewInit() {
    this.fetchCampaings();
  }

  searchCampaings(): void {
    this.filterCampaings(this.searchCampaing);
  }

  searchByDates(): void {
    if (this.initDate) {
      this.onInitDateNavigate(this.initDate as NgbDate);
    }

    if (this.initDate && this.endDate) {
      this.initialDate = this.utilities.getDateInStringTimezone(new Date(this.initDate.year, this.initDate.month - 1, this.initDate.day));
      this.endedDate = this.utilities.getDateInStringTimezone(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day));
      this.fetchCampaings(this.initialDate.split('T')[0], this.endedDate.split('T')[0]);
    } else if (!this.initDate && !this.endDate) {
      this.fetchCampaings('', '');
    }
  }

  onInitDateNavigate(date: NgbDate) {
    let nextDay = new Date(date.year, date.month - 1, date.day + 1);

    // Verificar si el próximo día está fuera de los límites del mes
    if (nextDay.getMonth() !== date.month - 1) {
      // Si es así, establecer el primer día del próximo mes
      nextDay = new Date(date.year, date.month, 1);
    }

    // Convertir la fecha al formato NgbDate
    this.minEndDate = { year: nextDay.getFullYear(), month: nextDay.getMonth() + 1, day: nextDay.getDate() } as NgbDate;
  }
}

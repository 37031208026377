import { ResponseService } from "../models/response.models";
import { InventoryService } from "../services/inventory.service";
import { LoadingSpinnerService } from "../services/loading-spinner.service";
import { LocalStorageService } from "../services/local-storage.service";
import Utils from "./utils";

class ShareFunctionsInventory {
  public localStorageService: LocalStorageService;
  public utilities: Utils;
  private loadingSpinnerService: LoadingSpinnerService;
  private inventoryService: InventoryService;

  constructor(
    loadingSpinnerService: LoadingSpinnerService,
    inventoryService: InventoryService,
    utilities: Utils
  ) {
    this.loadingSpinnerService = loadingSpinnerService;
    this.inventoryService = inventoryService;
    this.utilities = utilities;
  }

  async getCampaingsByDate(
    initDate?: string,
    endDate?: string
  ): Promise<{
    loadingTable: boolean,
    orders: any[],
    noResults: boolean,
    ordersToSearch: any[]
  }> {
    this.loadingSpinnerService.show();
    return new Promise(async (resolve, reject) => {
      (await this.inventoryService.fetchInfoOrdersBetweenDates(initDate, endDate)).subscribe(
        (response: any) => {
          this.loadingSpinnerService.hide();
          if (response.status) {
            const orders = response.data;
            resolve({
              loadingTable: false,
              orders: orders,
              noResults: orders.length === 0,
              ordersToSearch: orders.sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime())
            });
          } else {
            reject('No results');
          }
        },
        (error) => {
          this.loadingSpinnerService.hide();
          this.loadingSpinnerService.showAlertError();
          reject(error);
        }
      );
    });
  }

}

export default ShareFunctionsInventory;

<div class="bg-light min-vh-100 d-flex align-items-center justify-content-center" *ngIf="error!=''">
    <div class="card bg-danger" style="width: 24rem;">
      <div class="card-body text-center">
        <h2 style="color: white;">ERROR</h2>
        <div class="col-12 mt-3">
          <p style="color: white;">{{error}}</p>
          <p style="color: white;">Comunicate con el área de desarrollo.</p>
        </div>
      </div>
    </div>
</div>

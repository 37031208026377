import { ActivatedRoute, Params } from '@angular/router';
import { PL } from 'src/app/core/models/pl.models';
import { Profiles } from 'src/app/core/models/profiles.models';
import { DataPL, DataTrend, ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PPCService } from 'src/app/core/services/ppc.service';
import { TopBarService } from 'src/app/core/services/topbar.service';
import Utils from 'src/app/core/utilities/utils';

export interface ProductFilter {
  name: string;
  value: string;
}

export class TopBarContainer {
  noResults: boolean = true;
  loadingPL: boolean = true;
  loadingTrends: boolean = true;
  noResultsTrends: boolean = true;

  dataPL: DataPL | undefined;
  dataTrend: DataTrend | undefined;
  dataTrendMaster: any[] | null | undefined;
  filterTrendDataMaster: any[] | null | undefined;
  typeFilterSelected: string;
  searchProductTrend: string;
  utilities: Utils = new Utils();
  textFilter: string = 'Filters';
  topMonths: DataPL | undefined;
  public accounts: Profiles[] = [];

  productFilters: ProductFilter[] = [
    {"name": "Sales shipped", "value": "sales"},
    {"name": "Units", "value":"units"},
    {"name": "Promotion", "value":"promotion"},
    {"name": "Amazon fee", "value":"amazon_fee"},
    {"name": "Gross profit", "value":"gros_profit"}
  ];

  backgroundColors = ['#01559f', '#0693bf', '#03ad46', '#a75704']

  constructor(public topBarService: TopBarService,
              public indexDbCacheService: IndexedDbCacheService,
              public ppcService: PPCService,
              public localStorageService: LocalStorageService,
              public loadingSpinnerService: LoadingSpinnerService) {
    this.getGeneralDataPL();
    this.getAccountsForThisProfile();
  }

  manualSyncDataPLFromService() {
    this.loadingPL = !this.loadingPL;
    this.getGeneralDataPL(true, true);
  }

  manualSyncDataTrendsFromService() {
    this.loadingTrends = !this.loadingTrends;
    this.getGeneralDataTrends(true, true);
  }


  reSyncAccount () {
    this.loadingSpinnerService.showAlertConfirmReSync();
  }

  async getAccountsForThisProfile(syncData: boolean = false, overwriteCache: boolean = true) {
    (await this.ppcService.getAllAccounts(syncData, overwriteCache)).subscribe((response: ResponseService) => {
      this.accounts = response.data ? response.data : null;
      this.noResults = response.data ? response.data.length === 0 : false;
    });
  }

  async getGeneralDataTrends(syncData: boolean = false, overwriteCache: boolean = true) {
    (await this.topBarService.generalDataTrendTwo(syncData, overwriteCache)).subscribe((response: ResponseService) => {
      this.loadingTrends = false;
      this.dataTrendMaster = response.data;
      this.getTypeTrend('sales');
    });
  }

  getTypeTrend (typeValue:any) {
    this.initializeDataTrend();
    this.dataTrend['header']=[...this.dataTrend['header'], ...this.dataTrendMaster.map((item:any) => (item.name_month)), ...["Total"]]
    let listAsin = this.extractUniqueAsins();
    let listProductInformation = this.getInformationAsin(listAsin);
    let listProductInformationWithFilter = this.getFilterAsin(listProductInformation, typeValue);
    this.dataTrend.data = listProductInformationWithFilter;
    this.filterTrendDataMaster = listProductInformationWithFilter;
    this.noResultsTrends = !(listProductInformationWithFilter.length > 0);
  }

  getInformationAsin(listAsin: any) {
    const uniqueAsins = new Set(listAsin);
    const listAsinInformation: any[] = [];

    for (const dataValue of this.dataTrendMaster) {
      const financeData = dataValue.finance as any || [];

      financeData
        .filter(financeItem => uniqueAsins.has(financeItem.asin))
        .forEach(financeItem => {
          const foundItem = listAsinInformation.find(item => item.asin === financeItem.asin);
          if (!foundItem) {
            listAsinInformation.push({
              asin: financeItem.asin,
              image: financeItem.image,
              name_product: financeItem.name_product,
              total: financeItem.total,
              seller_sku: financeItem.seller_sku,
              size: financeItem.size,
            });
          }
        });
    }
    return listAsinInformation;
  }

  getFilterAsin(listAsin:any, typeFilter:any){
    listAsin.forEach((asin:any) => {
      let monthProduct:any = [];
      let totalAmount = 0;

      this.dataTrendMaster.forEach((dataValue:any) => {
        let total = 0
        for(let pos=0; pos < dataValue.finance.length; pos++){
          if(dataValue.finance[pos].asin == asin.asin){
            total=total+dataValue.finance[pos][typeFilter];
          }
        }
        monthProduct.push(total);
      });

      monthProduct.forEach((amount:any) => { totalAmount = totalAmount + amount;});
      monthProduct.push(totalAmount);
      asin['amounts'] = monthProduct;
    });
    return listAsin;
  }

  extractUniqueAsins() {
    const asinSet = new Set(
      Array.prototype.concat.apply(
        [],
        (this.dataTrendMaster as any[])
          .map(entry => (entry.finance as any[] || []).map(item => item?.asin))
          .filter(Boolean)
      )
    );

    return Array.from(asinSet);
  }

  async getGeneralDataPL(syncData: boolean = false, overwriteCache: boolean = true) {
    (await this.topBarService.generalDataPLVTwo(syncData, overwriteCache)).subscribe((response: ResponseService) => {
      this.loadingPL = false;
      this.initializeDataPL();
      const objStructurePL= this.generateDataStructurePL(response.data);
      this.noResults = !(response.data && response.data.length > 0);

      this.dataPL['header'] = objStructurePL.header;
      this.dataPL['rowSales']= [...this.dataPL['rowSales'],...objStructurePL.rowSales];
      this.dataPL['rowSales']= [...this.dataPL['rowSales'],...[Number(this.utilities.getSum(this.dataPL['rowSales'])).toFixed(2)]];
      this.dataPL['rowSalesShipped']= [...this.dataPL['rowSalesShipped'],...objStructurePL.rowSalesShipped];
      this.dataPL['rowSalesShipped']= [...this.dataPL['rowSalesShipped'],...[Number(this.utilities.getSum(this.dataPL['rowSalesShipped'])).toFixed(2)]];
      this.dataPL['rowUnits']= [...this.dataPL['rowUnits'],...objStructurePL.rowUnits];
      this.dataPL['rowUnits']= [...this.dataPL['rowUnits'],...[Number(this.utilities.getSum(this.dataPL['rowUnits'])).toFixed(2)]];
      this.dataPL['rowPromotion']= [...this.dataPL['rowPromotion'],...objStructurePL.rowPromotion];
      this.dataPL['rowPromotion']= [...this.dataPL['rowPromotion'],...[Number(this.utilities.getSum(this.dataPL['rowPromotion'])).toFixed(2)]];
      this.dataPL['rowShippingCost']= [...this.dataPL['rowShippingCost'],...objStructurePL.rowShippingCost];
      this.dataPL['rowShippingCost']= [...this.dataPL['rowShippingCost'],...[Number(this.utilities.getSum(this.dataPL['rowShippingCost'])).toFixed(2)]];
      this.dataPL['rowAmazonFee']= [...this.dataPL['rowAmazonFee'],...objStructurePL.rowAmazonFee];
      this.dataPL['rowAmazonFee']= [...this.dataPL['rowAmazonFee'],...[Number(this.utilities.getSum(this.dataPL['rowAmazonFee'])).toFixed(2)]];
      this.dataPL['rowVat']= [...this.dataPL['rowVat'],...objStructurePL.rowVat];
      this.dataPL['rowVat']= [...this.dataPL['rowVat'],...[Number(this.utilities.getSum(this.dataPL['rowVat'])).toFixed(2)]];
      this.dataPL['rowGrossProfit']= [...this.dataPL['rowGrossProfit'],...objStructurePL.rowGrossProfit];
      this.dataPL['rowGrossProfit']= [...this.dataPL['rowGrossProfit'],...[Number(this.utilities.getSum(this.dataPL['rowGrossProfit'])).toFixed(2)]];

      const newDataPl = this.dataPL;
      newDataPl['header'] = newDataPl['header'].slice(1, 5);
      newDataPl['rowSales'] = newDataPl['rowSales'].slice(1, 5);
      newDataPl['rowSalesShipped'] = newDataPl['rowSalesShipped'].slice(1, 5);
      newDataPl['rowUnits'] = newDataPl['rowUnits'].slice(1, 5);
      newDataPl['rowPromotion'] = newDataPl['rowPromotion'].slice(1, 5);
      newDataPl['rowShippingCost'] = newDataPl['rowShippingCost'].slice(1, 5);
      newDataPl['rowAmazonFee'] = newDataPl['rowAmazonFee'].slice(1, 5);
      newDataPl['rowVat'] = newDataPl['rowVat'].slice(1, 5);
      newDataPl['rowGrossProfit'] = newDataPl['rowGrossProfit'].slice(1, 5);
      this.topMonths = newDataPl;
    });
  }

  private initializeDataPL(): void {
    this.dataPL = new DataPL();

    this.dataPL.header = ["Parameters/Date"];
    this.dataPL.rowSales = ["Sales"];
    this.dataPL.rowSalesShipped = ["Sales shipped"];
    this.dataPL.rowUnits = ["Units sold"];
    this.dataPL.rowPromotion = ["Promo"];
    this.dataPL.rowShippingCost = ["Shipping costs"];
    this.dataPL.rowAmazonFee = ["Amazon fees"];
    this.dataPL.rowVat = ["VAT"];
    this.dataPL.rowGrossProfit = ["Gross profit"];
  }

  private initializeDataTrend(): void {
    this.dataTrend = new DataTrend();

    this.dataTrend.header = ["Product"];
    this.dataTrend.data = [];
  }


  generateDataStructurePL(data: PL[]): DataPL{
    let months =  null;
    let sales = ['0'], sales_shipped = ['0'], units = ['0'], promotion = ['0'], shipping_cost = ['0'], amazon_fee = ['0'], gross_profit = ['0'], vat = ['0'];

    if (data) {
      months = data.map((item:any) => (item.name_month));
      sales = data.map((item:any) => (Number(item.finance.saleGlobal).toFixed(2)));
      sales_shipped = data.map((item:any) => (Number(item.finance.sales).toFixed(2)));
      units = data.map((item:any) => (item.finance.units));
      promotion = data.map((item:any) => (Number(item.finance.promotion).toFixed(2)));
      shipping_cost = data.map((item:any) => (Number(item.finance.shipping_cost).toFixed(2)));
      amazon_fee = data.map((item:any) => (Number(item.finance.amazon_fee).toFixed(2)));
      vat = data.map((item:any) => (Number(item.finance.marketplace_facilitator_vat).toFixed(2)));
      gross_profit = data.map((item:any) => (Number(item.finance.gros_profit).toFixed(2)));
    }


    return {
      header: months ? [...this.dataPL['header'], ...months, ...["Total"]] : [...this.dataPL['header'], ...["Total"]],
      rowSales: sales,
      rowSalesShipped: sales_shipped,
      rowUnits: units,
      rowPromotion: promotion,
      rowShippingCost: shipping_cost,
      rowAmazonFee: amazon_fee,
      rowVat: vat,
      rowGrossProfit: gross_profit,
    }
  }

  filterProducts(searchTerm: string | undefined): void {

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    if (lowerCaseSearchTerm.trim() === '') {
      this.dataTrend.data = [...this.filterTrendDataMaster];
      this.noResultsTrends = false;
      return;
    }

    this.dataTrend.data = this.filterTrendDataMaster.filter(product =>
      (product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(lowerCaseSearchTerm)
    );

    this.noResultsTrends = this.dataTrend.data.length === 0;
  }
}

import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { MapChartInventoryContainer } from './map-chart-inventory.container';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-map-chart-inventory',
  templateUrl: './map-chart-inventory.component.html',
  styleUrls: ['./map-chart-inventory.component.scss']
})

/**
 * Dashboard Component
 */
export class MapChartInventoryComponent extends MapChartInventoryContainer implements OnInit, OnChanges {
  @Input() initDate: any = null;
  @Input() endDate: any = null;

  constructor(
    public inventoryService: InventoryService,
    public indexDbCacheService: IndexedDbCacheService,
    public loadingSpinnerService: LoadingSpinnerService,
    public modalService: NgbModal
  ) {
    super(inventoryService, indexDbCacheService, loadingSpinnerService);
  }

  ngOnInit(): void {
    this.fetchCampaings();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['initDate'] && !changes['initDate'].isFirstChange()) || (changes['endDate'] && !changes['endDate'].isFirstChange())) {
      this.fetchCampaings(this.initDate, this.endDate);
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  private cancelPendingRequests$ = new Subject<void>();

  constructor() { }

  // Método para obtener el observable para cancelar solicitudes pendientes
  get cancelPendingRequestsObservable() {
    return this.cancelPendingRequests$.asObservable();
  }

  // Método para cancelar solicitudes pendientes
  cancelPendingRequests() {
    this.cancelPendingRequests$.next();
  }
}

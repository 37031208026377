<ng-container>
  <ng-container  *ngIf="loadingPL; else content">
    <app-skeleton-element-list [typeOfContentSkeleton]="'table'"></app-skeleton-element-list>
  </ng-container>
  <ng-template #content>
    <div class="row">
      <div class="col-sm-6">
        <h3 class="d-flex mb-3 justify-content-start align-items-center">
          P&L Data
        </h3>
      </div>
      <div class="col-sm-6">
        <div class="d-flex justify-content-end align-items-center ml-5">
          <button type="button" (click)="manualSyncDataPLFromService()"
            class="btn btn-primary btn-sm btn-rounded btn-sync"
            ngbTooltip="Manual Sync" placement="right">
            <i class=" ri-refresh-line"></i>
          </button>
          <div class="btn-group btn-group-filter ml-10" autoClose="outside" ngbDropdown>
            <button type="button" class="btn btn-dropdown-filter btn-secondary dropdown-toggle text-left" ngbDropdownToggle>
              <span>{{textFilterChart}}</span>
              <i class="mdi mdi-chevron-down align-dropdown-element"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-right" ngbDropdownMenu>
              <p class="dropdown-item"
                *ngFor="let typeFilter of productFiltersChart"
                (click)="activeFilterChart(typeFilter)">
                {{typeFilter.name}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="chartjs-chart">
              <canvas baseChart [datasets]="lineBarChart.datasets" chartType="bar" [options]="lineBarChart.options"
                height="400" [labels]="lineBarChart.labels">
              </canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-md-3 col-lg-3 col-xl-2 pdr-0">
        <table class="table table-striped table-responsive background-table mb-0">
          <thead>
            <tr class="table-data-pl">
              <th class="text-center">Parameters</th>
            </tr>
          </thead>
          <tbody>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowSales'}">Sales</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowSalesShipped'}">Sales Shipped</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowUnits'}">Units Sold</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowPromotion'}">Promo</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowShippingCost'}">Shipping Costs</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowAmazonFee'}">Amazon Fees</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowVat'}">VAT</td></tr>
            <tr><td class="content-table-header" [ngClass]="{'selected-filter': keyToChartFilter === 'rowGrossProfit'}">Gross Profit</td></tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-9 col-md-9 col-lg-9 col-xl-10 pdl-0">
        <div class="container-pl-data horizontal-scroll">
          <table *ngIf="!noResults ; else noResultsContent" class="table table-striped table-responsive background-table mb-0">
            <thead>
              <tr class="table-data-pl">
                <ng-container *ngFor="let header of this.dataPL?.header; index as i">
                  <th class="text-center" *ngIf="i != 0">{{header}}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let prop of utilities.getKeysFromObject(this.dataPL);">
                <tr *ngIf="prop !== 'header'">
                  <ng-container *ngFor="let item of dataPL[prop] index as i">
                    <td *ngIf="i != 0" class="content-table-element" [ngClass]="{'selected-filter': keyToChartFilter === prop}">
                      <span class="left-aligned">
                        {{ prop !== 'header' ? i === 0 || prop === 'rowUnits' ? item : '$' + ( item | number:'1.2-2') : item}}
                      </span>
                      <span
                        *ngIf="!checkValueType(item) && dataPL[prop][i+1] && convertToFloat(dataPL[prop][i+1]) !== 0 && this.dataPL?.header[i+1] !== 'Total'"
                        [ngClass]="convertToFloat(dataPL[prop][i+1])  > item ? 'bg-danger-subtle text-danger' : convertToFloat(dataPL[prop][i+1])  === item ? 'bg-dark-subtle text-reset' : 'bg-success-subtle text-success'"
                        class="badge rounded-pill font-s-10 right-aligned">
                          {{ dataPL[prop][i+1] && convertToFloat(dataPL[prop][i+1] ) !== 0 ? ((( convertToFloat(item) - convertToFloat(dataPL[prop][i+1]) ) / ( convertToFloat(dataPL[prop][i+1]) )) * 100).toFixed(2) : '-'  }}%
                          <i [ngClass]="convertToFloat(dataPL[prop][i+1])  > item ? ' ri-arrow-down-line' : convertToFloat(dataPL[prop][i+1])  === item ? '' : ' ri-arrow-up-line'"></i>
                      </span>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ng-template #noResultsContent>
            <app-empty-search></app-empty-search>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

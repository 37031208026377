import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SellsByHourComponent } from './sells-by-hour.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { EmptySearchComponentModule } from '../../../../support/empty-search/empty-search.module';
import { SkeletonElementListComponentModule } from '../../../../support/skeleton-element-list/skeleton-element-list.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule, NgbNavModule, NgbCarouselModule, NgbDropdownModule, NgbTooltipModule, NgbPaginationModule, NgbCollapseModule, NgbModalModule, NgbAccordionModule, NgbPopoverModule, NgbDatepickerModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        EmptySearchComponentModule,
        SkeletonElementListComponentModule,
        NgbProgressbarModule,
        NgbAccordionModule,
        NgxPaginationModule,
        NgApexchartsModule
      ],
    declarations: [
      SellsByHourComponent
    ],
    exports:[ SellsByHourComponent ]
})
export class SelssByHourModule { }

import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.LIST.TOTALSALES',
        icon: 'ri-money-dollar-circle-line',
        link: '/dashboard/total-sales'
    },
    {
        id: 3,
        label: 'MENUITEMS.PRODUCT.TEXT',
        icon: 'ri-grid-fill',
        link: '/products/sku-list'
    },
    {
        id: 4,
        label: 'MENUITEMS.PPC.TEXT',
        icon: 'ri-bar-chart-grouped-fill',
        link: '/ppc'
    },
    {
      id: 6,
      label: 'MENUITEMS.FINNANCIAL.TEXT',
      icon: 'ri-coin-line',
      link: '/financial'
    },
    {
      id: 7,
      label: 'MENUITEMS.INVENTORY.TEXT',
      icon: 'ri-list-check-2',
      link: '/inventory'
    },
    {
      id: 8,
      label: 'MENUITEMS.INSIGHTS.TEXT',
      icon: ' ri-line-chart-line',
      link: '/insights'
    },

];



<div class="row">
  <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="false" [interval]="false" #carouselCards>
    <ng-template ngbSlide *ngFor="let chunkElem of counterByDay">
      <div class="row">
        <ng-container *ngFor="let item of chunkElem; let i = index">
          <div class="card col card-into-carousel" (click)="showSalesByDayOfTheWeek(item.key)">
            <div class="card-body card-body-into-carousel">
              <div class="d-flex align-items-center">
                <div class="flex-1 overflow-hidden">
                  <h5 class="mb-0 dayweek-name">
                    <b>{{weeks[item.key]}}</b>
                  </h5>
                </div>
              </div>
              <div class="container-element-data">
                  <div class="row">
                    <div class="col-md-12">Ventas: {{item.value}}</div>
                  </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ngb-carousel>
</div>

<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h4 class="card-title mb-0">Ventas x día de la semana</h4>
    </div>

    <apx-chart class="apex-charts" dir="ltr" [series]="dashedLineChart.series" [chart]="dashedLineChart.chart"
          [dataLabels]="dashedLineChart.dataLabels" [stroke]="dashedLineChart.stroke"
          [colors]="dashedLineChart.colors" [xaxis]="dashedLineChart.xaxis" [grid]="dashedLineChart.grid"
          [tooltip]="dashedLineChart.tooltip" [plotOptions]="dashedLineChart.plotOptions"
          [fill]="dashedLineChart.fill" [markers]="dashedLineChart.markers" [legend]="dashedLineChart.legend"
          [yaxis]="dashedLineChart.yaxis" [tooltip]="dashedLineChart.tooltip"></apx-chart>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertSyncService } from 'src/app/core/services/alert-sync.service';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-confirm-authorization',
  templateUrl: './confirm-authorization.component.html',
  styleUrls: ['./confirm-authorization.component.scss']
})
export class ConfirmAuthorizationComponent implements OnInit {
  addAuthorization=false;
  error='';
  title = 'Sincronizando datos con Amazon';

  constructor(
    private router: Router,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private alertSyncService: AlertSyncService,
    private route_params: ActivatedRoute
  ) {
    this.obtainCode()
  }

  ngOnInit(): void {
  }

  obtainCode(){
    this.route_params.queryParams.subscribe(params =>{

      const uri_spi = params["amazon_callback_uri"];
      const amazon_state = params["amazon_state"];
      const spapi_oauth_code = params["spapi_oauth_code"];
      const storageIdUser = this.localStorageService.getData('idUser');

        if(uri_spi && amazon_state){

          let data = {
            idUser: storageIdUser,
            uri_spi: uri_spi,
            amazon_state: amazon_state
          };
          console.log('here', data)
          this.confirmAutorizationSPAPI(data);
        }

        if(spapi_oauth_code){
          let data = {
            spapi_oauth_code: spapi_oauth_code,
            idUser: storageIdUser
          };

          if(this.localStorageService.getData('userType') === "seller"){
            this.autorizationAmazonSPAPISeller(data);
            console.log('here 1', data)
          }

          if(this.localStorageService.getData('userType') === "vendor"){
            this.autorizationAmazonSPAPIVendor(data);
          }
        }

        if(!(uri_spi && amazon_state) && !spapi_oauth_code){
          this.addAuthorization = true;
        }

    })
  }

  confirmAutorizationSPAPI(data:any){
    this.alertSyncService.cargandoDatos(this.title)
    this.alertSyncService.confirmAutorizationSPAPI(data).subscribe(
      (response:any) => {
        console.log(response)
        if(response.status){
          console.log('here')
          window.location.href = response.data;
        }else{
          this.alertSyncService.cerrarCargandoDatos();
        }
      }
    )
  }

  autorizationAmazonSPAPIVendor(data:any){
    this.alertSyncService.cargandoDatos(this.title)
    this.alertSyncService.autorizationAmazonSPAPIVendor(data).subscribe(
      (response:any) => {
        if(response.status){
          this.alertSyncService.cerrarCargandoDatos();
          this.router.navigate(['account/ads-authorization']);
        }else{
          this.alertSyncService.cerrarCargandoDatos();
          this.error = response.msg;
        }
      }
    )
  }

  autorizationAmazonSPAPISeller(data:any){
    this.alertSyncService.cargandoDatos(this.title)
    this.alertSyncService.autorizationAmazonSPAPI(data).subscribe(
      (response:any) => {
        console.log(response)
        if(response.status){
          console.log('sera redireccionado')
          this.alertSyncService.cerrarCargandoDatos();
          this.router.navigate(['account/ads-authorization']);
        }else{
          this.alertSyncService.cerrarCargandoDatos();
          this.error = response.msg;
        }
      }
    )
  }

}

<div role="group" class="btn-group btn-group-sm mb-3">
  <button type="button" (click)="showOtherContentTab('pl')" [ngClass]="selectedPrimaryTab === 'pl' ? 'active-btn' : ''" class="btn btn-primary no-radius-right-border">P&L</button>
  <button type="button" (click)="showOtherContentTab('trends')" [ngClass]="selectedPrimaryTab === 'trends' ? 'active-btn' : ''" class="btn btn-primary no-radius-right-border">Trends</button>
  <button type="button" (click)="showOtherContentTab('products')" [ngClass]="selectedPrimaryTab === 'products' ? 'active-btn' : ''" class="btn btn-primary no-radius-right-border">Sales for Product</button>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="container-content-tab">
      <ng-container *ngIf="selectedPrimaryTab === 'pl'">
        <app-financial-pl></app-financial-pl>
      </ng-container>

      <ng-container *ngIf="selectedPrimaryTab === 'trends'">
        <app-financial-trends></app-financial-trends>
      </ng-container>

      <ng-container *ngIf="selectedPrimaryTab === 'products'">
        <ng-container  *ngIf="loadingProductsList; else contentProducts">
          <app-skeleton-element-list [typeOfContentSkeleton]="'table'"></app-skeleton-element-list>
        </ng-container>
        <ng-template #contentProducts>
          <div class="row mb-3">
              <div class="col-sm-12">
                <div class="d-flex justify-content-start align-items-center">
                  <h3 class="mb-0">Sales for Product</h3>
                  <div class="position-relative ml-5"  ngbTooltip="Al dar clic en el nombre de la columna se ordenaran los datos de mayor a menor y tambien de forma inversa" placement="bottom">
                    <i class="ri-information-line font-size-icon-info"></i>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="d-flex justify-content-end align-items-center">
                  <div class="row mr-2 ">
                    <div class="col-sm-6 date-search">
                      <span class="ri-calendar-line"></span>
                      <input [ngModelOptions]="{standalone: true}" name="initDate" id="initDate"
                             [(ngModel)]="initDate" (ngModelChange)="setMinDate()" ngbDatepicker class="form-control"
                             autoClose="false" placeholder="YYYY-MM-DD" (click)="date.toggle()" #date="ngbDatepicker">
                    </div>
                    <div class="col-sm-6 date-search">
                      <span class="ri-calendar-line"></span>
                      <input [ngModelOptions]="{standalone: true}" name="endDate" id="endDate"
                             [minDate]="minEndDate" [(ngModel)]="endDate"
                             ngbDatepicker class="form-control"
                             autoClose="false" placeholder="YYYY-MM-DD" (click)="dateEnd.toggle()" #dateEnd="ngbDatepicker">
                    </div>
                  </div>
                  <form class="app-search d-lg-block mr-10" ngbTooltip="Se puede buscar por todos los parametros de la tabla" placement="bottom">
                    <div class="position-relative">
                      <input type="text" class="form-control"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="searchProductList" (ngModelChange)="searchProductsList()" placeholder="{{'HEADER.SEARCH' | translate}}">
                      <span class="ri-search-line"></span>
                    </div>
                  </form>
                  <button type="button" (click)="manualSyncDataProductsFromService()"
                    class="btn btn-primary btn-rounded btn-sync"
                    ngbTooltip="Manual Sync" placement="right">
                    <i class=" ri-refresh-line"></i>
                  </button>
                </div>
              </div>

          </div>

          <ng-container *ngIf="!noResultsProductsList ; else noResultsContentProductsList">
            <div class="container-trends-data horizontal-scroll">
              <table class="table table-striped table-responsive background-table mb-0">
                <thead>
                  <tr class="table-data-sales">
                    <th (click)="sortColumn('asin')"><b>ASIN</b></th>
                    <th (click)="sortColumn('sales')"><b>Sales Shipped</b> </th>
                    <th (click)="sortColumn('units')"><b>Units</b> </th>
                    <th (click)="sortColumn('orders')"><b>Orders</b></th>
                    <th (click)="sortColumn('promotion')"><b>Promotion</b> </th>
                    <th (click)="sortColumn('shipping_cost')"><b>Shipping cost</b> </th>
                    <th (click)="sortColumn('shipping_price')"><b>Shipping price</b> </th>
                    <th (click)="sortColumn('shipping_tax')"><b>Shipping tax</b></th>
                    <th (click)="sortColumn('amazon_fee')"><b>Amazon fees</b></th>
                    <th (click)="sortColumn('fba')">FBA</th>
                    <th (click)="sortColumn('fee_comission')">Fee comission</th>
                    <th (click)="sortColumn('fee_giftwrap_chargeback')">Fee giftwrap chargeback</th>
                    <th (click)="sortColumn('fee_shipping_chargeback')">Fee shipping chargeback</th>
                    <th (click)="sortColumn('gros_profit')"><b>Gross profit</b></th>
                    <th><b>Condition</b></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of productsToSearch | paginate: config">
                    <td>
                      <div class="d-flex justify-content-between align-items-center">
                        {{item?.seller_sku}} | {{item?.asin}}
                        <div class="position-relative ml-5 pointer" [ngbTooltip]="item.name_product" placement="bottom">
                          <i class="ri-information-line font-size-icon-info"></i>
                        </div>
                      </div>
                    </td>
                    <td class="text-right"> ${{item?.sales ? (item?.sales.toFixed(2) | number:'1.2-2') : 0.00}} </td>
                    <td class="text-center"> {{item?.units ? item?.units : 0}}</td>
                    <td class="text-center">{{item?.orders}}</td>
                    <td class="text-right"> ${{item?.promotion ? (item?.promotion.toFixed(2) | number:'1.2-2') : 0.00}} </td>
                    <td class="text-right"> ${{item?.shipping_cost ? (item?.shipping_cost.toFixed(2) | number:'1.2-2') : 0.00}} </td>
                    <td class="text-right">${{item?.shipping_price ? (item?.shipping_price.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">${{item?.shipping_tax ? (item?.shipping_tax.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">
                      ${{item?.amazon_fee ? (item?.amazon_fee.toFixed(2) | number:'1.2-2') : 0.00}}
                    </td>
                    <td class="text-right">${{item?.fba ? (item?.fba.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">${{item?.fee_comission ? (item?.fee_comission.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">${{item?.fee_giftwrap_chargeback ? (item?.fee_giftwrap_chargeback.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">${{item?.fee_shipping_chargeback ? (item?.fee_shipping_chargeback.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">${{item?.gros_profit ? (item?.gros_profit.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="text-right">{{item?.condition}}</td>
                  </tr>
                  <tr>
                    <td class="selected-filter">Totales</td>
                    <td class="selected-filter text-right"> ${{dataTotals?.sales ? (dataTotals?.sales.toFixed(2) | number:'1.2-2') : 0.00}} </td>
                    <td class="selected-filter text-center"> {{dataTotals?.units ? dataTotals?.units : 0}}</td>
                    <td class="selected-filter text-center">{{dataTotals?.orders}}</td>
                    <td class="selected-filter text-right">${{dataTotals?.promotion ? (dataTotals?.promotion.toFixed(2) | number:'1.2-2') : 0.00}} </td>
                    <td class="selected-filter text-right">${{dataTotals?.shipping_cost ? (dataTotals?.shipping_cost.toFixed(2) | number:'1.2-2') : 0.00}} </td>
                    <td class="selected-filter text-right">${{dataTotals?.shipping_price ? (dataTotals?.shipping_price.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right">${{dataTotals?.shipping_tax ? (dataTotals?.shipping_tax.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right">
                      ${{dataTotals?.amazon_fee ? (dataTotals?.amazon_fee.toFixed(2) | number:'1.2-2') : 0.00}}
                    </td>
                    <td class="selected-filter text-right">${{dataTotals?.fba ? (dataTotals?.fba.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right">${{dataTotals?.fee_comission ? (dataTotals?.fee_comission.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right">${{dataTotals?.fee_giftwrap_chargeback ? (dataTotals?.fee_giftwrap_chargeback.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right">${{dataTotals?.fee_shipping_chargeback ? (dataTotals?.fee_shipping_chargeback.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right">${{dataTotals?.gros_profit ? (dataTotals?.gros_profit.toFixed(2) | number:'1.2-2') : 0.00}}</td>
                    <td class="selected-filter text-right"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <pagination-controls (pageChange)="config.currentPage = $event"></pagination-controls>
          </ng-container>



          <ng-template #noResultsContentProductsList>
            <app-empty-search></app-empty-search>
          </ng-template>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

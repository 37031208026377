import {Component, Input, ViewChild} from '@angular/core';

import {NgbModal, NgbActiveModal, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-modal-dashboard-selection',
  templateUrl: './modal-dashboard-selection.component.html',
  styleUrls: ['./modal-dashboard-selection.component.scss']
})
export class modalDashboardSelectionComponent {
  utilities: Utils;
  addedCharts: any = [];

  constructor(public activeModal: NgbActiveModal,
              public indexDbCacheService: IndexedDbCacheService,
              public localStorageService: LocalStorageService,
              public loadingSpinnerService: LoadingSpinnerService) {
    this.utilities = new Utils();
  }

  addToDashboard(chart: string) {
    const index = this.addedCharts.indexOf(chart);
    if (index !== -1) {
        this.addedCharts.splice(index, 1);
        return;
    }
    this.addedCharts.push(chart);
  }

  isChartSelected(chart: string): boolean {
    return this.addedCharts.includes(chart);
  }

  saveSelection() {
    if (this.addedCharts.length > 0) {
      this.localStorageService.setAddedCharts(this.addedCharts);
      this.activeModal.close();
      window.location.reload();
    } else {
      this.loadingSpinnerService.showSelectOneChart();
    }
  }

}

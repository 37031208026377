import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, ChartTypeApex } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { dashedLineChart, totalsByStatus, basicColumChart, statesMx, equivalentStates } from '../../data';
import { PaginationInstance } from 'ngx-pagination';
import { InventoryService } from 'src/app/core/services/inventory.service';
import * as echarts from 'echarts';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import ShareFunctionsInventory from 'src/app/core/utilities/share-functions-inventory';
import { Subscription } from 'rxjs';

export class MapChartInventoryContainer {
  minEndDate: NgbDate;
  public utilities: Utils;
  public shareFunctionsInventory: ShareFunctionsInventory;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  orderTable: boolean = true;
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  basicColumChart: ChartTypeApex;
  filters: any[] = []
  orders: any[] = [];
  ordersToSearch: any[] = [];
  statesMx: any[];
  topStates: any[];
  view: string = 'map';
  dataForMunicipality: any[] = [];
  isLoadedAMunicipality: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  showDatesSection: boolean = false;
  equivalentStates: any;

  constructor(public inventoryService: InventoryService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.equivalentStates = equivalentStates;
    this.dashedLineChart = dashedLineChart;
    this.statesMx = statesMx;
    this.utilities = new Utils();
    this.shareFunctionsInventory = new ShareFunctionsInventory(loadingSpinnerService, inventoryService, this.utilities);
  }


  activeFilter(filter: any) {
    this.textFilter = filter.title;
    this.keyToSearch = filter.key;
  }

  async fetchCampaings(initDate?: string, endDate?: string) {
    try {
      const response = await this.shareFunctionsInventory.getCampaingsByDate(initDate, endDate);
      this.loadingTable = response.loadingTable;
      this.orders = response.orders;
      this.noResults = response.noResults;
      this.ordersToSearch = response.ordersToSearch;
      this.loadingSpinnerService.show();
      setTimeout(() => {
        this.getDataByStates();
        this.createMapChart();
        this.loadingSpinnerService.hide();
      }, 1000);
    } catch (error) {
      console.log(error)
      this.loadingSpinnerService.showAlertError();
      this.loadingSpinnerService.hide();
    }
  }

  getDataByStates() {
    const results = this.utilities.countShipStates(this.orders);
    this.statesMx.forEach(state => {
      const key = this.utilities.cleanAndNormalizeString(state.key);
      const resultValue = results[key] || 0;
      state.value = resultValue;
    });

    this.topStates = this.utilities.getTopStates(this.statesMx);
  }

  createMapByPostalCodes(state) {
    this.groupByShipState(this.orders, state);
  }

  createMapChart() {
    const elem = this;
    const chartDom = document.getElementById('main')!;
    const myChart = echarts.init(chartDom);
    const maxValue = this.statesMx.reduce((max, state) => (state.value > max ? state.value : max), -Infinity);
    this.view = 'map';
    this.isLoadedAMunicipality = false;

    this.inventoryService.fetchStates().subscribe((response: any) => {
      echarts.registerMap('MX', response);
      this.statesMx.sort(function (a, b) {return a.value - b.value;});
      const mapOption: echarts.EChartsOption = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2
        },
        dataZoom: [
            {
                type: 'inside', // Para zoom interno
                disabled: true // Desactivar el zoom interno
            },
            {
                type: 'slider', // Para zoom a través del control deslizante
                disabled: true // Desactivar el control deslizante de zoom
            }
        ],
        visualMap: {
          left: 'right',
          min: 0,
          max: maxValue,
          inRange: {
            color: ['#e0f3f8', '#ffffbf', '#abd9e9', '#74add1', '#4575b4', '#313695']
          },
          text: ['High', 'Low'],
          calculable: true
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: 'Purchase Orders by State',
            id: 'population',
            type: 'map',
            roam: 'move',
            map: 'MX',
            animationDurationUpdate: 1000,
            universalTransition: true,
            data: this.statesMx
          }
        ]
      };
      myChart.setOption(mapOption, true);

      myChart.on('click', function(params) {
        elem.filterPurchaseOrdersByState(elem.utilities.cleanAndNormalizeString(params.name));
      });
    });
  }

  changeBarView() {
    const chartDom = document.getElementById('main')!;
    const myChart = echarts.init(chartDom);
    this.view = 'bar';

    const barOption: echarts.EChartsOption = {
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        axisLabel: {
          rotate: 30
        },
        data: this.statesMx.map(function (item) {
          return item.name;
        })
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2
      },
      animationDurationUpdate: 1000,
      series: {
        type: 'bar',
        name: 'Purchase Orders by State',
        id: 'population',
        data: this.statesMx.map(function (item) {
          return item.value;
        }),
        universalTransition: true
      }
    };

    myChart.setOption(barOption, true);
  }

  groupByShipState(orders: any[], state: any): void {
    this.isLoadedAMunicipality = true;
    const cleanedTargetState = this.utilities.cleanAndNormalizeString(state.name);
    // Check for equivalent states
    const equivalentState = this.equivalentStates[cleanedTargetState] || cleanedTargetState;
    // Group orders by ship_state
    const groupedOrders: Record<string, any[]> = {};

    orders.forEach(order => {
      const cleanedShipState = this.utilities.cleanAndNormalizeString(order.ship_state);
      const equivalentShipState = this.equivalentStates[cleanedShipState] || cleanedShipState;
      if (equivalentShipState === equivalentState) {
        if (!groupedOrders[equivalentShipState]) {
          groupedOrders[equivalentShipState] = [];
        }
        groupedOrders[equivalentShipState].push(order);
      }
    });
    // Convert groupedOrders object to array
    const result: any[][] = Object.values(groupedOrders);
    this.inventoryService.fetchZipCodes().subscribe((response: any) => {
      const stateToCompare = cleanedTargetState === 'michoacan' ? 'Michoacán de Ocampo' : cleanedTargetState;
      const statesExisted = response.find(resp => { return this.utilities.cleanAndNormalizeString(resp.state) === stateToCompare});
      const newMunicipalities = this.utilities.addValueToMunicipalities(statesExisted?.municipalities, result[0]);
      this.generateMapForMunicipality(newMunicipalities, state);
    });
  }

  generateMapForMunicipality(municipality: any, state: any) {
    const elem = this;
    const chartDom = document.getElementById('main')!;
    const myChart = echarts.init(chartDom);
    const maxValue = this.statesMx.reduce((max, state) => (state.value > max ? state.value : max), -Infinity);

    this.inventoryService.fetchPostalCodes(state.jsonPath).subscribe((response: any) => {
      echarts.registerMap(state.key.replace(' ', '_'), response);
      this.statesMx.sort(function (a, b) {return a.value - b.value;});
      const mapOption: echarts.EChartsOption = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2
        },
        visualMap: {
          left: 'right',
          min: 0,
          max: maxValue,
          inRange: {
            color: ['#e0f3f8', '#ffffbf', '#abd9e9', '#74add1', '#4575b4', '#313695']
          },
          text: ['High', 'Low'],
          calculable: true
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: [
            {
                type: 'inside', // Para zoom interno
                disabled: true // Desactivar el zoom interno
            },
            {
                type: 'slider', // Para zoom a través del control deslizante
                disabled: true // Desactivar el control deslizante de zoom
            }
        ],
        series: [
          {
            name: 'Purchase Orders by State',
            id: 'population',
            type: 'map',
            roam: 'move',
            map: state.key.replace(' ', '_'),
            animationDurationUpdate: 1000,
            universalTransition: true,
            data: municipality
          }
        ]
      };
      myChart.setOption(mapOption, true);
      myChart.on('click', function(params) {
        elem.filterPurchaseOrdersByState(elem.utilities.cleanAndNormalizeString(params.name));
      });

      this.filterPurchaseOrdersByState(state.key);
    });
  }

  filterPurchaseOrdersByState(searchTerm: string | undefined): void {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    this.inventoryService.setSearchTerm(lowerCaseSearchTerm);
  }
}

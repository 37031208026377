<div class="container-fluid">
  <app-pagetitle title="PPC" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="d-flex justify-content-end align-items-center pd-0 mb-10">
    <button class="btn btn-sm btn-primary" (click)="openExlargeModal()">Change Account</button>
  </div>
  <div class="row">
    <div class="col-12" id="salesChart">
      <app-sales-chart></app-sales-chart>
    </div>
    <div class="col-12" id="indicatorsChart">
      <app-interactions-chart></app-interactions-chart>
    </div>
    <div class="col-12" id="impressionsChart">
      <app-impressions-chart></app-impressions-chart>
    </div>
    <div class="col-12" id="profitChart">
      <app-profit-chart></app-profit-chart>
    </div>
  </div>
  <div class="mt-30 mb-30">
    <ng-container *ngIf="campaings.length > 0; else noElementsExisting">
      <ng-container *ngIf="!loadingTable; else loadingContentTable">
        <div class="card card-clean">
          <div class="d-flex justify-content-end align-items-center pd-15">
            <div class="row mr-2 ">
              <div class="col-sm-12 date-search">
                <span class="ri-calendar-line"></span>
                <input [ngModelOptions]="{standalone: true}" [(ngModel)]="initDate" (ngModelChange)="searchByDates()" ngbDatepicker class="form-control" autoClose="false" placeholder="YYYY-MM-DD" (click)="date.toggle()" #date="ngbDatepicker">
              </div>
            </div>
            <form class="app-search d-lg-block">
              <div class="position-relative">
                <input type="text" class="form-control"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="searchCampaing" (ngModelChange)="searchCampaings()" placeholder="{{'HEADER.SEARCH' | translate}}">
                <span class="ri-search-line"></span>
              </div>
            </form>
          </div>
          <div class="pd-0 horizontal-scroll">
            <ng-container *ngIf="!noResults; else noResultsContent">
              <table class="table table-responsive">
                <thead>
                  <tr class="table-data-campaings">
                    <th>Campaing Name</th>
                    <th>Start</th>
                    <th>Status</th>
                    <th>Spend</th>
                    <th>CPC</th>
                    <th>PPC Sales</th>
                    <th>ACOS</th>
                    <th>ROAS</th>
                    <th>Profit</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of campaingsToSearch | paginate: config">
                    <tr>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">{{item.campaign_name}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">{{utilities.convertFormatDate(item.date)}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">{{item.campaign_status}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">${{item.spend | number:'1.2-2'}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">${{item.cpc | number:'1.2-2'}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">${{item.sales | number:'1.2-2'}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">{{item.acos | number:'1.2-2'}}%</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">{{(item.spend ? (item.sales / item.spend).toFixed(2) : 0) | number:'1.2-2'}}</td>
                      <td  [ngClass]="item.campaign_status === 'ENABLED' ? 'enabled' : item.campaign_status === 'PAUSED' ? 'paused' : 'archived'">${{item.profit | number:'1.2-2'}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <pagination-controls (pageChange)="config.currentPage = $event"></pagination-controls>
            </ng-container>

            <ng-template #noResultsContent>
              <app-empty-search></app-empty-search>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <ng-template #loadingContentTable>
        <app-skeleton-element-list [typeOfContentSkeleton]="'table'"></app-skeleton-element-list>
      </ng-template>
    </ng-container>
    <ng-template #noElementsExisting>
      <app-empty-search></app-empty-search>
    </ng-template>
  </div>
</div>

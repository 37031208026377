<div class="container-fluid">
  <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <ng-container *ngIf="account_id; else noAccountSelected">
    <div class="col-sm-12 mb-30">
      <div class="card">
         <div class="card-container-filters">
            <div class="d-flex align-items-center justify-content-end">
               <div class="row mr-2" *ngIf="showDatesSection">
                  <div class="col-sm-6 date-search">
                     <span class="ri-calendar-line"></span>
                     <input [ngModelOptions]="{standalone: true}" name="initDate" id="initDate"
                     [(ngModel)]="initDate" (ngModelChange)="searchByDates()" ngbDatepicker class="form-control"
                     autoClose="false" placeholder="YYYY-MM-DD" (click)="date.toggle()" #date="ngbDatepicker">
                  </div>
                  <div class="col-sm-6 date-search">
                     <span class="ri-calendar-line"></span>
                     <input [ngModelOptions]="{standalone: true}" name="endDate" id="endDate"
                     [minDate]="minEndDate" [(ngModel)]="endDate"
                     (ngModelChange)="searchByDates()" ngbDatepicker class="form-control"
                     autoClose="false" placeholder="YYYY-MM-DD" (click)="dateEnd.toggle()" #dateEnd="ngbDatepicker">
                  </div>
               </div>
               <div class="dropdown mr-2" ngbDropdown>
                  <button class="btn btn-sm btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
                     ngbDropdownToggle>
                  {{textFilterDate}} <i class="mdi mdi-chevron-down"></i>
                  </button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                     <p class="dropdown-item dropdown-chart-filters-item"
                     *ngFor="let filter of filtersDate"
                     (click)="activeFilteDate(filter)">
                     {{filter.title}}
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

    <div class="row">
      <div class="col-12" *ngIf="isChartSelected('ppc_1')">
        <app-sales-chart></app-sales-chart>
      </div>
      <div class="col-12" *ngIf="isChartSelected('financial_1')">
        <app-financial-pl></app-financial-pl>
      </div>
      <div class="col-6 pd-btns-carrousel" id="donutProductsChart" *ngIf="isChartSelected('products_1')">
        <app-donut-products></app-donut-products>
      </div>
      <div class="col-6 pd-btns-carrousel" id="cardTableProducts" *ngIf="isChartSelected('products_2')">
        <app-table-products></app-table-products>
      </div>
      <div class="col-12" *ngIf="isChartSelected('financial_2')">
        <app-financial-trends [isDashboardView]="true"></app-financial-trends>
      </div>
      <div class="col-6" *ngIf="isChartSelected('products_3')">
        <app-gauss-products></app-gauss-products>
      </div>
      <div class="col-6" *ngIf="isChartSelected('ppc_2')">
        <app-interactions-chart></app-interactions-chart>
      </div>
      <div class="col-6" *ngIf="isChartSelected('ppc_3')">
        <app-impressions-chart></app-impressions-chart>
      </div>
      <div class="col-6" *ngIf="isChartSelected('ppc_4')">
        <app-profit-chart></app-profit-chart>
      </div>
      <div class="col-7" *ngIf="isChartSelected('insights_1')">
        <app-sells-by-weekday [initialDate]="initialDate" [endedDate]="endedDate"></app-sells-by-weekday>
      </div>
      <div class="col-5" *ngIf="isChartSelected('insights_2')">
        <app-sells-by-hour [initialDate]="initialDate" [endedDate]="endedDate"></app-sells-by-hour>
      </div>
      <div class="col-12" *ngIf="isChartSelected('inventory')">
        <app-map-chart-inventory [initDate]="initialDate" [endDate]="endedDate"></app-map-chart-inventory>
      </div>
    </div>
  </ng-container>
  <ng-template #noAccountSelected>
    <div class="container-empty-search">
      <div class="row">
        <div class="col-lg-12">
          <div class="empty-search">
            <div class="empty-search__icon">
              <img src="../../../assets/images/empty.png" class="image-icon" alt="empty search">
            </div>
            <div class="empty-search__title">
              <h3>{{'EMPTY_SEARCH.TITLE' | translate}}</h3>
            </div>
            <div class="empty-search__description">
              <p class="text-center">
                We could not find information, you must select the account to obtain the advertising spending information so that we can create your dashboard, please click on the accounts icon (<i class="ri-team-line"></i>) in the top bar in the right corner and configure the account
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InteractionsChartComponent } from './interactions-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { EmptySearchComponentModule } from '../../../../support/empty-search/empty-search.module';
import { SkeletonElementListComponentModule } from '../../../../support/skeleton-element-list/skeleton-element-list.module';
import { NgbModule, NgbNavModule, NgbCarouselModule, NgbDropdownModule, NgbTooltipModule, NgbPaginationModule, NgbCollapseModule, NgbModalModule, NgbAccordionModule, NgbPopoverModule, NgbDatepickerModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        EmptySearchComponentModule,
        NgbProgressbarModule,
        NgbAccordionModule,
        SkeletonElementListComponentModule,
        NgChartsModule,
        NgApexchartsModule
      ],
    declarations: [
      InteractionsChartComponent
    ],
    exports:[ InteractionsChartComponent ]
})
export class InteractionsChartModule { }

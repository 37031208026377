import { ActivatedRoute, Params } from '@angular/router';
import { PL } from 'src/app/core/models/pl.models';
import { DataPL, DataTrend, ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TopBarService } from 'src/app/core/services/topbar.service';
import Utils from 'src/app/core/utilities/utils';
import { lineBarChart, dataTotals } from '../../data';
import { ChartType, DatepickerElement } from 'src/app/core/models/elements.model';
import { ProductService } from 'src/app/core/services/products.service';
import { Products } from 'src/app/core/models/products.model';
import { PaginationInstance } from 'ngx-pagination';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export interface ProductFilter {
  name: string;
  value: string;
}

export class FinancialPLContainer {
  noResults: boolean = true;
  loadingPL: boolean = true;
  orderTable: boolean = true;
  public products: Products[] = [];
  public productsToSearch: Products[] = [];

  dataPL: DataPL | undefined;
  typeFilterSelected: string;
  searchProductTrend: string;
  searchProductList: string;
  utilities: Utils = new Utils();
  textFilter: string = 'Filters';
  lineBarChart: ChartType;
  textFilterChart: string = 'Filters';
  dataTotals: any = dataTotals;
  initialTotals: any = dataTotals;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  minEndDate: NgbDate;

  productFilters: ProductFilter[] = [
    {"name": "Sales shipped", "value": "sales"},
    {"name": "Units", "value":"units"},
    {"name": "Promotion", "value":"promotion"},
    {"name": "Amazon fee", "value":"amazon_fee"},
    {"name": "Gross profit", "value":"gros_profit"}
  ];

  productFiltersChart: ProductFilter[] = [
    {"name": "Sales", "value":"rowSales"},
    {"name": "Sales shipped", "value": "rowSalesShipped"},
    {"name": "Unit Sold", "value":"rowUnits"},
    {"name": "Promotion", "value":"rowPromotion"},
    {"name": "Amazon fee", "value":"rowAmazonFee"},
    {"name": "Gross profit", "value":"rowGrossProfit"},
    {"name": "Shipping Cost", "value":"rowShippingCost"},
    {"name": "VAT", "value":"rowVat"},
  ];

  keyToChartFilter: string = 'rowSales';

  constructor(public topBarService: TopBarService, public indexDbCacheService: IndexedDbCacheService, public productsService: ProductService, public loadingSpinnerService: LoadingSpinnerService) {
    this.lineBarChart = lineBarChart;
    this.getGeneralDataPL();
  }

  manualSyncDataPLFromService() {
    this.loadingPL = !this.loadingPL;
    this.getGeneralDataPL(true, true);
  }

  async getGeneralDataPL(syncData: boolean = false, overwriteCache: boolean = true) {
    (await this.topBarService.generalDataPLVTwo(syncData, overwriteCache)).subscribe((response: ResponseService) => {
      this.loadingPL = false;
      this.initializeDataPL();
      this.noResults = !(response.data.length > 0);
      const objStructurePL= this.generateDataStructurePL(response.data);

      this.dataPL['header'] = objStructurePL.header;
      this.dataPL['rowSales']= [...this.dataPL['rowSales'],...objStructurePL.rowSales];
      this.dataPL['rowSales']= [...this.dataPL['rowSales'],...[Number(this.utilities.getSum(this.dataPL['rowSales'])).toFixed(2)]];
      this.dataPL['rowSalesShipped']= [...this.dataPL['rowSalesShipped'],...objStructurePL.rowSalesShipped];
      this.dataPL['rowSalesShipped']= [...this.dataPL['rowSalesShipped'],...[Number(this.utilities.getSum(this.dataPL['rowSalesShipped'])).toFixed(2)]];
      this.dataPL['rowUnits']= [...this.dataPL['rowUnits'],...objStructurePL.rowUnits];
      this.dataPL['rowUnits']= [...this.dataPL['rowUnits'],...[Number(this.utilities.getSum(this.dataPL['rowUnits'])).toFixed(2)]];
      this.dataPL['rowPromotion']= [...this.dataPL['rowPromotion'],...objStructurePL.rowPromotion];
      this.dataPL['rowPromotion']= [...this.dataPL['rowPromotion'],...[Number(this.utilities.getSum(this.dataPL['rowPromotion'])).toFixed(2)]];
      this.dataPL['rowShippingCost']= [...this.dataPL['rowShippingCost'],...objStructurePL.rowShippingCost];
      this.dataPL['rowShippingCost']= [...this.dataPL['rowShippingCost'],...[Number(this.utilities.getSum(this.dataPL['rowShippingCost'])).toFixed(2)]];
      this.dataPL['rowAmazonFee']= [...this.dataPL['rowAmazonFee'],...objStructurePL.rowAmazonFee];
      this.dataPL['rowAmazonFee']= [...this.dataPL['rowAmazonFee'],...[Number(this.utilities.getSum(this.dataPL['rowAmazonFee'])).toFixed(2)]];
      this.dataPL['rowVat']= [...this.dataPL['rowVat'],...objStructurePL.rowVat];
      this.dataPL['rowVat']= [...this.dataPL['rowVat'],...[Number(this.utilities.getSum(this.dataPL['rowVat'])).toFixed(2)]];
      this.dataPL['rowGrossProfit']= [...this.dataPL['rowGrossProfit'],...objStructurePL.rowGrossProfit];
      this.dataPL['rowGrossProfit']= [...this.dataPL['rowGrossProfit'],...[Number(this.utilities.getSum(this.dataPL['rowGrossProfit'])).toFixed(2)]];

      const newBarChart = JSON.parse(JSON.stringify(this.lineBarChart));
      newBarChart.labels = objStructurePL.header.slice(1, objStructurePL.header.length);
      newBarChart.datasets[0].data = this.dataPL[this.keyToChartFilter].slice(1, this.dataPL[this.keyToChartFilter].length);
      this.lineBarChart = newBarChart;
    });
  }

  activeFilterChart(typeFilter: any) {
    this.textFilterChart = typeFilter.name;
    this.keyToChartFilter = typeFilter.value;
    const newBarChart = JSON.parse(JSON.stringify(this.lineBarChart));
    newBarChart.labels = this.dataPL['header'].slice(1, this.dataPL['header'].length);
    newBarChart.datasets[0].data = this.dataPL[this.keyToChartFilter].slice(1, this.dataPL[this.keyToChartFilter].length);
    this.lineBarChart = newBarChart;
  }

  private initializeDataPL(): void {
    this.dataPL = new DataPL();
    this.dataPL.header = ["Parameters/Date"];
    this.dataPL.rowSales = ["Sales"];
    this.dataPL.rowSalesShipped = ["Sales shipped"];
    this.dataPL.rowUnits = ["Units sold"];
    this.dataPL.rowPromotion = ["Promo"];
    this.dataPL.rowShippingCost = ["Shipping costs"];
    this.dataPL.rowAmazonFee = ["Amazon fees"];
    this.dataPL.rowVat = ["VAT"];
    this.dataPL.rowGrossProfit = ["Gross profit"];
  }

  generateDataStructurePL(data: PL[]): DataPL{
    let months = data.map((item:any) => (item.name_month));
    let sales = data.map((item:any) => (Number(item.finance.saleGlobal).toFixed(2)));
    let sales_shipped = data.map((item:any) => (Number(item.finance.sales).toFixed(2)));
    let units = data.map((item:any) => (item.finance.units));
    let promotion = data.map((item:any) => (Number(item.finance.promotion).toFixed(2)));
    let shipping_cost = data.map((item:any) => (Number(item.finance.shipping_cost).toFixed(2)));
    let amazon_fee = data.map((item:any) => (Number(item.finance.amazon_fee).toFixed(2)));
    let vat = data.map((item:any) => (Number(item.finance.marketplace_facilitator_vat).toFixed(2)));
    let gross_profit = data.map((item:any) => (Number(item.finance.gros_profit).toFixed(2)));

    return {
      header: [...this.dataPL['header'], ...months, ...["Total"]],
      rowSales: sales,
      rowSalesShipped: sales_shipped,
      rowUnits: units,
      rowPromotion: promotion,
      rowShippingCost: shipping_cost,
      rowAmazonFee: amazon_fee,
      rowVat: vat,
      rowGrossProfit: gross_profit,
    }
  }
}

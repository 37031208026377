import { ChartTypeApex, ChartTypeLineColumn, ChartTypeRadialChart } from '../../core/models/elements.model';

const basicRadialBarChart: ChartTypeRadialChart = {
  chart: {
      height: 350,
      type: 'donut',
  },
  series: [44, 55, 41, 17, 15],
  plotOptions: {
    pie: {
        donut: {
            size: '25%'  // Ajusta el tamaño del donut
        }
    }
  },
  dataLabels: {
    style: {
      colors: ["#fff"],
      fontSize: "10px",
    },
  },
  legend: {
      show: true,
      position: 'right',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '8px',
      offsetX: 0,
      offsetY: -10
  },
  labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5'],
  colors: ['#1cbb8c', '#5664d2', '#fcb92c', '#4aa3ff', '#ff3d60'],
  responsive: [{
      breakpoint: 600,
      options: {
          chart: {
              height: 240
          },
          legend: {
              show: false
          },
      }
  }],
};

const basicLineColumnsChart: ChartTypeLineColumn = {
  series: [
    {
      name: "Impressions",
      type: "column",
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    },
    {
      name: "Clicks",
      type: "line",
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ],
  chart: {
    height: 350,
    type: "line",
    toolbar: {
      show: true,
      tools: {
        download: true,  // Mostrar solo la opción de descarga
        zoom: false,     // Ocultar opciones de zoom
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
    }
  },
  stroke: {
    width: [0, 4]
  },
  title: {
    text: "Impressions/Clicks"
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1]
  },
  labels: [
    "01 Jan 2001",
    "02 Jan 2001",
    "03 Jan 2001",
    "04 Jan 2001",
    "05 Jan 2001",
    "06 Jan 2001",
    "07 Jan 2001",
    "08 Jan 2001",
    "09 Jan 2001",
    "10 Jan 2001",
    "11 Jan 2001",
    "12 Jan 2001"
  ],
  xaxis: {
    type: "category",
    labels: {
      style: {
        fontSize: '10px', // Ajusta el tamaño de la letra según tus necesidades
      },
    },
  },
  yaxis: [
    {
      title: {
        text: "Impressions"
      }
    },
    {
      opposite: true,
      title: {
        text: "Clicks"
      }
    }
  ]
};

const basicSalesLineColumnsChart: ChartTypeLineColumn = {
  series: [
    {
      name: "Sales",
      type: "column",
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    },
    {
      name: "Spend",
      type: "line",
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ],
  chart: {
    height: 350,
    type: "line",
    toolbar: {
      show: true,
      tools: {
        download: true,  // Mostrar solo la opción de descarga
        zoom: false,     // Ocultar opciones de zoom
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
    }
  },
  stroke: {
    width: [0, 4]
  },
  title: {
    text: "Sales/Spend"
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1]
  },
  labels: [
    "01 Jan 2001",
    "02 Jan 2001",
    "03 Jan 2001",
    "04 Jan 2001",
    "05 Jan 2001",
    "06 Jan 2001",
    "07 Jan 2001",
    "08 Jan 2001",
    "09 Jan 2001",
    "10 Jan 2001",
    "11 Jan 2001",
    "12 Jan 2001"
  ],
  xaxis: {
    type: "category",
    labels: {
      style: {
        fontSize: '10px', // Ajusta el tamaño de la letra según tus necesidades
      },
    },
  },
  yaxis: [
    {
      title: {
        text: "Sales"
      }
    },
    {
      opposite: true,
      title: {
        text: "Spend"
      }
    }
  ]
};


const dashedLineChart: ChartTypeApex = {
  chart: {
      height: 380,
      type: 'line',
      zoom: {
          enabled: false
      },
      toolbar: {
          show: false,
      }
  },
  colors: ['#5664d2', '#fcb92c', '#1cbb8c', '#4aa3ff', '#ff3d60', '#F021E0'],
  dataLabels: {
      enabled: false
  },
  stroke: {
      width: [3, 4, 3],
      curve: 'straight',
      dashArray: [0, 8, 5]
  },
  series: [
    {
      name: 'ACOS',
      data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    }
  ],
  title: {
      text: 'Page Statistics',
      align: 'left'
  },
  markers: {
      size: 0,

      hover: {
          sizeOffset: 6
      }
  },
  xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
          '10 Jan', '11 Jan', '12 Jan'
      ],
  },
  tooltip: {
      y: [{
          title: {
              formatter: (val) => {
                  return val + ' (mins)';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val + ' per session';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val;
              }
          }
      }]
  },
  grid: {
      borderColor: '#f1f1f1',
  }
};

const roasDashedLineChart: ChartTypeApex = {
  chart: {
      height: 380,
      type: 'line',
      zoom: {
          enabled: false
      },
      toolbar: {
          show: false,
      }
  },
  colors: ['#4aa3ff'],
  dataLabels: {
      enabled: false
  },
  stroke: {
      width: [3, 4, 3],
      curve: 'straight',
      dashArray: [0, 8, 5]
  },
  series: [
    {
      name: 'ROAS',
      data: [89, 56, 74, 98, 72, 38, 64, 46, 84, 58, 46, 49]
    }
  ],
  title: {
      text: 'Page Statistics',
      align: 'left'
  },
  markers: {
      size: 0,

      hover: {
          sizeOffset: 6
      }
  },
  xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
          '10 Jan', '11 Jan', '12 Jan'
      ],
  },
  tooltip: {
      y: [{
          title: {
              formatter: (val) => {
                  return val + ' (mins)';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val + ' per session';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val;
              }
          }
      }]
  },
  grid: {
      borderColor: '#f1f1f1',
  }
};

const profitLineChart: ChartTypeApex = {
  chart: {
      height: 380,
      type: 'line',
      zoom: {
          enabled: false
      },
      toolbar: {
          show: false,
      }
  },
  colors: ['#F021E0'],
  dataLabels: {
      enabled: false
  },
  stroke: {
      width: [3, 4, 3],
      curve: 'straight',
      dashArray: [0, 8, 5]
  },
  series: [
    {
      name: 'Profit',
      data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
    }
  ],
  title: {
      text: 'Page Statistics',
      align: 'left'
  },
  markers: {
      size: 0,

      hover: {
          sizeOffset: 6
      }
  },
  xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
          '10 Jan', '11 Jan', '12 Jan'
      ],
  },
  tooltip: {
      y: [{
          title: {
              formatter: (val) => {
                  return val + ' (mins)';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val + ' per session';
              }
          }
      }, {
          title: {
              formatter: (val) => {
                  return val;
              }
          }
      }]
  },
  grid: {
      borderColor: '#f1f1f1',
  }
};

export { basicRadialBarChart, basicLineColumnsChart, dashedLineChart, profitLineChart, basicSalesLineColumnsChart, roasDashedLineChart };

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-search',
  templateUrl: './empty-search.component.html',
  styleUrls: ['./empty-search.component.scss']
})

/**
 * Pages-500 component
 */
export class EmptySearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

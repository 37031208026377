import { HttpRequestService } from './http-request.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, from, mergeMap, of, map, throwError, takeUntil } from 'rxjs';
import { IndexedDbCacheService } from './index-db-cache.service';
import { OnlineOfflineService } from './online-offline.service';
import { Products } from '../models/products.model';
import { ResponseService } from '../models/response.models';
import Utils from '../utilities/utils';

@Injectable({ providedIn: 'root' })
export class ProductService {

  public globalUrl: string;
  public utilities: Utils;

  constructor(
    public _http: HttpClient,
    private indexDBCacheService: IndexedDbCacheService,
    private httpRequestService: HttpRequestService,
    private onlineOfflineService: OnlineOfflineService) {
    this.globalUrl = environment.baseUrlProd;

    this.onlineOfflineService.connectionChanged.subscribe(online => {
      if (online) {
        this.getAllProductsByOrders(true, true);
      }
    });
  }

  async getAllProductsByOrders(syncData: boolean = false, overwriteCache: boolean = true, queryParameters: Record<string, string | boolean> = {}): Promise<Observable<any>> {
    try {
      const cachedData: Products[] = await this.indexDBCacheService.getRegister('products');

      if (cachedData && cachedData.length > 0 && !syncData) {
        return of({ data: cachedData, cache: true});
      }

      const newData = await this.fetchDataFromService(queryParameters);

      if (overwriteCache) {
        await this.indexDBCacheService.saveRegister('products', newData);
      } else {
        const combinedData = this.combineData(cachedData, newData);
        await this.indexDBCacheService.saveRegister('products', combinedData);
      }

      return of({ data: newData, cache: false});
    } catch (error) {
      throw error;
    }
  }

  private async fetchDataFromService(queryParameters: Record<string, string | boolean> = {}): Promise<Products[]> {
    try {
      let params = new HttpParams();
      if (queryParameters.initDate && queryParameters.endDate) {
        params = new HttpParams().set('start_date', queryParameters.initDate).set('end_date', queryParameters.endDate);
      }

      const observable = this._http.get<ResponseService>(this.globalUrl + 'get-all_product-by-orders', {params})
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );

      const response = await observable.toPromise();
      return response ? response.data : null;
    } catch (error) {
      throw error;
    }
  }

  private combineData(cachedData: Products[], newData: Products[]): Products[] {
    const uniqueData = newData.filter(item => !cachedData.some(existingItem => existingItem.id === item.id));
    return [...cachedData, ...uniqueData];
  }
}

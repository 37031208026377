import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, from, mergeMap, of, map, throwError, takeUntil } from 'rxjs';
import { IndexedDbCacheService } from './index-db-cache.service';
import { OnlineOfflineService } from './online-offline.service';
import { Products } from '../models/products.model';
import { FinancesData, ResponseService, ResponseServiceObject } from '../models/response.models';
import Utils from '../utilities/utils';
import { HttpRequestService } from './http-request.service';

@Injectable({ providedIn: 'root' })
export class TilesService {

  public globalUrl: string;
  public utilities: Utils;
  public arrayOfDate: any = [];

  constructor(
    public _http: HttpClient,
    private indexDBCacheService: IndexedDbCacheService,
    private httpRequestService: HttpRequestService,
    private onlineOfflineService: OnlineOfflineService) {
    this.globalUrl = environment.baseUrlProd;
    this.onlineOfflineService.connectionChanged.subscribe(online => {
      if (online) {
        this.getAllTiles(true, true);
      }
    });
  }

  async getAllTiles(syncData: boolean = false, overwriteCache: boolean = true): Promise<Observable<any>> {
    try {
      const cachedData: any = await this.indexDBCacheService.getRegister('tiles');

      if (cachedData && cachedData.length > 0 && !syncData) {
        return of({ data: cachedData, cache: true});
      }

      const newData = await this.fetchDataFromService();
      await this.indexDBCacheService.saveRegister('tiles', newData);

      return of({ data: newData, cache: false});
    } catch (error) {
      throw error;
    }
  }

  private async fetchDataFromService(): Promise<any> {
    try {
      const observable =  this._http.get<ResponseServiceObject>(this.globalUrl + 'get-finance-for-cards')
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );

      const response = await observable.toPromise();

      const defaultFinances: FinancesData = response ? response.data : null;
      const financeToday = defaultFinances ? defaultFinances.finances_current : null;
      const financeYesterday = defaultFinances ? defaultFinances.finances_yesterday : null;
      const financeToMonthDate = defaultFinances ? defaultFinances.finaces_to_month : null;
      const financeLastMonth = defaultFinances ? defaultFinances.finances_last_month : null;
      const defaultFinancesDate = [
        {...financeToday, id: 1}, {...financeYesterday, id: 2}, {...financeToMonthDate, id: 3}, {...financeLastMonth, id: 4}
      ]
      return defaultFinancesDate;
    } catch (error) {
      throw error;
    }
  }

  private combineData(cachedData: Products[], newData: Products[]): Products[] {
    const uniqueData = newData.filter(item => !cachedData.some(existingItem => existingItem.id === item.id));
    return [...cachedData, ...uniqueData];
  }

  public async getFinancesByDates(startDate: string, endDate: string): Promise<Observable<any>> {
    try {
      const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);

      const response = this._http.get(this.globalUrl + 'get-finance-by-date', { params })
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

import { HttpHeaders } from "@angular/common/http";
import { LocalStorageService } from "../services/local-storage.service";
import { equivalentStates } from "../../pages/inventory/data";

class Utils {
  public localStorageService: LocalStorageService;
  public equivalentStates: any;

  toUpperCase(input: string): string {
    return input.toUpperCase();
  }

  toLowerCase(input: string): string {
    return input.toLowerCase();
  }

  getFormatDate(date:Date): string{
    const formattedDate = date.toISOString().substring(0, 10);
    return formattedDate;
  }

  getFormattedDate(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  }

  getFormattedDateYear(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  getDateInStringTimezone(date: any) {
    const dateObject = new Date(date);
    // Obtener los componentes de la fecha y hora en la zona horaria local
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const seconds = dateObject.getSeconds().toString().padStart(2, "0");
    const milliseconds = dateObject.getMilliseconds().toString().padStart(3, "0");

    // Crear la cadena en el formato deseado
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return formattedDate
  }

  addJsonHeader(): HttpHeaders {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return headers;
  }

  addFormUrlEncoded(): HttpHeaders {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return headers;
  }

  getKeysFromObject(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  getSum(listValue:Array<string>){
    let sum: number = 0;

    for (let value of listValue) {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        sum += numValue;
      }
    }

    return sum
  }

  chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  convertFormatDate(date) {
    const dateParts = date.split('-');
    const dateObj = new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
    dateObj.setDate(dateObj.getDate() + 1);

    const options = { day: 'numeric', month: 'short', year: 'numeric' } as Intl.DateTimeFormatOptions;
    const dateFormat = dateObj.toLocaleDateString('en-US', options);

    return dateFormat;
  }

  getNameByDay(day: number): string {
    const nameDays = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    return nameDays[day];
  }

  setUserElements(response: any) {
    this.localStorageService = new LocalStorageService();

    const idUser = response.data.id;
    const userType = response.data.user_type;
    const amazonSPAPIStatus = response.data.status_sp_api;
    this.localStorageService.saveData('idUser', String(idUser));
    this.localStorageService.saveData('userType', userType);
    this.localStorageService.saveData('amazonSPAPIStatus', amazonSPAPIStatus);
    this.localStorageService.saveData('status_subscription', JSON.stringify(response.data.status_subscription));
    this.localStorageService.saveData('authorizationRouteAds', JSON.stringify(response.data.authorization_route));

    if (userType === 'seller') {
      this.localStorageService.saveData("authorizationRouteSeller",JSON.stringify(response.data.authorization_sp_api_route))
    }

    if (userType === 'vendor') {
      this.localStorageService.saveData("authorizationRouteVendor",JSON.stringify(response.data.authorization_sp_api_route_vendor))
    }
  }

  getCurrentQuarter() {
    const fecha = new Date();
    const year = fecha.getFullYear();
    const trimestre = Math.floor(fecha.getMonth() / 3) + 1;
    const mesInicio = (trimestre - 1) * 3;
    const mesFin = mesInicio + 2;

    const primerDia = this.getDateInStringTimezone(new Date(year, mesInicio, 1));
    const ultimoDia = this.getDateInStringTimezone(new Date(year, mesFin + 1, 0)); // Obtener el último día del mes siguiente

    return { startDate: primerDia, endDate: ultimoDia };
  }


  getQuarterDate() {
    const today = new Date();
    const lastQuarterStartDateFormatted = this.getDateInStringTimezone(new Date(today.getFullYear(), Math.floor((today.getMonth() - 2) / 3) * 3, 1));
    const lastQuarterStartDate = new Date(today.getFullYear(), Math.floor((today.getMonth() - 2) / 3) * 3, 1);
    const lastQuarterEndDate = new Date(lastQuarterStartDate);
    lastQuarterEndDate.setMonth(lastQuarterStartDate.getMonth() + 3);
    lastQuarterEndDate.setDate(lastQuarterEndDate.getDate() - 1);
    const lastQuarterEndDateFormatted = this.getDateInStringTimezone(lastQuarterEndDate);
    return {
      startDate: lastQuarterStartDateFormatted,
      endDate: lastQuarterEndDateFormatted
    }
  }

  getLastQuarterDate() {
    const fecha =  new Date();
    const year = fecha.getFullYear();
    const month = fecha.getMonth();

    let trimestreAnteriorInicio, trimestreAnteriorFin;
    // Calcula el año y el mes de inicio del trimestre anterior
    let yearTrimestreAnterior, mesInicioTrimestreAnterior;
    if (month >= 0 && month <= 2) {
      yearTrimestreAnterior = year - 1;
      mesInicioTrimestreAnterior = 6;
    } else if (month >= 3 && month <= 5) {
      yearTrimestreAnterior = year - 1;
      mesInicioTrimestreAnterior = 9;
    } else if (month >= 6 && month <= 8) {
      yearTrimestreAnterior = year;
      mesInicioTrimestreAnterior = 0;
    } else if (month >= 9 && month <= 11){
      yearTrimestreAnterior = year;
      mesInicioTrimestreAnterior = 3
    }

    // Calcula la fecha de inicio del trimestre anterior
    trimestreAnteriorInicio = this.getDateInStringTimezone(new Date(yearTrimestreAnterior, mesInicioTrimestreAnterior, 1));

    // Calcula la fecha de fin del trimestre anterior
    trimestreAnteriorFin = this.getDateInStringTimezone(new Date(yearTrimestreAnterior, mesInicioTrimestreAnterior + 3, 0));

    return { startDate: trimestreAnteriorInicio, endDate:trimestreAnteriorFin};
  }

  addValueToMunicipalities(municipalities, orders) {
    return municipalities?.map(municipio => {

      // Inicializa el contador de códigos postales para este municipio
      let codigoPostalCount = 0;

      // Itera sobre las órdenes y verifica si hay coincidencias con los códigos postales del municipio
      orders?.forEach(order => {
          municipio.postalCodes.forEach(postalCode => {
            const shipPostalCode = order.ship_postal_code.length === 4 ? `0${order.ship_postal_code}` : order.ship_postal_code;
            if ( shipPostalCode === postalCode.code) {
                codigoPostalCount++;
            }
          });
      });

      return {
          ...municipio,
          value: codigoPostalCount
      };
    });
  }

  cleanAndNormalizeString(input: string): string {
    return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  getTopStates(states) {
    const clonedStates = [...states];
    const sortedStates = clonedStates.sort((a, b) => b.value - a.value);
    const topStates = sortedStates.slice(0, 5);
    return topStates;
  }

  countShipStates(data: any[]): Record<string, number> {
    this.equivalentStates = equivalentStates;
    const stateCounts: Record<string, number> = {};

    data?.forEach(item => {
      let state = this.cleanAndNormalizeString(item.ship_state);
      state = this.equivalentStates[state] || state;
      stateCounts[state] = (stateCounts[state] || 0) + 1;
    });

    return stateCounts;
  }

  filterProductsToList(searchTerm: string, keyToSearch: string, products: any) {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (lowerCaseSearchTerm.trim() === '') {
      return {
        productsToSearch: this.chunkArray([...products], 4),
        productsToList: products,
        maxSalesProduct: null,
        noResults: false
      };
    }

    const productToSearch = this.chunkArray(products.filter(product =>
        (product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(lowerCaseSearchTerm)
    ), 6);
    const productsToList = products.filter(product => (product.asin.toLowerCase() + ' ' + product.seller_sku.toLowerCase()).includes(lowerCaseSearchTerm));

    return {
      productsToSearch: productToSearch,
      productsToList:  productsToList,
      maxSalesProduct: productsToList.reduce((max, current) => {return (max[keyToSearch] > current[keyToSearch]) ? max : current;}, products[0]),
      noResults: productToSearch.length === 0
    };
  }

  generateRangeOfDates(filter: any) {
    let dateCase = null;
    const today = new Date();
    let showDatesSection = true;
    const textFilterDate = filter.title;

    let initialDate = '';
    let endedDate = this.getDateInStringTimezone(today);

    switch (filter.key) {
      case 'seven_days':
        showDatesSection = false;
        initialDate = this.getDateInStringTimezone(today.setDate(today.getDate() - 7));
        break;
      case 'fourteen_days':
        showDatesSection = false;
        initialDate = this.getDateInStringTimezone(today.setDate(today.getDate() - 14));
        break;
      case 'thirty_days':
        showDatesSection = false;
        initialDate = this.getDateInStringTimezone(today.setDate(today.getDate() - 30));
        break;
      case 'current_quarter':
        showDatesSection = false;
        dateCase = this.getCurrentQuarter();
        initialDate = dateCase.startDate;
        endedDate = dateCase.endDate;
        break;
      case 'quarter':
        showDatesSection = false;
        dateCase = this.getQuarterDate();
        initialDate = dateCase.startDate;
        endedDate = dateCase.endDate;
        break;
      case 'previous_quarter':
        showDatesSection = false;
        dateCase = this.getLastQuarterDate();
        initialDate = dateCase.startDate;
        endedDate = dateCase.endDate;
        break;
      default:
        showDatesSection = true;
        break;
    }

    return {
      showDatesSection: showDatesSection,
      initialDate: initialDate,
      endedDate: endedDate,
      titleFilter: textFilterDate
    }
  }

  getCounterByDayForDates(orders: any[]): any {
    const dataCounters = [];
    const counters: number[] = [0, 0, 0, 0, 0, 0, 0]; // Contadores para lunes, martes, miércoles, jueves, viernes, sábado y domingo respectivamente

    orders.forEach(sale => {
      const date = new Date(sale.purchase_date);
      const weekDay = date.getDay(); // 0 para domingo, 1 para lunes, 2 para martes, ..., 6 para sábado
      const nameDay = this.getNameByDay(weekDay);
      counters[weekDay]++
    });

    const countersWithIndex = counters.map((value, index) => ({ key: index, value }));
    return {
      counters: counters,
      counterByDay: this.chunkArray(countersWithIndex, 7)
    };
  }

  countSalesByHour(orders: any[]): number[] {
    const counterByHour: number[] = Array(24).fill(0);
    orders.forEach((order) => {
      const date = new Date(order.purchase_date);
      const hour = date.getHours();
      counterByHour[hour]++;
    });

    return counterByHour;
  }

  counterByDayByHour(orders: any[], day: number): number[] {
    const counterByHour: number[] = Array(24).fill(0); // Inicializar un arreglo de 24 elementos con valor 0

    // Filtrar ventas para el día de la semana especificado
    const ordersByDay = orders.filter(order => { const date = new Date(order.purchase_date); return date.getDay() === day; });

    // Contar las ventas por hora
    ordersByDay.forEach(order => {
      const date = new Date(order.purchase_date);
      const hourOrder = date.getHours();
      counterByHour[hourOrder]++;
    });
    return counterByHour;
  }
}

export default Utils;

import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { IndexedDbCacheService } from './index-db-cache.service';
import { HttpRequestService } from './http-request.service';

@Injectable({ providedIn: 'root' })

export class AuthService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public globalUrl: string;

    constructor(
      private http: HttpClient,
      private localStorage: LocalStorageService,
      public indexedDbService: IndexedDbCacheService,
      private httpRequestService: HttpRequestService
    ) {
      const currentUser = this.localStorage.getData('currentUser') ? JSON.parse(this.localStorage.getData('currentUser')) : null;
      this.currentUserSubject = new BehaviorSubject<User>(currentUser);
      this.currentUser = this.currentUserSubject.asObservable();
      this.globalUrl = environment.baseUrlProd;
    }

    public get currentUserValue(): User {
      return this.currentUserSubject.value;
    }

    syncProcess(data:any){
      const dataInfo = {user_id: data};
      return this.http.post<any>(this.globalUrl + 'sync', dataInfo).pipe(map(response => {
          this.localStorage.saveData('amazonSPAPIStatus', 'queued');
      }));
    }

    reSyncProcess(data:any){
      const dataInfo = {user_id: data};
      return this.http.post<any>(this.globalUrl + 'sync', dataInfo).pipe(map(response => {
          this.localStorage.showReSyncAlert();
      }));
    }

    setPasswordUser(username: string, password: string) {
      return this.http.post<any>(this.globalUrl + 'verify-set-password', { username, password })
          .pipe(map(user => {
            this.localStorage.saveData('currentUser', JSON.stringify(user.data));
            this.currentUserSubject.next(user);
            return user;
          }));
     }

    login(username: string, password: string) {
        return this.http.post<any>(this.globalUrl + 'login', { username, password })
            .pipe(map(user => {
              // login successful if there's a jwt token in the response
              if (user.data && user.data.access_token) {
                this.cleanLocalDB();
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.localStorage.saveData('currentUser', JSON.stringify(user.data));
                this.currentUserSubject.next(user);
                this.createLocalDB();
              }
              return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        const keysToRemove = ['lastUrl', 'idUser', 'authorizationRouteAds', 'account_id', 'authorizationRouteSeller', 'userType', 'currentUser', 'account_name', 'addedCharts'];
        keysToRemove.forEach(key => {
          localStorage.removeItem(key);
        });

        this.cleanLocalDB();
        this.currentUserSubject.next(null);
        this.httpRequestService.cancelPendingRequests();
    }

    resetPassword(email: string) {
        return this.http.post<any>(this.globalUrl + 'reset-password', { email })
            .pipe(map(user => {
                return user;
            }));
    }

    registerUser(user: any) {
      return this.http.post(this.globalUrl + 'add-user', user);
    }

    async createLocalDB() {
      await this.indexedDbService.openDatabase();
    }

    async cleanLocalDB() {
      await this.indexedDbService.cleanAllTables();
    }
}

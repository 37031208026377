import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  title = 'VXI';
  loadAPI: Promise<any>;

  ngOnInit(): void {
      this.loadAPI = new Promise((resolve) => {
          console.log('resolving promise...');
          this.loadScript();
      });
  }

  public loadScript() {
    const url = '../assets/js/zohosqi.js';
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, from, mergeMap, of, map } from 'rxjs';
import Utils from '../utilities/utils';
import { IndexedDbCacheService } from './index-db-cache.service';
import { Products } from '../models/products.model';
import { ResponseService } from '../models/response.models';

@Injectable({ providedIn: 'root' })
export class ApiService {
  public globalUrl: string;
  public utilities: Utils;

  constructor(public _http: HttpClient, private indexDBCacheService: IndexedDbCacheService) {
    this.globalUrl = environment.baseUrlProd;
  }

  getFinancesWithProduct(){
    return this._http.get(this.globalUrl + 'products_with_finances');
  }

  getFinancesByDates(startDateString: string, endDateString: string): Observable<any> {
    const params = new HttpParams().set('start_date', startDateString).set('end_date', endDateString);
    return this._http.get(this.globalUrl + 'finances_by_dates', { params });
  }

  getFinancesByDatesV2(startDateString: string, endDateString: string): Observable<any> {
    const params = new HttpParams().set('start_date', startDateString).set('end_date', endDateString);
    return this._http.get(this.globalUrl + 'get-finance-by-date', { params });
  }

  getDefaultFinances(){
    return this._http.get(this.globalUrl + 'finances');
  }

  getDefaultFinancesv2(){
    return this._http.get(this.globalUrl + 'get-finance-for-cards');
  }

  getAllOrders(){
    return this._http.get(this.globalUrl + 'orders');
  }

  getAllOrdersByUser(data:any){
    return this._http.get(this.globalUrl + 'orders-by-user/' + data['init'] + '/' + data['fin']);
  }

  createAccount(data:any){
    return this._http.post(this.globalUrl + 'add-user', data);
  }

  getChartData(year: string): Observable<any> {
    const params = new HttpParams().set('year', year);
    return this._http.get(this.globalUrl + 'get-chart', { params });
  }

  getChartDataV2(): Observable<any> {
    return this._http.get(this.globalUrl + 'get-finance-for-chart');
  }

  authorizationAmazonAds(data:any){
    return this._http.post(this.globalUrl + 'autorization', data);
  }

  confirmAutorizationSPAPI(data:any){
    return this._http.post(this.globalUrl + 'confirm-autorization-sp-api', data);
  }

  authorizationAmazonSPAPI(data:any){
    return this._http.post(this.globalUrl + 'generate-token-sp-api', data);
  }

  authorizationAmazonSPAPIVendor(data:any){
    return this._http.post(this.globalUrl + 'generate-token-sp-api-vendor', data);
  }

  generalReportAds(data:any){
    return this._http.get(this.globalUrl + 'get-general-monthly-report-ads/${data.profile_id}');
  }

  getOrdersVendor(){
    return this._http.get(this.globalUrl + 'get-orders-vendor');
  }

  getInformationCampaignsByDay(data:any){
    return this._http.get(this.globalUrl + 'get-information-campaigns-by-day/${data.profile_id}/${data.date}');
  }

  getUsersProfiles(){
    return this._http.get(this.globalUrl + 'users-profiles');
  }

  generalDataPL(){
    return this._http.get(this.globalUrl + 'get-finance-pandl');
  }

  generalDataPLV2(){
    return this._http.get(this.globalUrl + 'get-pl-by-finance');
  }

  generalDataTrend(){
    return this._http.get(this.globalUrl + 'get-finance-trend');
  }
}

import { LoadingSpinnerService } from './../../../../../core/services/loading-spinner.service';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Products } from 'src/app/core/models/products.model';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ProductService } from 'src/app/core/services/products.service';
import { GaussProductsContainer } from './gauss-products.container';
import { NgbCarousel, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-gauss-products',
  templateUrl: './gauss-products.component.html',
  styleUrls: ['./gauss-products.component.scss']
})

/**
 * Dashboard Component
 */
export class GaussProductsComponent extends GaussProductsContainer implements OnInit, OnChanges {
  @Input() searchTerm: string;
  @Input() keyToSearch: string = 'sales';

  constructor(
    public productsService: ProductService,
    public indexDbCacheService: IndexedDbCacheService,
    public loadingSpinnerService: LoadingSpinnerService,
    public modalService: NgbModal
  ) {
    super(productsService, indexDbCacheService, loadingSpinnerService);
  }

  ngOnInit(): void {
    this.fetchProducts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerm'] && !changes['searchTerm'].isFirstChange()) {
      this.generateChart(true, this.searchTerm);
    }
  }
}

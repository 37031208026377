import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MapChartInventoryComponent } from './map-chart-inventory.component';
import { EmptySearchComponentModule } from '../../../../support/empty-search/empty-search.module';
import { NgbModule, NgbNavModule, NgbCarouselModule, NgbDropdownModule, NgbTooltipModule, NgbPaginationModule, NgbCollapseModule, NgbModalModule, NgbAccordionModule, NgbPopoverModule, NgbDatepickerModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        EmptySearchComponentModule,
        NgbProgressbarModule,
        NgbAccordionModule
      ],
    declarations: [
      MapChartInventoryComponent
    ],
    exports:[ MapChartInventoryComponent ]
})
export class MapChartInventoryModule { }

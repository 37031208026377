import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, ChartTypeApex } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { dashedLineChart, totalsByStatus, basicColumChart, statesMx, equivalentStates } from '../../data';
import { PaginationInstance } from 'ngx-pagination';
import { InventoryService } from 'src/app/core/services/inventory.service';
import * as echarts from 'echarts';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import ShareFunctionsInventory from 'src/app/core/utilities/share-functions-inventory';

export class TableCampaingsInventoryContainer {
  minEndDate: NgbDate;
  public utilities: Utils;
  public shareFunctionsInventory: ShareFunctionsInventory;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  orderTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  basicColumChart: ChartTypeApex;
  filters: any[] = []
  orders: any[] = [];
  ordersToSearch: any[] = [];
  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 10000, // Total de elementos
  };

  totalsByStatus: any[];
  statesMx: any[];
  topStates: any[];
  view: string = 'map';
  dataForMunicipality: any[] = [];
  isLoadedAMunicipality: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  showDatesSection: boolean = false;
  equivalentStates: any;

  constructor(public inventoryService: InventoryService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.equivalentStates = equivalentStates;
    this.statesMx = statesMx;
    this.utilities = new Utils();
    this.shareFunctionsInventory = new ShareFunctionsInventory(loadingSpinnerService, inventoryService, this.utilities);
  }

  async fetchCampaings(initDate?: string, endDate?: string) {
    try {
      const response = await this.shareFunctionsInventory.getCampaingsByDate(initDate, endDate);
      this.loadingTable = response.loadingTable;
      this.orders = response.orders;
      this.noResults = response.noResults;
      this.ordersToSearch = response.ordersToSearch;
      this.config.totalItems = this.orders?.length;
    } catch (error) {
      console.log(error, 'error in inventory container')
    }
  }

  filterCampaings(searchTerm: string | undefined): void {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (lowerCaseSearchTerm.trim() === '') {
      this.ordersToSearch = [...this.orders.sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime())];
      this.noResults = false;
      return;
    }
    this.ordersToSearch = this.orders.filter(order =>
      order.product_name.toLowerCase().includes(lowerCaseSearchTerm) ||
      order.amazon_order_id.toLowerCase().includes(lowerCaseSearchTerm) ||
      order.merchant_order_id.toLowerCase().includes(lowerCaseSearchTerm) ||
      order.ship_city.toLowerCase().includes(lowerCaseSearchTerm) ||
      order.ship_postal_code.toLowerCase().includes(lowerCaseSearchTerm) ||
      order.ship_state.toLowerCase().includes(lowerCaseSearchTerm)
    ).sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime());
    this.config.totalItems = this.ordersToSearch.length;
    this.noResults = this.ordersToSearch.length === 0;
  }

  filterPurchaseOrdersByState(searchTerm: string | undefined): void {
    if (searchTerm.trim() === '') {
      this.ordersToSearch = [...this.orders.sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime())];
      this.noResults = false;
      return;
    }
    //Revisar aqui
    this.ordersToSearch = this.orders.filter(order => {
      let state = this.utilities.cleanAndNormalizeString(order.ship_state);
      state = this.equivalentStates[state] || state;
      return state.toLowerCase().includes(searchTerm);
    } ).sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime());
    this.config.totalItems = this.ordersToSearch.length;
    this.noResults = this.ordersToSearch.length === 0;
  }

  sortColumn(column: string) {
    this.orderTable = !this.orderTable;
    if (column === 'purchase_date') {
      this.ordersToSearch = this.orders.sort((a, b) => new Date(b.purchase_date).getTime() - new Date(a.purchase_date).getTime());
    } else {
      this.ordersToSearch = this.orders.sort((a, b) => {
        // Verificar si a[column] y b[column] son cadenas de texto
        if (typeof a[column] === 'string' && typeof b[column] === 'string') {
            return a[column].localeCompare(b[column]);
        } else {
            // Si no son cadenas de texto, comparar directamente los valores
            return a[column] - b[column];
        }
      });
    }

    if (!this.orderTable) {
      this.ordersToSearch = this.ordersToSearch.reverse();
    }
  }
}

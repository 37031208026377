<div class="container-fluid">
  <app-pagetitle title="Total Sales" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
</div>

<ng-container  *ngIf="loadingTiles; else content">
  <div class="row">
    <app-skeleton-element-list [typeOfContentSkeleton]="'card-tile'"></app-skeleton-element-list>
  </div>
</ng-container>
<ng-template #content>

<div class="row">
  <div class="col-sm-5">
    <div class="card mb-minum">
      <div class="card-body pd-body-search">
        <div class="card-title mb-0">
          <div class="row">
            <div class="col-sm-12">
              <h6 class="mb-0">Filter Section</h6>
            </div>

            <div class="container-header-chart mb-0">
              <div class="row">
                <div class="col-sm-6">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
                      ngbDropdownToggle>
                      {{textFilter}} <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <p class="dropdown-item dropdown-chart-filters-item"
                        *ngFor="let filter of filters"
                        (click)="activeFilter(filter)">
                        {{filter.title}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
                      ngbDropdownToggle>
                      {{textFilterDate}} <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <p class="dropdown-item dropdown-chart-filters-item"
                        *ngFor="let filter of filtersDate"
                        (click)="activeFilteDate(filter)">
                        {{filter.title}}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12" *ngIf="showDatesSection">
                  <div class="row">
                    <div class="col-sm-6 mt-2">
                      <input [(ngModel)]="initDate" ngbDatepicker
                        (ngModelChange)="setMinDate()"
                        class="form-control input-group-calendar" autoClose="false"
                        placeholder="YYYY-MM-DD" (click)="date.toggle()" #date="ngbDatepicker">
                    </div>
                    <div class="col-sm-6 mt-2">
                      <input
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="endDate" ngbDatepicker [minDate]="minEndDate"
                        class="form-control input-group-calendar" autoClose="false"
                        placeholder="YYYY-MM-DD" (click)="dateEnd.toggle()" #dateEnd="ngbDatepicker">
                    </div>
                    <div class="col-sm-8 d-flex justify-content-start align-items-center">
                      <p class="mb-0 subtext-select-card-group">
                        {{dateSelectByRange?.date}}
                      </p>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-end align-items-center mt-2">
                      <button type="button" class="btn btn-outline-secondary btn-sm" (click)="searchByPeriod()">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!loadingFinancesByDate; else cardLoader">
      <ngb-accordion
        *ngIf="searchedElements.length > 0"
        #acc="ngbAccordion" activeIds="static-0" [closeOthers]="true">
        <ngb-panel
          *ngFor="let element of searchedElements; let i=index"
          [id]="'static-'+i">
          <ng-template ngbPanelTitle class="text-black">
            <i [ngClass]="i === 0 ? 'ri-calendar-event-line' : 'ri-calendar-todo-line'" class="mr-2 font-size-24"></i>
            {{element.date}}
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="container-intial-info">
              <div class="row">
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Sales</p>
                  <p class="text-body-card-group mb-0">
                    ${{element.data?.saleGlobal === null ? 0:element.data?.saleGlobal | number:'1.2-2' }}
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Sales Shipped</p>
                  <p class="text-body-card-group mb-0">
                    ${{element.data?.sales === null ? 0:element.data?.sales  |  number:'1.2-2' }}
                  </p>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Orders/Units</p>
                  <p class="text-body-card-group mb-0">
                    {{ element.data?.orders === null ? 0 : element.data?.orders }}/{{ element.data?.units === null ? 0 : element.data?.units }}
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Promotion</p>
                  <p class="text-body-card-group mb-0">
                    ${{ element.data?.promotion === null ? 0 : element.data?.promotion | number:'1.2-2' }}
                  </p>
                </div>
              </div>
            </div>
            <hr class="line-content-hr">
            <div class="container-secondary-info">
              <div class="row">
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Shipping Cost</p>
                  <p class="text-body-card-group mb-0">
                    ${{ element.data?.shipping_cost === null ? 0 : element.data?.shipping_cost | number:'1.2-2' }}
                  </p>
                </div>

                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Average Ticket</p>
                  <p class="text-body-card-group mb-0">
                    ${{ element.data?.saleGlobal === null || element.data?.units === 0 ? 0 : (element.data?.saleGlobal / element.data?.units) | number:'1.2-2' }}
                  </p>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Gross Profit</p>
                  <p class="text-body-card-group mb-0">
                    ${{ element.data?.gros_profit === null ? 0 : element.data?.gros_profit | number:'1.2-2' }}
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Marketplace VAT</p>
                  <p class="text-body-card-group mb-0">
                    ${{element.data?.marketplace_facilitator_vat === null ? 0 : element.data?.marketplace_facilitator_vat | number:'1.2-2' }}
                  </p>
                </div>
              </div>
            </div>
            <hr class="line-content-hr">
            <div class="container-third-info">
              <div class="row">
                <div class="col-sm-12">
                  <p class="text-body-card-group mb-0">Amazon Fee</p>
                  <p class="text-body-card-group mb-0">
                    ${{ element.data?.amazon_fee === null ? 0 : element.data?.amazon_fee | number:'1.2-2' }}
                  </p>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">FBA</p>
                  <p class="text-body-card-group mb-0">
                    ${{element.data?.fba === null ? 0:element.data?.fba | number:'1.2-2' }}
                  </p>
                </div>

                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Fee comission</p>
                  <p class="text-body-card-group mb-0">
                    ${{element.data?.fee_comission === null ? 0 : element.data?.fee_comission | number:'1.2-2' }}
                  </p>
                </div>
              </div>
              <div class="row mt-10">
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Fee giftwrap chargeback</p>
                  <p class="text-body-card-group mb-0">
                    ${{element.data?.fee_giftwrap_chargeback === null ? 0:element.data?.fee_giftwrap_chargeback | number:'1.2-2' }}
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="text-body-card-group mb-0">Fee shipping chargeback</p>
                  <p class="text-body-card-group mb-0">
                    ${{ element.data?.fee_shipping_chargeback === null ? 0 : element.data?.fee_shipping_chargeback | number:'1.2-2' }}
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>

    <ng-template #cardLoader>
      <app-skeleton-element-list [typeOfContentSkeleton]="'card-vertical'"></app-skeleton-element-list>
    </ng-template>
  </div>
  <div class="col-lg-7">
    <section class="pt-2 pb-0" *ngIf="chunkedElementsSearch && chunkedElementsSearch.length > 0">
      <div class="container">
          <div class="row">
            <div class="col-sm-12 d-flex justify-content-end mb-2">
              <button class="btn btn-primary mr-1 btn-sm center-elements-into-content" [disabled]="chunkedElementsSearch.length <= 1" (click)="prevSlide()">
                <i class=" ri-arrow-left-line"></i>
              </button>
              <button class="btn btn-primary btn-sm center-elements-into-content" [disabled]="chunkedElementsSearch.length <= 1" (click)="nextSlide()">
                <i class=" ri-arrow-right-line"></i>
              </button>
            </div>
            <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="false" [interval]="false" #carouselCards>
              <ng-template ngbSlide *ngFor="let chunkElem of chunkedElementsSearch">
                <div class="row">
                  <div class="card col-md-4 card-into-carousel" *ngFor="let item of chunkElem; let i = index">
                    <div class="card-body" [ngClass]="i === 0 ? 'first-card-in-list' : 'card-body-into-carousel'">
                      <div class="d-flex">
                        <div class="flex-1 overflow-hidden">
                          <h5 class="mb-0" [ngClass]="i === 0 ? 'text-white' : 'text-white'">{{textFilter}}</h5>
                          <span class="small-text" [ngClass]="i === 0 ? 'text-white-75' : 'text-white-75'">{{item.date}}</span>
                        </div>
                        <div [ngClass]="i === 0 ? 'text-white' : 'text-white'" class="ms-auto">
                          <i class="font-size-24 ri-numbers-line"></i>
                        </div>
                     </div>
                      <h5 class="mb-0 mt-2" [ngClass]="i === 0 ? 'text-white' : ''">
                        <ng-container *ngIf="keyToSearch === 'average_ticket'; else otherSearchsCard">
                          {{ item && item.data['units'] === 0 ? 0 : '$' + ((item.data['saleGlobal'] / item.data['units']) | number:'1.2-2') }}
                        </ng-container>
                        <ng-template #otherSearchsCard>
                          {{ item ? (keyToSearch === 'units' || keyToSearch === 'orders') ?  item.data[keyToSearch] : ('$' + (item.data[keyToSearch] | number:'1.2-2')) : 0 }}
                        </ng-template>
                      </h5>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
      </div>
    </section>

    <div class="card">
      <div class="card-body">
        <div class="row text-center">
          <div class="col-3" *ngFor="let date of arrayOfDate; let i=index">
            <h6 class="mb-0">
              <ng-container *ngIf="keyToSearch === 'average_ticket'; else otherSearchs">
                {{ defaultFinancesDate[i] && defaultFinancesDate[i]['units'] === 0 ? 0 : '$' + ((defaultFinancesDate[i]['saleGlobal'] / defaultFinancesDate[i]['units']) | number:'1.2-2') }}
              </ng-container>
              <ng-template #otherSearchs>
                {{ defaultFinancesDate[i] ? (keyToSearch === 'units' || keyToSearch === 'orders') ?  defaultFinancesDate[i][keyToSearch] : ('$' + (defaultFinancesDate[i][keyToSearch] | number:'1.2-2')) : 0 }}
              </ng-template>
            </h6>
            <p class="text-muted text-truncate">{{date.title}}</p>
          </div>
        </div>
        <div class="chartjs-chart">
          <!-- Bar Chart -->
          <canvas baseChart [datasets]="lineBarChart.datasets" chartType="bar" [options]="lineBarChart.options"
            height="300" [labels]="lineBarChart.labels">
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-deck-card-periods mt-10">
  <button type="button" (click)="manualSyncDataForTilesCard()"
    class="btn btn-primary btn-rounded btn-sync btn-manual-sync-floated"
    ngbTooltip="Manual Sync" placement="left">
    <i class=" ri-refresh-line"></i>
  </button>
  <div class="row">
    <ng-container *ngFor="let date of arrayOfDate; let i=index">
      <div class="card card-into-carousel mb-4 col-md-3">
        <div class="card-body card-body-into-deck" [style.backgroundColor]="backgroundColors[i]">
          <div class="card-title">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="mb-0 text-white">{{date.title}}</h5>
              </div>
              <div class="col-sm-12 mt-2">
                <p class="mb-0 subtext-card-group text-white-75">{{date.date}}</p>
              </div>
            </div>
          </div>
          <!-- Aqui va el contenido -->
          <div id="containerAccordionContent" ngbAccordion  [closeOthers]="true" #accordion="ngbAccordion">
            <div ngbAccordionItem="first" [collapsed]="false">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  <i class=" ri-hand-coin-line mr-2"></i>
                  Sales Info Section
                </button>
              </h2>
              <div ngbAccordionCollapse >
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="container-intial-info">
                      <div class="row">
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Sales</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{defaultFinancesDate[i]?.saleGlobal === null ? 0:defaultFinancesDate[i]?.saleGlobal | number:'1.2-2' }}
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Sales Shipped</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{defaultFinancesDate[i]?.sales === null ? 0:defaultFinancesDate[i]?.sales  |  number:'1.2-2' }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-10">
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Orders/Units</p>
                          <p class="text-body-card-group mb-0 text-white">
                            {{ defaultFinancesDate[i]?.orders === null ? 0 : defaultFinancesDate[i]?.orders }}/{{ defaultFinancesDate[i]?.units === null ? 0 : defaultFinancesDate[i]?.units }}
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Promotion</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{ defaultFinancesDate[i]?.promotion === null ? 0 : defaultFinancesDate[i]?.promotion | number:'1.2-2' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div ngbAccordionItem="second">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  <i class="ri-truck-line mr-2"></i>
                  Shipping Section
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="container-secondary-info">
                      <div class="row">
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Shipping Cost</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{ defaultFinancesDate[i]?.shipping_cost === null ? 0 : defaultFinancesDate[i]?.shipping_cost | number:'1.2-2' }}
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Average Ticket</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{ defaultFinancesDate[i]?.saleGlobal === null || defaultFinancesDate[i]?.units === 0 ? 0 : (defaultFinancesDate[i]?.saleGlobal / defaultFinancesDate[i]?.units) | number:'1.2-2' }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-10">
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Gross Profit</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{ defaultFinancesDate[i]?.gros_profit === null ? 0 : defaultFinancesDate[i]?.gros_profit | number:'1.2-2' }}
                          </p>
                        </div>
                        <div class="col-sm-6">
                          <p class="text-body-card-group mb-0 text-white-75">Marketplace VAT</p>
                          <p class="text-body-card-group mb-0 text-white">
                            ${{defaultFinancesDate[i]?.marketplace_facilitator_vat === null ? 0 : defaultFinancesDate[i]?.marketplace_facilitator_vat | number:'1.2-2' }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div ngbAccordionItem="third">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>
                  <i class="ri-amazon-fill mr-2"></i>
                  Amazon Info Section
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="row">
                      <div class="col-sm-12">
                        <p class="text-body-card-group mb-0 text-white-75">Amazon Fee</p>
                        <p class="text-body-card-group mb-0 text-white">
                          ${{ defaultFinancesDate[i]?.amazon_fee === null ? 0 : defaultFinancesDate[i]?.amazon_fee | number:'1.2-2' }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-10">
                      <div class="col-sm-6">
                        <p class="text-body-card-group mb-0 text-white-75">FBA</p>
                        <p class="text-body-card-group mb-0 text-white">
                          ${{defaultFinancesDate[i]?.fba === null ? 0:defaultFinancesDate[i]?.fba | number:'1.2-2' }}
                        </p>
                      </div>

                      <div class="col-sm-6">
                        <p class="text-body-card-group mb-0 text-white-75">Fee comission</p>
                        <p class="text-body-card-group mb-0 text-white">
                          ${{defaultFinancesDate[i]?.fee_comission === null ? 0 : defaultFinancesDate[i]?.fee_comission | number:'1.2-2' }}
                        </p>
                      </div>
                    </div>
                    <div class="row mt-10">
                      <div class="col-sm-6">
                        <p class="text-body-card-group mb-0 text-white-75">Fee giftwrap chargeback</p>
                        <p class="text-body-card-group mb-0 text-white">
                          ${{defaultFinancesDate[i]?.fee_giftwrap_chargeback === null ? 0:defaultFinancesDate[i]?.fee_giftwrap_chargeback | number:'1.2-2' }}
                        </p>
                      </div>
                      <div class="col-sm-6">
                        <p class="text-body-card-group mb-0 text-white-75">Fee shipping chargeback</p>
                        <p class="text-body-card-group mb-0 text-white">
                          ${{ defaultFinancesDate[i]?.fee_shipping_chargeback === null ? 0 : defaultFinancesDate[i]?.fee_shipping_chargeback | number:'1.2-2' }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, catchError, from, mergeMap, of, map, throwError, takeUntil } from 'rxjs';
import { IndexedDbCacheService } from './index-db-cache.service';
import { OnlineOfflineService } from './online-offline.service';
import { PL, Finance } from '../models/pl.models';
import { ResponseService } from '../models/response.models';
import Utils from '../utilities/utils';
import { HttpRequestService } from './http-request.service';

@Injectable({ providedIn: 'root' })
export class TopBarService {

  public globalUrl: string;
  public utilities: Utils;

  constructor(
    public _http: HttpClient,
    private indexDBCacheService: IndexedDbCacheService,
    private httpRequestService: HttpRequestService,
    private onlineOfflineService: OnlineOfflineService) {
    this.globalUrl = environment.baseUrlProd;

    this.onlineOfflineService.connectionChanged.subscribe(online => {
      if (online) {
        this.generalDataPLVTwo(true, true);
      }
    });
  }

  async generalDataPLVTwo(syncData: boolean = false, overwriteCache: boolean = true): Promise<Observable<any>> {
    try {
      const cachedData: PL[] = await this.indexDBCacheService.getRegister('pl');

      if (cachedData && cachedData.length > 0 && !syncData) {
        return of({ data: cachedData, cache: true});
      }

      const newData = await this.fetchDataFromService();

      if (overwriteCache) {
        await this.indexDBCacheService.saveRegister('pl', newData);
      } else {
        const combinedData = this.combineData(cachedData, newData);
        await this.indexDBCacheService.saveRegister('pl', combinedData);
      }

      return of({ data: newData, cache: false});
    } catch (error) {
      throw error;
    }
  }

  async generalDataTrendTwo(syncData: boolean = false, overwriteCache: boolean = true): Promise<Observable<any>> {
    try {
      const cachedData: PL[] = await this.indexDBCacheService.getRegister('trends');

      if (cachedData && cachedData.length > 0 && !syncData) {
        return of({ data: cachedData, cache: true});
      }

      const newData = await this.fetchDataFromServiceTrends();

      if (overwriteCache) {
        await this.indexDBCacheService.saveRegister('trends', newData);
      } else {
        const combinedData = this.combineData(cachedData, newData);
        await this.indexDBCacheService.saveRegister('trends', combinedData);
      }

      return of({ data: newData, cache: false});
    } catch (error) {
      throw error;
    }
  }

  private async fetchDataFromServiceTrends(): Promise<PL[]> {
    try {
      const observable = this._http.get<ResponseService>(this.globalUrl + 'get-trend-by-finance')
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );

      const response = await observable.toPromise();
      return response ? response.data : null;
    } catch (error) {
      throw error;
    }
  }

  private async fetchDataFromService(): Promise<PL[]> {
    try {
      const observable = this._http.get<ResponseService>(this.globalUrl + 'get-pl-by-finance')
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntil(this.httpRequestService.cancelPendingRequestsObservable)
      );

      const response = await observable.toPromise();
      return response ? response.data : null;
    } catch (error) {
      throw error;
    }
  }

  private combineData(cachedData: PL[], newData: PL[]): PL[] {
    const uniqueData = newData.filter(item => !cachedData.some(existingItem => existingItem.month === item.month));
    return [...cachedData, ...uniqueData];
  }
}

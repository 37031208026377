<ng-container *ngIf="!loading; else loadingContent">
  <div class="card">
    <div class="card-body">
      <apx-chart class="apex-charts" dir="ltr" [series]="profitLineChart.series" [chart]="profitLineChart.chart"
          [dataLabels]="profitLineChart.dataLabels" [stroke]="profitLineChart.stroke"
          [colors]="profitLineChart.colors" [xaxis]="profitLineChart.xaxis" [grid]="profitLineChart.grid"
          [tooltip]="profitLineChart.tooltip" [plotOptions]="profitLineChart.plotOptions"
          [fill]="profitLineChart.fill" [markers]="profitLineChart.markers" [legend]="profitLineChart.legend"
          [yaxis]="profitLineChart.yaxis" [tooltip]="profitLineChart.tooltip"></apx-chart>
    </div>
  </div>
</ng-container>
<ng-template #loadingContent>
  <div class="row">
    <app-skeleton-element-list [typeOfContentSkeleton]="'card-chart'"></app-skeleton-element-list>
  </div>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmptySearchComponent } from './empty-search.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [ CommonModule, FormsModule, TranslateModule],
  declarations: [EmptySearchComponent],
  exports: [EmptySearchComponent]
})
export class EmptySearchComponentModule {}

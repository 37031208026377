import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SkuListComponent } from './products/sku-list/sku-list.component';
import { TotalSalesComponent } from './dashboard/total-sales/total-sales.component';
import { PPCComponent } from './ppc/ppc.component';
import { InventoryComponent } from './inventory/inventory.component';
import { FinancialComponent } from './financial/financial.component';
import { InsightsComponent } from './insights/insights.component';

const routes: Routes = [
    { path: '', component: DashboardComponent},
    { path: 'dashboard/total-sales', component: TotalSalesComponent},
    { path: 'products/sku-list', component: SkuListComponent},
    { path: 'ppc', component: PPCComponent},
    { path: 'inventory', component: InventoryComponent},
    { path: 'insights', component: InsightsComponent},
    { path: 'financial', component: FinancialComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }

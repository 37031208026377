<div class="card">
  <div class="card-body">
    <ng-container *ngIf="productsToList.length > 0; else emptyDonutChart">
      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th style="width: 10%;"></th>
              <th style="width: 25%;">Description</th>
              <th style="width: 25%;">SKU</th>
              <th style="width: 40%;">Ventas de Productos</th>
            </tr>
          </thead>
          <tbody class="tbody-scroll">
            <tr *ngFor="let productItem of productsToList; let i = index;">
              <td style="width: 10%;">{{i+1}}</td>
              <td style="width: 25%;"> {{ productItem.name_product | slice:0:25 }}{{ productItem.name_product?.length > 25 ? '...' : '' }} </td>
              <td style="width: 25%;"> {{productItem.seller_sku}} | {{productItem.asin}}</td>
              <td style="width: 40%;">
                <ng-container *ngIf="productItem && maxSalesProduct">
                  <ngb-progressbar
                    [ngbTooltip]="keySearch && keySearch === 'units' ? productItem[keySearch] : '$' + (productItem[keySearch] | number:'1.2-2')" placement="top"
                    [value]="((productItem[keySearch] / maxSalesProduct[keySearch]) * 100).toFixed(2)"
                    [striped]="true" type="success"></ngb-progressbar>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-template #emptyDonutChart>
      <app-empty-search></app-empty-search>
    </ng-template>
  </div>
</div>

<div class="container-fluid">
  <app-pagetitle title="Inventory" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="col-sm-12 mb-30">
    <div class="card">
      <div class="card-container-filters">
        <div class="d-flex align-items-center justify-content-end">
          <div class="row mr-2" *ngIf="showDatesSection">
            <div class="col-sm-6 date-search">
              <span class="ri-calendar-line"></span>
              <input [ngModelOptions]="{standalone: true}" name="initDate" id="initDate"
                     [(ngModel)]="initDate" (ngModelChange)="searchByDates()" ngbDatepicker class="form-control"
                     autoClose="false" placeholder="YYYY-MM-DD" (click)="date.toggle()" #date="ngbDatepicker">
            </div>
            <div class="col-sm-6 date-search">
              <span class="ri-calendar-line"></span>
              <input [ngModelOptions]="{standalone: true}" name="endDate" id="endDate"
                     [minDate]="minEndDate" [(ngModel)]="endDate"
                     (ngModelChange)="searchByDates()" ngbDatepicker class="form-control"
                     autoClose="false" placeholder="YYYY-MM-DD" (click)="dateEnd.toggle()" #dateEnd="ngbDatepicker">
            </div>
          </div>
          <div class="dropdown mr-2" ngbDropdown>
            <button class="btn btn-sm btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
              ngbDropdownToggle>
              {{textFilterDate}} <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <p class="dropdown-item dropdown-chart-filters-item"
                *ngFor="let filter of filtersDate"
                (click)="activeFilteDate(filter)">
                {{filter.title}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-container *ngIf="orders.length > 0; else noOrders">
    <ng-container *ngIf="!loading; else loadingContent">
      <div class="row">
        <div class="col-sm-4">
          <div class="card" *ngFor="let item of totalsByStatus; let i = index">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-1 overflow-hidden">
                  <h5 class="mb-0">
                    <b>{{item.name}}</b>
                  </h5>
                </div>
                <div class="ms-auto">
                  <i class="font-size-24 ri-numbers-line"></i>
                </div>
             </div>
             <div class="container-element-data">
              <div class="row">
                <div class="col-md-6">Orders</div>
                <div class="col-md-6">{{item.totalOrders}}</div>
              </div>
              <div class="row">
                <div class="col-md-6">Sales</div>
                <div class="col-md-6">${{item.sumSales | number:'1.2-2'}}</div>
              </div>
              <div class="row">
                <div class="col-md-6">Units</div>
                <div class="col-md-6">{{item.sumQuantity}}</div>
              </div>
             </div>
            </div>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <h4 class="card-title mb-4">Orders</h4>
              </div>
              <div class="container-card">
                <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart"
                  [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid"
                  [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
                  [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors"
                  [fill]="basicColumChart.fill">
                </apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #loadingContent>
      <div class="row">
        <div class="col-sm-4">
          <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
          <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
          <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
        </div>
        <div class="col-sm-8">
          <app-skeleton-element-list [typeOfContentSkeleton]="'card-chart'"></app-skeleton-element-list>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #noOrders>
    <div class="card">
      <div class="card-body">
        <app-empty-search></app-empty-search>
      </div>
    </div>
  </ng-template>


  <div class="col-sm-12 mt-30 mb-30">
    <app-map-chart-inventory
      [initDate]="initialDate"
      [endDate]="endedDate"
    ></app-map-chart-inventory>
  </div>

  <div class="mt-30 mb-30">
    <div class="card clean-card">
      <div class="d-flex justify-content-end align-items-center pd-15">
        <div class="position-relative mr-5"  ngbTooltip="Al dar clic en el nombre de la columna se ordenaran los datos de mayor a menor y tambien de forma inversa" placement="bottom">
          <i class="ri-information-line font-size-icon-info"></i>
        </div>
        <form class="app-search d-lg-block">
          <div class="position-relative" ngbTooltip="Puedes realizar la busqueda por los siguientes parametros, Product Name, Ship City, Postal Code, Amazon and Merchant Order Id" placement="bottom">
            <input type="text" class="form-control" name="searchText" id="searchText"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="searchCampaing" (ngModelChange)="searchCampaings()" placeholder="{{'HEADER.SEARCH' | translate}}">
            <span class="ri-search-line"></span>
          </div>
        </form>
      </div>
      <app-table-campaings-inventory
      [searchTerm]="searchTerm"
      [initialDate]="initialDate"
      [endedDate]="endedDate"
      ></app-table-campaings-inventory>
    </div>
  </div>
</div>

<div class="container-fluid">
  <app-pagetitle title="Products" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-sm-12 pd-btns-carrousel" id="searchFieldsContainer">
      <div class="d-flex justify-content-end align-items-center">
        <div class="row mr-2" *ngIf="showDatesSection">
          <div class="col-sm-6 date-search">
            <span class="ri-calendar-line"></span>
            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="initDate" (ngModelChange)="searchByDates()" ngbDatepicker class="form-control" autoClose="false" placeholder="YYYY-MM-DD" (click)="date.toggle()" #date="ngbDatepicker">
          </div>
          <div class="col-sm-6 date-search">
            <span class="ri-calendar-line"></span>
            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="endDate" (ngModelChange)="searchByDates()" ngbDatepicker class="form-control" autoClose="false" placeholder="YYYY-MM-DD" (click)="dateEnd.toggle()" #dateEnd="ngbDatepicker">
          </div>
        </div>
        <form class="app-search d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="searchProductTile" (ngModelChange)="searchProductTiles()" placeholder="{{'HEADER.SEARCH' | translate}}">
            <span class="ri-search-line"></span>
          </div>
        </form>
        <button type="button" (click)="manualSyncDataFromService()"
          class="btn btn-primary btn-rounded btn-sync mr-2"
          ngbTooltip="Manual Sync" placement="left">
          <i class=" ri-refresh-line"></i>
        </button>
        <div class="dropdown ml-2 mr-2" ngbDropdown>
          <button class="btn btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
            ngbDropdownToggle>
            {{textFilter}} <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <p class="dropdown-item dropdown-chart-filters-item"
              *ngFor="let filter of filters"
              (click)="activeFilter(filter)">
              {{filter.title}}
            </p>
          </div>
        </div>
        <div class="dropdown" ngbDropdown>
          <button class="btn btn-secondary dropdown-chart-filters dropdown-toggle" type="button" id="dropdownMenuButton"
            ngbDropdownToggle>
            {{textFilterDate}} <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <p class="dropdown-item dropdown-chart-filters-item"
              *ngFor="let filter of filtersDate"
              (click)="activeFilteDate(filter)">
              {{filter.title}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 pd-btns-carrousel" id="donutProductsChart">
      <app-donut-products
        [searchTerm]="searchTerm"
        [keyToSearch]="keyToSearch"
        ></app-donut-products>
    </div>
    <div class="col-sm-6 pd-btns-carrousel" id="cardTableProducts">
      <app-table-products
      [searchTerm]="searchTerm"
      [keyToSearch]="keyToSearch"
      ></app-table-products>
    </div>
    <div class="col-sm-12 pd-btns-carrousel" id="gaussChartProducts">
      <app-gauss-products
        [searchTerm]="searchTerm"
        [keyToSearch]="keyToSearch"
        ></app-gauss-products>
    </div>

    <!-- Cards Productos -->
    <div class="col-sm-12">
      <div *ngIf="loading; else content">
        <div class="col-lg-12" *ngFor="let item of [].constructor(10)">
          <app-skeleton-element-list [typeOfContentSkeleton]="'card'"></app-skeleton-element-list>
        </div>
      </div>

      <ng-template #content>
        <div class="row" *ngIf="!noResults ; else emptyData">
          <div class="container">
              <div class="row">
                <div class="col-sm-12 d-flex justify-content-end mb-2 pd-btns-carrousel">
                  <button class="btn btn-primary mr-1 btn-sm center-elements-into-content" [disabled]="productsToSearch.length <= 1" (click)="prevSlide()">
                    <i class=" ri-arrow-left-line"></i>
                  </button>
                  <button class="btn btn-primary btn-sm center-elements-into-content" [disabled]="productsToSearch.length <= 1" (click)="nextSlide()">
                    <i class=" ri-arrow-right-line"></i>
                  </button>
                </div>
                <ngb-carousel [showNavigationIndicators]="false" [showNavigationArrows]="false" [interval]="false" #carouselCards>
                  <ng-template ngbSlide *ngFor="let chunkElem of productsToSearch">
                    <div class="row">
                      <div class="card card-into-carousel col-sm-3" *ngFor="let product of chunkElem; let i = index">
                        <div class="card-body-into-carousel">
                          <div class="center-image">
                            <p
                              (click)="selectProduct(smallDataModal, product)"
                              class="mb-0 see-more">
                              <i class=" ri-information-line"></i>
                            </p>
                            <img class="card-img-top img-fluid image-product" [src]="product.image && product.image !== '' ? product.image : '../../../../assets/images/no-photo.png'" [alt]="product.fn_sku">
                          </div>

                          <div class="card-body text-center">
                            <h4 class="card-title mt-0">
                              <p class="d-flex align-items-center justify-content-center mb-0">
                                <span>{{product.asin}}</span>
                              </p>
                            </h4>
                            <div class="row">
                              <div class="col-sm-12">
                                <span class="subTitle-card">
                                  {{product.seller_sku}}
                                </span>
                              </div>
                            </div>

                            <div class="text-muted">
                              <span class="small-text">Size: {{product.size && product.size !== '' ? product.size : 'N/A'}}</span> |
                              <span
                                [ngClass]="product.total <= 10 ? 'bg-danger-subtle text-danger' : product.total <= 20 ? 'bg-warning-subtle text-warning' : 'bg-success-subtle text-success'"
                                class="badge rounded-pill ms-1 font-s-12"> Stock: {{product.total}}</span>
                            </div>
                            <div class="container-data mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <p class="mb-0 subTitle-card font-bold">Sales Shipped</p>
                                  <p class="mb-0 subTitle-card font-light">${{product.sales | number:'1.2-2'}}</p>
                                </div>
                                <div class="col-md-12">
                                  <p class="mb-0 subTitle-card font-bold">Gros profit</p>
                                  <p class="mb-0 subTitle-card font-light">${{product.gros_profit | number: '1.2-3'}}</p>
                                </div>
                                <div class="col-md-12">
                                  <p class="mb-0 subTitle-card font-bold">Units Sold</p>
                                  <p class="mb-0 subTitle-card font-light">{{product.units}}</p>
                                </div>
                                <div class="col-md-12">
                                  <p class="mb-0 subTitle-card font-bold">Ads</p>
                                  <p class="mb-0 subTitle-card font-light">--</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>
          </div>
        </div>

        <ng-template #emptyData>
          <app-empty-search></app-empty-search>
        </ng-template>
      </ng-template>

    </div>
  </div>
</div>


<ng-template #smallDataModal let-modal>
  <div class="modal-header">
    <div class="row w-100">
        <div class="col-md-3 center-image-modal">
          <img class="card-img img-fluid image-product-card" [src]="selectedProduct.image && selectedProduct.image !== '' ? selectedProduct.image : '../../../../assets/images/no-photo.png'" [alt]="selectedProduct.fn_sku">
        </div>
        <div class="col-sm-9 pd-0">
          <p class="d-flex align-items-center justify-content-start card-title mb-0">
            <span>{{selectedProduct.asin}}</span>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>
          </p>

          <span class="subTitle-card">{{selectedProduct.seller_sku}}</span>
          <div class="small text-medium-emphasis">
            <span class="small-text">Size: {{selectedProduct.size && selectedProduct.size !== '' ? selectedProduct.size : 'N/A'}}</span> |
            <span class="small-text">Stock: {{selectedProduct.total}}</span>
          </div>
        </div>
    </div>
  </div>
  <div class="modal-body pd-0">
    <table class="table table-striped">
      <tbody>
        <tr>
          <td colspan="4"> <b>Sales Shipped</b> </td>
          <td class="text-right"> ${{selectedProduct?.sales ? selectedProduct?.sales.toFixed(2) : 0.00}} </td>
        </tr>
        <tr>
          <td colspan="4"> <b>Units</b> </td>
          <td class="text-right"> {{selectedProduct?.units ? selectedProduct?.units : 0}}</td>
        </tr>
        <tr>
          <td colspan="4"> <b>Promotion</b> </td>
          <td class="text-right"> ${{selectedProduct?.promotion ? selectedProduct?.promotion.toFixed(2) : 0.00}} </td>
        </tr>
        <tr>
          <td colspan="4"> <b>Shipping cost</b> </td>
          <td class="text-right"> ${{selectedProduct?.shipping_cost ? selectedProduct?.shipping_cost.toFixed(2) : 0.00}} </td>
        </tr>
        <tr>
          <td colspan="4"> <b>Shipping price</b> </td>
          <td class="text-right">${{selectedProduct?.shipping_price ? selectedProduct?.shipping_price.toFixed(2) : 0.00}}</td>
        </tr>
        <tr>
          <td colspan="4"><b>Shipping tax</b></td>
          <td class="text-right">${{selectedProduct?.shipping_tax ? selectedProduct?.shipping_tax.toFixed(2) : 0.00}}</td>
        </tr>
        <tr>
          <td colspan="4">
            <b>Amazon fees</b>
            <ul class="list-unstyled mb-0">
              <li class="nowrap-text">FBA</li>
              <li class="nowrap-text">Fee comission</li>
              <li class="nowrap-text">Fee giftwrap chargeback</li>
              <li class="nowrap-text">Fee shipping chargeback</li>
            </ul>
          </td>
          <td class="text-right">
            ${{selectedProduct?.amazon_fee ? selectedProduct?.amazon_fee.toFixed(2): 0.00}}
            <ul class="list-unstyled mb-0">
              <li>${{selectedProduct?.fba ? selectedProduct?.fba.toFixed(2):0.00}}</li>
              <li>${{selectedProduct?.fee_comission ? selectedProduct?.fee_comission.toFixed(2):0.00}}</li>
              <li>${{selectedProduct?.fee_giftwrap_chargeback ? selectedProduct?.fee_giftwrap_chargeback.toFixed(2):0.00}}</li>
              <li>${{selectedProduct?.fee_shipping_chargeback ? selectedProduct?.fee_shipping_chargeback.toFixed(2):0.00}}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="4"><b>Gross profit</b></td>
          <td class="text-right">${{selectedProduct?.gros_profit ? selectedProduct?.gros_profit.toFixed(2):0.00}}</td>
        </tr>
        <tr>
          <td colspan="4"><b>Marketplace facilitator VAT</b></td>
          <td class="text-right"> ${{selectedProduct?.marketplace_facilitator_vat ? selectedProduct?.marketplace_facilitator_vat.toFixed(2):0.00}} </td>
        </tr>
        <tr>
          <td colspan="4"><b>Orders</b></td>
          <td class="text-right">{{selectedProduct?.orders}}</td>
        </tr>
        <tr>
          <td colspan="5"><b>Name Product</b></td>
        </tr>
        <tr>
          <td colspan="5">
            {{selectedProduct?.name_product}}
          </td>
        </tr>
        <tr>
          <td colspan="4"><b>Fn SKU</b></td>
          <td class="text-right">{{selectedProduct?.fn_sku}}</td>
        </tr>
        <tr>
          <td colspan="4"><b>Condition</b></td>
          <td class="text-right">{{selectedProduct?.condition}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { AuthService } from '../../../core/services/auth.service';
import { LanguageService } from '../../../core/services/language.service';
import { TopBarContainer } from './topbar.container';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { TopBarService } from 'src/app/core/services/topbar.service';
import { NgbDropdownConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PPCService } from 'src/app/core/services/ppc.service';
import { modalDashboardSelectionComponent } from 'src/app/shared/ui/modals/modal-dashboard-selection/modal-dashboard-selection.component';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class TopbarComponent extends TopBarContainer implements OnInit {

  element: any;
  accountName: string;
  configData: any;
  userProfile: any = {
    name: '',
    email: '',
    role: ''
  };

  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  selectedPrimaryTab: string;

  listLang = [
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' }
  ];

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) private document: any,
                public router: Router,
                public authService: AuthService,
                public languageService: LanguageService,
                public cookiesService: CookieService,
                public localStorageService: LocalStorageService,
                public modalService: NgbModal,
                public topBarService: TopBarService,
                public indexDbCacheService: IndexedDbCacheService,
                public ppcService: PPCService,
                public loadingSpinnerService: LoadingSpinnerService,
                config: NgbDropdownConfig) {
    super(topBarService, indexDbCacheService, ppcService, localStorageService, loadingSpinnerService);
    this.selectedPrimaryTab = 'pl';
    config.autoClose = 'outside';
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.accountName = this.localStorageService.getData('account_name');
    this.getUserProfile();
  }

  openModalDashboardSelection() {
    const modalOptions: NgbModalOptions = {
      size: 'xl',
      keyboard: false,
      backdrop: 'static', // Configura el backdrop como 'static'
    };
    const modalRef = this.modalService.open(modalDashboardSelectionComponent, modalOptions);
  }

  getUserProfile() {
    const user = JSON.parse(this.localStorageService.getData('currentUser'));
    if (user) {
      this.userProfile = {
        name: user.name,
        email: user.username,
        role: user.user_type
      };
    }
  }

  selectAccount(account_id: number, account_name: string) {
    this.localStorageService.saveData('account_id', account_id.toString());
    this.localStorageService.saveData('account_name', account_name);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  showOtherContentTab(tab: string) {
    this.selectedPrimaryTab = tab;
    switch (tab) {
      case 'pl':
        this.getGeneralDataPL();
        break;
      case 'trends':
        this.getGeneralDataTrends();
        break;
      default:
        break;
    }
  }

  searchProductTrends(): void {
    this.filterProducts(this.searchProductTrend);
  }

  activeFilter(typeFilterSelected:any){
    this.getTypeTrend(typeFilterSelected.value)
    this.typeFilterSelected = typeFilterSelected.value;
    this.textFilter = typeFilterSelected.name;
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }


  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }
}

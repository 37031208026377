<ng-container *ngIf="!loading; else loadingContent">
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="row mt-10">
          <div class="col text-center">
            <p class="text-muted mb-0 font-size-12">Sales</p>
            <span class="font-bold font-size-18">{{calculate && calculate.sales ? '$' + (calculate.sales.toFixed(2) | number:'1.2-2' ) : 0}}</span>
          </div>
          <div class="col text-center">
            <p class="text-muted mb-0 font-size-12">Spend</p>
            <span class="font-bold font-size-18">{{calculate && calculate.spend ? '$' +  (calculate.spend.toFixed(2) | number:'1.2-2' ) : 0}}</span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="basicSalesLineColumnsChart.series[0].data.length > 0 && basicSalesLineColumnsChart.series[1].data.length; else emptyMixedChartSales">
        <apx-chart
        [series]="basicSalesLineColumnsChart.series"
        [chart]="basicSalesLineColumnsChart.chart"
        [yaxis]="basicSalesLineColumnsChart.yaxis"
        [xaxis]="basicSalesLineColumnsChart.xaxis"
        [labels]="basicSalesLineColumnsChart.labels"
        [stroke]="basicSalesLineColumnsChart.stroke"
        [title]="basicSalesLineColumnsChart.title"
        [dataLabels]="basicSalesLineColumnsChart.dataLabels"
        [tooltip]="basicSalesLineColumnsChart.tooltip"
      ></apx-chart>
      </ng-container>
      <ng-template #emptyMixedChartSales>
        <app-empty-search></app-empty-search>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #loadingContent>
  <div class="row">
    <app-skeleton-element-list [typeOfContentSkeleton]="'card-chart'"></app-skeleton-element-list>
  </div>
</ng-template>

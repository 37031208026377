import { Injectable } from '@angular/core';
import  *  as CryptoJS from  'crypto-js';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  key: string = '7abc71a5-5f6f-4a86-9c45-5b89613230b8';
  private addedChartsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  addedCharts$ = this.addedChartsSubject.asObservable();

  constructor() {
    this.loadAddedCharts();
   }

  setAddedCharts(term: any) {
    this.addedChartsSubject.next(term);
    this.saveData('addedCharts', JSON.stringify(term.join(',')) );
  }

  private loadAddedCharts() {
    const savedCharts = this.getData('addedCharts');
    if (savedCharts) {
      const addedCharts = JSON.parse(savedCharts);
      this.addedChartsSubject.next(addedCharts.split(','));
    }
  }

  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public getData(key: string) {
    let data = localStorage.getItem(key)|| "";
    return this.decrypt(data);
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }

  showReSyncAlert() {
    Swal.fire({
      icon: 'info',
      title: 'Se esta ejecutando el proceso de re sincronización de tu información.',
      text: 'Actualmente se esta descargando la información del canal que has seleccionado por favor espere unos minutos, mientras tanto continua utilizando Drizar Data Hub con la información con la que cuentas. Si el proceso tarda más de lo esperado por favor contacte a soporte para más información (soporte@ddh.com).',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
}

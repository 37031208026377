<ng-container>
  <ng-container  *ngIf="loadingTrends; else contentTrends">
    <app-skeleton-element-list [typeOfContentSkeleton]="'table'"></app-skeleton-element-list>
  </ng-container>
  <ng-template #contentTrends>
    <div class="row mb-3">
        <div class="col-sm-6">
          <h3 class="d-flex justify-content-start align-items-center">
            Trends Data
          </h3>
        </div>
        <div class="col-sm-6">
          <div class="d-flex justify-content-end align-items-center">
            <form class="app-search d-lg-block mr-10" ngbTooltip="Puedes realizar la busqueda por los siguientes parametros, ASIN, SKU, Product Name y los montos por mes" placement="bottom">
              <div class="position-relative">
                <input type="text" class="form-control"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="searchProductTrend" (ngModelChange)="searchProductTrends()" placeholder="{{'HEADER.SEARCH' | translate}}">
                <span class="ri-search-line"></span>
              </div>
            </form>
            <div class="btn-group btn-group-filter mr-2" autoClose="outside" ngbDropdown>
              <button type="button" class="btn btn-dropdown-filter btn-secondary dropdown-toggle text-left" ngbDropdownToggle>
                <span>{{textFilter}}</span>
                <i class="mdi mdi-chevron-down align-dropdown-element"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-right" ngbDropdownMenu>
                <p class="dropdown-item"
                  *ngFor="let typeFilter of productFilters"
                  (click)="activeFilter(typeFilter)">
                  {{typeFilter.name}}
                </p>
              </div>
            </div>

            <button type="button" (click)="manualSyncDataTrendsFromService()"
              class="btn btn-primary btn-rounded btn-sync"
              ngbTooltip="Manual Sync" placement="right">
              <i class=" ri-refresh-line"></i>
            </button>
          </div>
        </div>
    </div>
    <div class="row" [ngClass]="{'space-dashboard-view': isDashboardView}">
      <div class="col-sm-3 col-md-3 col-lg-3 col-xl-2 pdr-0">
        <table *ngIf="!noResultsTrends ; else noResultsContentTrends" class="table table-striped table-responsive background-table mb-0">
          <thead>
            <tr class="table-data-pl">
              <th class="text-left space-product-name-cell">Product</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of this.dataTrend?.data; index as i">
                <td class="text-left vertical-middle-align height-table-cell pd-main-cell">
                  <div class="d-flex justify-content-start align-items-center">
                    <img class="card-img img-fluid image-product" [src]="product.image && product.image !== '' ? product.image : '../../../../assets/images/no-photo.png'" [alt]="'Image product'">

                    <div class="product-details ml-10">
                      <span class="font-size-element-column pointer" [ngbTooltip]="product.asin + ' | ' + product.seller_sku" placement="top"> {{ product.seller_sku | slice:0:10 }}{{ product.seller_sku.length > 10 ? '...' : '' }} </span>
                      <div class="small text-medium-emphasis">
                        <span>Size: {{ product.size }}</span> <br/>
                        <span
                          [ngClass]="product.total <= 10 ? 'bg-danger-subtle text-danger' : product.total <= 20 ? 'bg-warning-subtle text-warning' : 'bg-success-subtle text-success'"
                          class="badge rounded-pill font-s-12"> Stock: {{product.total}}</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-9 col-md-9 col-lg-9 col-xl-10 pdl-0">
        <div class="container-trends-data horizontal-scroll">
          <table *ngIf="!noResultsTrends ; else noResultsContentTrends" class="table table-striped table-responsive background-table mb-0">
            <thead>
              <tr class="table-data-pl">
                <ng-container *ngFor="let header of this.dataTrend?.header; index as i">
                  <th *ngIf="header !== 'Product'" [ngClass]="header === 'Product' ? 'text-left space-product-name' : 'text-center'">{{header}}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of this.dataTrend?.data; index as i">
                <td class="text-center vertical-middle-align height-table-cell" *ngFor="let amount of product?.amounts; index as i; let last = last">
                  <span class="left-aligned" *ngIf="typeFilterSelected === 'units'; else otherTypeFilter">
                    {{ amount }}
                  </span>
                  <ng-template #otherTypeFilter>
                    <span class="left-aligned" [ngClass]="{'w-100': last}">
                      {{ ('$' + (amount | number:'1.2-2')) }}
                    </span>
                  </ng-template>
                  <span
                    *ngIf="product?.amounts[i+1] && product?.amounts[i+1] !== 0 && this.dataTrend?.header[i+2] !== 'Total'"
                    [ngClass]="product?.amounts[i+1]  > amount ? 'bg-danger-subtle text-danger' : product?.amounts[i+1]  === amount ? 'bg-dark-subtle text-reset' : 'bg-success-subtle text-success'"
                    class="badge rounded-pill font-s-10 right-aligned">
                        {{ product?.amounts[i+1] && product?.amounts[i+1] !== 0 ? (((amount - product?.amounts[i+1]) / ( product?.amounts[i+1])) * 100).toFixed(2) : '-'  }}%
                        <i [ngClass]="product?.amounts[i+1]  > amount ? ' ri-arrow-down-line' : product?.amounts[i+1]  === amount ? '' : ' ri-arrow-up-line'"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <ng-template #noResultsContentTrends>
            <app-empty-search></app-empty-search>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

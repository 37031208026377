<div class="modal-header flex-column">
  <h5 class="modal-title mt-0 mb-10">¡Bienvenido!</h5>
  <p class="modal-subtitle mb-0">Hemos notado que aun no has conectado tus canales de informacion.</p>
  <p class="modal-subtitle">Por favor selecciona el canal que deseas conectar.</p>
</div>
<div class="modal-body">
  <div class="row w-100">
    <div class="bg-light m-20 d-flex align-items-center justify-content-center">
      <div class="card" [ngClass]="{'bg-sp-api-seller': userType === 'seller'}">
        <div class="card-body text-center">
          <img class="card-img-top logo-amazon-seller" *ngIf="userType === 'seller'" alt="..." src="https://m.media-amazon.com/images/I/31MWTU1kYsL.svg" alt="Amazon Ads">
          <img class="card-img-top logo-amazon"  *ngIf="userType === 'vendor'" alt="..." src="./assets/images/amazon_vendor.png" alt="Amazon Vendor">
          <div class="row mt-3">
            <div class="col-12">
              <button class="btn btn-sm btn-primary w-md waves-effect waves-light" (click)="actionInModal(false)" > Autorizar </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light m-20 d-flex align-items-center justify-content-center" *ngIf="error!=''">
      <div class="card-error">
        <div class="card-body text-center">
          <h2 class="text-danger">ERROR</h2>

          <div class="col-12 mt-1">
            <p class="text-danger">{{error}}</p>
            <p class="text-danger">Comunicate con el área de desarrollo.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InactivityService } from 'src/app/core/services/inactivity.service';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit {

  constructor(
    private inactivityService: InactivityService,
    private router: Router,
    private indexedDbService: IndexedDbCacheService) {
    this.inactivityService.getInactivityObservable().subscribe((inactive) => {
      if (inactive) {
        this.inactivityService.alertInactivity();
      }
    });
  }

  ngOnInit(): void {
    document.body.setAttribute('data-sidebar', 'dark');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.classList.remove('auth-body-bg');
  }
  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }


  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }
}

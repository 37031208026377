import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, ChartTypeApex } from '../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { dashedLineChart, totalsByStatus, basicColumChart, statesMx, equivalentStates } from './data';
import { PaginationInstance } from 'ngx-pagination';
import { InventoryService } from 'src/app/core/services/inventory.service';
import * as echarts from 'echarts';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import ShareFunctionsInventory from 'src/app/core/utilities/share-functions-inventory';

export class InventoryContainer {
  minEndDate: NgbDate;
  public utilities: Utils;
  public shareFunctionsInventory: ShareFunctionsInventory;
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  orderTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  basicColumChart: ChartTypeApex;
  filters: any[] = []
  orders: any[] = [];
  ordersToSearch: any[] = [];
  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 10000, // Total de elementos
  };

  totalsByStatus: any[];
  statesMx: any[];
  topStates: any[];
  view: string = 'map';
  dataForMunicipality: any[] = [];
  isLoadedAMunicipality: boolean = false;
  textFilterDate: string = 'Choose a Option';
  filtersDate: any[] = [];
  showDatesSection: boolean = false;
  equivalentStates: any;
  initialDate:any;
  endedDate:any;
  searchTerm: string;

  constructor(public inventoryService: InventoryService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.equivalentStates = equivalentStates;
    this.dashedLineChart = dashedLineChart;
    this.basicColumChart = basicColumChart;
    this.totalsByStatus = totalsByStatus;
    this.statesMx = statesMx;
    this.utilities = new Utils();
    this.initializeFilters();
    this.shareFunctionsInventory = new ShareFunctionsInventory(loadingSpinnerService, inventoryService, this.utilities);
  }

  initializeFilters() {
    this.filters = [
      { title: 'Total Sales', key: 'sales' },
      { title: 'Units', key: 'units' }
    ];

    this.filtersDate = [
      { title: 'Ultimo 7 Dias', key: 'seven_days' },
      { title: 'Ultimos 14 Dias', key: 'fourteen_days' },
      { title: 'Ultimo 30 Dias', key: 'thirty_days' },
      { title: 'Trimestre En Curso', key: 'current_quarter' },
      { title: 'Trimestre Anterior', key: 'quarter' },
      { title: '2 Trimestres Anteriores', key: 'previous_quarter' },
      { title: 'Rango de Fechas', key: 'free_dates' }
    ];
  }

  activeFilter(filter: any) {
    this.textFilter = filter.title;
    this.keyToSearch = filter.key;
  }

  async fetchCampaings(initDate?: string, endDate?: string) {
    try {
      const response = await this.shareFunctionsInventory.getCampaingsByDate(initDate, endDate);
      this.loadingTable = response.loadingTable;
      this.orders = response.orders;
      this.noResults = response.noResults;
      this.ordersToSearch = response.ordersToSearch;
      this.config.totalItems = this.orders?.length;
      this.generateDataTotals(); //Esta funcion si se queda en este componente
    } catch (error) {
      console.log(error, 'error in inventory container')
    }
  }

  generateDataTotals() {
    const orderStatusTypes = ["Shipped", "Unshipped", "Pending"];
    const newBasicColumChart = JSON.parse(JSON.stringify(this.basicColumChart));
    orderStatusTypes.forEach((status, index) => {
      const filteredOrders = this.orders?.filter((order: any) => order.order_status === status);

      this.totalsByStatus[index] = {
        name: status,
        sumQuantity: filteredOrders?.reduce((total: any, order: any) => total + parseInt(order.quantity), 0),
        sumSales: filteredOrders?.reduce((total: any, order: any) => total + parseFloat(order.Sales), 0),
        totalOrders: filteredOrders?.length
      };
    });

    newBasicColumChart.series[0].data = [this.totalsByStatus[0].totalOrders, this.totalsByStatus[1].totalOrders, this.totalsByStatus[2].totalOrders];
    newBasicColumChart.series[1].data = [this.totalsByStatus[0].sumQuantity, this.totalsByStatus[1].sumQuantity, this.totalsByStatus[2].sumQuantity];
    this.basicColumChart = newBasicColumChart;
    this.loading = false;
  }

  activeFilteDate(filter: any) {
    const results = this.utilities.generateRangeOfDates(filter);

    this.endedDate = null;
    this.initialDate = null;
    this.textFilterDate = results.titleFilter;
    this.showDatesSection = results.showDatesSection;
    if (!results.showDatesSection) {
      this.endedDate = results.endedDate;
      this.initialDate = results.initialDate;
      this.fetchCampaings(results.initialDate, results.endedDate)
    }
  }

  filterCampaings(searchTerm: string | undefined): void {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    this.searchTerm = lowerCaseSearchTerm;
  }
}

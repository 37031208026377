import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { PPCContainer } from './ppc.container';
import { NgbCarousel, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PPCService } from 'src/app/core/services/ppc.service';
import { modalProfileContentComponent } from 'src/app/shared/ui/modals/modals.component';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PaginationInstance } from 'ngx-pagination';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

@Component({
  selector: 'app-products-ppc',
  templateUrl: './ppc.component.html',
  styleUrls: ['./ppc.component.scss']
})

/**
 * Dashboard Component
 */
export class PPCComponent extends PPCContainer implements OnInit  {
  @ViewChild('carouselCharts') carouselCharts: NgbCarousel;
  account_id: number = 0;

  constructor(
    public ppcService: PPCService,
    public indexDbCacheService: IndexedDbCacheService,
    public modalService: NgbModal,
    public localStorageService: LocalStorageService,
    public loadingSpinnerService: LoadingSpinnerService,
    private el: ElementRef
  ) {
    super(ppcService, indexDbCacheService, loadingSpinnerService);
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'VXI' }, { label: 'PPC', active: true }];
    this.account_id = Number(this.localStorageService.getData('account_id'));
    if (!this.account_id) {
      this.openExlargeModal();
    } else {
      this.getAccountInfo();
      this.getCampaingsByDate();
    }
  }

  openExlargeModal() {
    const modalOptions: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static', // Configura el backdrop como 'static'
    };
    const modalRef = this.modalService.open(modalProfileContentComponent, modalOptions);
  }

  prevSlide() {
    if (this.carouselCharts) {
      this.carouselCharts.prev();
    }
  }

  nextSlide() {
    if (this.carouselCharts) {
      this.carouselCharts.next();
    }
  }

  searchCampaings(): void {
    this.filterCampaings(this.searchCampaing);
  }

  searchByDates(): void {
    if(this.initDate) {
      const initDate =  this.utilities.getDateInStringTimezone(new Date(this.initDate.year, this.initDate.month - 1, this.initDate.day)).split('T')[0];
      this.getCampaingsByDate(initDate);
      return;
    }

    this.getCampaingsByDate();
  }
}

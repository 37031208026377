import { ChartTypeLineColumn, ChartTypeRadialChart } from '../../../core/models/elements.model';

const basicRadialBarChart: ChartTypeRadialChart = {
  chart: {
      height: 470,
      width: 470,
      type: 'donut',
  },
  series: [44, 55, 41, 17, 15],
  plotOptions: {
    pie: {
        donut: {
            size: '25%'  // Ajusta el tamaño del donut
        }
    }
  },
  dataLabels: {
    style: {
      colors: ["#fff"],
      fontSize: "10px",
    },
  },
  legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '8px',
      offsetX: 0,
      offsetY: -10
  },
  labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5'],
  colors: ['#1cbb8c', '#5664d2', '#fcb92c', '#4aa3ff', '#ff3d60'],
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: '100%',
      },
      legend: {
        position: 'bottom',
      },
    },
  }]
};


const basicLineColumnsChart: ChartTypeLineColumn = {
  series: [
    {
      name: "Sales",
      type: "column",
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    },
    {
      name: "Units",
      type: "line",
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ],
  chart: {
    height: 420,
    type: "line",
    toolbar: {
      show: true,
      tools: {
        download: true,  // Mostrar solo la opción de descarga
        zoom: false,     // Ocultar opciones de zoom
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      },
    }
  },
  stroke: {
    width: [0, 4]
  },
  title: {
    text: "Sales/Units"
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1]
  },
  labels: [
    "01 Jan 2001",
    "02 Jan 2001",
    "03 Jan 2001",
    "04 Jan 2001",
    "05 Jan 2001",
    "06 Jan 2001",
    "07 Jan 2001",
    "08 Jan 2001",
    "09 Jan 2001",
    "10 Jan 2001",
    "11 Jan 2001",
    "12 Jan 2001"
  ],
  xaxis: {
    type: "category",
    labels: {
      style: {
        fontSize: '10px', // Ajusta el tamaño de la letra según tus necesidades
      },
    },
  },
  yaxis: [
    {
      title: {
        text: "Sales"
      }
    },
    {
      opposite: true,
      title: {
        text: "Units"
      }
    }
  ]
};

export { basicRadialBarChart, basicLineColumnsChart };

import { AlertSyncService } from './alert-sync.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private isLoadingSubject = new Subject<boolean>();
  public isLoading$ = this.isLoadingSubject.asObservable();
  public alertSyncService: AlertSyncService;

  constructor(private router: Router, private authService: AuthService) {
    this.alertSyncService = new AlertSyncService(null, null, this);
  }

  show() {
    this.isLoadingSubject.next(true);
  }

  hide() {
    this.isLoadingSubject.next(false);
  }

  showAlertQueued() {
    Swal.fire({
      icon: 'info',
      title: 'Ya te encuentras en proceso de descarga.',
      text: 'Fuiste agregado a la cola de procesamiento para obtener tu información. En lo que se completa el proceso puedes cerrar sesión para que se complete el proceso de descarga.',
      showConfirmButton: true,
      confirmButtonText: "Cerrar Sesion",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      Swal.fire("La sesión se cerrara y se te notificara cuando el proceso de descarga haya terminado", "", "info");
        setTimeout(() => {
          this.authService.logout();
          this.router.navigate(['/account/login']);
        }, 1000);
    });
  }

  showSubscriptionExpireAlert() {
    Swal.fire({
      icon: 'info',
      title: 'Tu suscripción ha vencido.',
      text: 'Tu suscripción ha vencido porfavor da click en el botón de abajo para que puedas actualizar tu información de pago.',
      showConfirmButton: true,
      confirmButtonText: "Ir a mis planes",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {

    });
  }

  showAlert() {
    Swal.fire({
      icon: 'info',
      title: 'Este proceso puede tardar unos minutos.',
      text: 'Este proceso esta tomando más tiempo de lo esperado, por favor espere unos minutos más o contacte a soporte para más información (soporte@ddh.com).',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/']);
        this.hide();
      }
    });
  }

  showAlertError() {
    Swal.fire({
      icon: 'info',
      title: 'No encontramos información.',
      text: 'No hemos encontrado información con los parametros base establecidos, te invitamos a que configures nuevos parametros o que contactes con el equipo de soporte si continuas sin ver la información (soporte@ddh.com).',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
    });
  }

  showSelectOneChart() {
    Swal.fire({
      icon: 'info',
      title: 'No has seleccionado ningun chart.',
      text: 'Hemos detectado que no has seleccionado ningun chart para tu dashboard, si estas teniendo un problema para seleccionarlo contacta con el equipo de soporte para poder resolver tus dudas (soporte@ddh.com).',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
  }

  showAlertConfirmReSync() {
    Swal.fire({
      icon: 'info',
      title: 'Estas apunto de realizar el proceso de re-sincronización de tu información. ¿Estas seguro de continuar?',
      text: 'Este proceso puede tardar unos minutos, por favor espera a que termine el proceso para poder seguir utilizando Drizar Data Hub con la información actualizada. Si tienes dudas contacta con el equipo de soporte para más información (soporte@ddh.com)',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const user_id = this.authService.currentUserValue.id
        this.authService.reSyncProcess(user_id).subscribe((res) => {
          this.router.navigate(['/']);
        }, (error) => {});
      }
    });
  }

  showAlertDownload() {
    Swal.fire({
      icon: 'info',
      title: 'Descargando información.',
      text: 'Actualmente se esta descargando la información del canal que has seleccionado por favor espere unos minutos. Si el proceso tarda más de lo esperado por favor contacte a soporte para más información o de click en el botón de abajo para reiniciar el proceso.',
      showConfirmButton: true,
      confirmButtonText: "Reiniciar proceso",
      showCancelButton: true,
      denyButtonText: `Cerrar Sesión`,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const currentUser = this.authService.currentUserValue;
        const user_id = currentUser && currentUser.data ? currentUser.data?.id : currentUser?.id;
        this.authService.syncProcess(user_id).subscribe((res) => {}, (error) => {});
        this.showAlertQueued();
      } else if (result.isDenied) {
        Swal.fire("La sesión se cerrara y se te notificara cuando el proceso de descarga haya terminado", "", "info");
        setTimeout(() => {
          this.authService.logout();
          this.router.navigate(['/account/login']);
        }, 1000);
      }
    });
  }
}

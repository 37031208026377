// line-break.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreak'
})
export class LineBreakPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Eliminar puntos suspensivos
    let formattedText = value.replace(/\.{3,}/g, '');

    // Dividir el texto en palabras
    const words = formattedText.split(/\s+/);

    // Agregar <br> después de cada 6 palabras
    const wordsWithBr = [];
    for (let i = 0; i < words.length; i++) {
      wordsWithBr.push(words[i]);
      if ((i + 1) % 4 === 0) {
        wordsWithBr.push('<br>');
      }
    }

    // Unir las palabras con <br> de nuevo
    formattedText = wordsWithBr.join(' ');

    return formattedText;
  }
}

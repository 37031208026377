import { LoadingSpinnerService } from './../../../core/services/loading-spinner.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Products } from 'src/app/core/models/products.model';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { ProductService } from 'src/app/core/services/products.service';
import { SkuListContainer } from './sku-list.container';
import { NgbCarousel, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/core/utilities/utils';

@Component({
  selector: 'app-products-sku-list',
  templateUrl: './sku-list.component.html',
  styleUrls: ['./sku-list.component.scss']
})

/**
 * Dashboard Component
 */
export class SkuListComponent extends SkuListContainer implements OnInit {
  @ViewChild('popover', { static: false }) popover: NgbPopover | undefined;
  @ViewChild('carouselCards') carouselCards: NgbCarousel;
  private readonly scrollTriggerOffset = 75;
  public isPopoverTopLeft = false;

  constructor(
    public productsService: ProductService,
    public indexDbCacheService: IndexedDbCacheService,
    public loadingSpinnerService: LoadingSpinnerService,
    public modalService: NgbModal
  ) {
    super(productsService, indexDbCacheService, loadingSpinnerService);
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'VXI' }, { label: 'Products Sku List', active: true }];
  }

  searchProductTiles(): void {
    this.filterProducts(this.searchProductTile, this.products);
  }

  prevSlide() {
    if (this.carouselCards) {
      this.carouselCards.prev();
    }
  }

  nextSlide() {
    if (this.carouselCards) {
      this.carouselCards.next();
    }
  }

  searchByDates(): void {
    if (this.initDate && this.endDate) {
      this.initDateSearch = this.utilities.getDateInStringTimezone(new Date(this.initDate.year, this.initDate.month - 1, this.initDate.day));
      this.endDateSearch = this.utilities.getDateInStringTimezone(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day));
      this.fetchProducts(this.initDateSearch.split('T')[0] , this.endDateSearch.split('T')[0], true, true);
    } else if (!this.initDate && !this.endDate) {
      this.fetchProducts('', '', true, true);
    }
  }

  selectProduct(smallDataModal, product: Products) {
    this.selectedProduct = product;
    this.modalService.open(smallDataModal, { size: 'md' });
  }
}

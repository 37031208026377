import { ActivatedRoute, Params } from '@angular/router';
import { ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, CalculeElement, CalculeByDay, ChartTypeApex, ChartTypeLineColumn } from '../../../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { basicSalesLineColumnsChart } from '../../data';
import { Profiles } from 'src/app/core/models/profiles.models';
import { PPCService } from 'src/app/core/services/ppc.service';
import { from } from 'rxjs';
import { PaginationInstance } from 'ngx-pagination';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export class SalesChartContainer {
  public utilities: Utils;
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  calculate: CalculeElement;
  calculeByDay: CalculeByDay[];
  filters: any[] = [];
  basicSalesLineColumnsChart: ChartTypeLineColumn;

  constructor(public ppcService: PPCService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.basicSalesLineColumnsChart = basicSalesLineColumnsChart;
    this.utilities = new Utils();
  }

  async getAccountInfo() {
    (await this.ppcService.fetchInfoOfTheAccount()).subscribe((response: any) => {
      if (!response.status) {
        this.loadingSpinnerService.showAlertError();
        this.loadingSpinnerService.hide();
        return;
      }
      this.loading = false;
      this.calculate = response.data.calcule;
      this.calculeByDay = response.data.calcule_by_day;
      this.generateSalesChart();
    });
  }

  generateSalesChart() {
    let basicSalesLineColumnsChart = JSON.parse(JSON.stringify(this.basicSalesLineColumnsChart));
    basicSalesLineColumnsChart.series[0].data = this.calculeByDay.map((element: any) => Number(element.sales.toFixed(2)))
    basicSalesLineColumnsChart.series[1].data = this.calculeByDay.map((element: any) => Number(element.spend.toFixed(2)))
    basicSalesLineColumnsChart.labels = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.basicSalesLineColumnsChart = basicSalesLineColumnsChart;
  }
}

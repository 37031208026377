import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { InactivityService } from '../services/inactivity.service';
import { LoadingSpinnerService } from '../services/loading-spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private inactiveService: InactivityService, private loadingSpinnerService: LoadingSpinnerService, private modalService: NgbModal) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.modalService.dismissAll();
                this.loadingSpinnerService.hide();
                this.inactiveService.alertInactivity();
            }

            if (err.status === 404) {
              this.loadingSpinnerService.showSubscriptionExpireAlert();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}

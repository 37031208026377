import { ActivatedRoute, Params } from '@angular/router';
import { ResponseService } from 'src/app/core/models/response.models';
import { IndexedDbCacheService } from 'src/app/core/services/index-db-cache.service';
import { DatepickerElement, CalculeElement, CalculeByDay, ChartTypeApex, ChartTypeLineColumn } from '../../core/models/elements.model';
import Utils from 'src/app/core/utilities/utils';
import { dashedLineChart, basicLineColumnsChart, profitLineChart, basicSalesLineColumnsChart, roasDashedLineChart } from './data';
import { Profiles } from 'src/app/core/models/profiles.models';
import { PPCService } from 'src/app/core/services/ppc.service';
import { from } from 'rxjs';
import { PaginationInstance } from 'ngx-pagination';
import { LoadingSpinnerService } from 'src/app/core/services/loading-spinner.service';

export class PPCContainer {
  public utilities: Utils;
  public accounts: Profiles[] = [];
  public accountToChunk: Profiles[] = [];
  keyToSearch: string = '';
  noResults: boolean = false;
  loading: boolean = true;
  loadingTable: boolean = true;
  initDate: DatepickerElement;
  endDate: DatepickerElement;
  calculate: CalculeElement;
  calculeByDay: CalculeByDay[];
  textFilter: string = 'Total Sales';
  searchCampaing: string = '';
  dashedLineChart: ChartTypeApex;
  profitLineChart: ChartTypeApex;
  roasDashedLineChart: ChartTypeApex;
  filters: any[] = []
  campaings: any[] = [];
  campaingsToSearch: any[] = [];
  config: PaginationInstance = {
    itemsPerPage: 20, // Número de elementos por página
    currentPage: 1, // Página actual
    totalItems: 200, // Total de elementos
  };
  basicLineColumnsChart: ChartTypeLineColumn;
  basicSalesLineColumnsChart: ChartTypeLineColumn;

  constructor(public ppcService: PPCService, public indexDbCacheService: IndexedDbCacheService, public loadingSpinnerService: LoadingSpinnerService) {
    this.dashedLineChart = dashedLineChart;
    this.profitLineChart = profitLineChart;
    this.roasDashedLineChart = roasDashedLineChart;
    this.basicLineColumnsChart = basicLineColumnsChart;
    this.basicSalesLineColumnsChart = basicSalesLineColumnsChart;
    this.utilities = new Utils();
    this.getUserProfile();
    this.initializeFilters();
  }

  initializeFilters() {
    this.filters = [
      { title: 'Total Sales', key: 'sales' },
      { title: 'Units', key: 'units' }
    ];
  }

  activeFilter(filter: any) {
    this.textFilter = filter.title;
    this.keyToSearch = filter.key;
  }

  manualSyncDataFromService() {
    this.loading = !this.loading;
    this.getUserProfile(true, true);
  }

  async getUserProfile(syncData: boolean = false, overwriteCache: boolean = true) {
    (await this.ppcService.getAllAccounts(syncData, overwriteCache)).subscribe((response: ResponseService) => {
      this.accounts = response.data;
      this.noResults = response.data.length === 0;
      this.accountToChunk = this.utilities.chunkArray(this.accounts, 4);
    });
  }

  async getAccountInfo() {
    (await this.ppcService.fetchInfoOfTheAccount()).subscribe((response: any) => {
      if (!response.status) {
        this.loadingSpinnerService.showAlertError();
        this.loadingSpinnerService.hide();
        return;
      }
      this.loading = false;
      this.calculate = response.data.calcule;
      this.calculeByDay = response.data.calcule_by_day;
      this.generateSalesChart();
      this.generateIndicators();
      this.generateImpresionsChart();
      this.generateProfitChart();
    });
  }

  async getCampaingsByDate(date?: string) {
    this.loadingSpinnerService.show();
    (await this.ppcService.fetchInfoAccountCampaingsByDate(date)).subscribe((response: any) => {
      if (!response.status) {
        this.loadingSpinnerService.showAlertError();
        this.loadingSpinnerService.hide();
        return;
      }
      this.loadingTable = false;
      this.loadingSpinnerService.hide();
      this.campaings = response.data;
      this.campaingsToSearch = response.data
      this.config.totalItems = this.campaings.length;
      this.sortElements();
    });
  }

  sortElements(): void{
    this.campaingsToSearch.sort((a, b) => {
      // Colocar elementos con "Enabled" en la parte superior
      if (a.campaign_status === 'ENABLED' && b.campaign_status !== 'ENABLED') {
        return -1;
      } else if (a.campaign_status !== 'ENABLED' && b.campaign_status === 'ENABLED') {
        return 1;
      } else {
        return 0;
      }
    });
  }

  generateSalesChart() {
    let basicSalesLineColumnsChart = JSON.parse(JSON.stringify(this.basicSalesLineColumnsChart));
    basicSalesLineColumnsChart.series[0].data = this.calculeByDay.map((element: any) => Number(element.sales.toFixed(2)))
    basicSalesLineColumnsChart.series[1].data = this.calculeByDay.map((element: any) => Number(element.spend.toFixed(2)))
    basicSalesLineColumnsChart.labels = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.basicSalesLineColumnsChart = basicSalesLineColumnsChart;
  }

  generateIndicators() {
    let dashedLineChart = JSON.parse(JSON.stringify(this.dashedLineChart));
    dashedLineChart.series[0].data = this.calculeByDay.map((element: any) => Number(element.acos.toFixed(2)))
    dashedLineChart.xaxis.categories = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.dashedLineChart = dashedLineChart;

    let roasDashedLineChart = JSON.parse(JSON.stringify(this.roasDashedLineChart));
    roasDashedLineChart.series[0].data = this.calculeByDay.map((element: any) => element.spend ? Number((element.sales / element.spend).toFixed(2)) : 0 );
    roasDashedLineChart.xaxis.categories = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.roasDashedLineChart = roasDashedLineChart;
  }

  generateProfitChart() {
    let profitLineChart = JSON.parse(JSON.stringify(this.profitLineChart));
    profitLineChart.series[0].data =  this.calculeByDay.map((element: any) => Number(element.profit.toFixed(2)))
    profitLineChart.xaxis.categories = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.profitLineChart = profitLineChart;
  }

  generateImpresionsChart() {
    let newLineColumnChart = JSON.parse(JSON.stringify(this.basicLineColumnsChart));
    newLineColumnChart.series[0].data = this.calculeByDay.map((element: any) => element.impressions)
    newLineColumnChart.series[1].data = this.calculeByDay.map((element: any) => element.clicks)
    newLineColumnChart.labels = this.calculeByDay.map((element: any) => this.utilities.convertFormatDate(element.date))
    this.basicLineColumnsChart = newLineColumnChart;
  }

  filterCampaings(searchTerm: string | undefined): void {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    if (lowerCaseSearchTerm.trim() === '') {
      this.campaingsToSearch = [...this.campaings];
      this.noResults = false;
      return;
    }
    this.campaingsToSearch = this.campaings.filter(campaing => campaing.campaign_name.toLowerCase().includes(lowerCaseSearchTerm));
    this.noResults = this.campaingsToSearch.length === 0;
  }
}

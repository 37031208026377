import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DonutProductsComponent } from './donut-products.component';
import { EmptySearchComponentModule } from '../../../../../support/empty-search/empty-search.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        NgApexchartsModule,
        EmptySearchComponentModule,
        NgbAccordionModule
      ],
    declarations: [
      DonutProductsComponent
    ],
    exports:[ DonutProductsComponent ]
})
export class DonutProductsModule { }
